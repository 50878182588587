import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    buttons : {
        marginRight : theme.spacing(2)
    },
    paper : {
        padding : theme.spacing(3),
        borderRadius : theme.spacing(2)
    }
}));