import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { TextField, Button, Grid, Box } from "@material-ui/core";
import { makeStyles, Paper, Container } from "@material-ui/core";
import logo from "../../assets/images/small-logo.svg";
import "../../sass/main.scss";
import { resetPassword } from "../../actions/resetpassword";
import KJSIEIT from "../../components/KJSIEIT/KJSIEIT";

const ResetPassword = () => {
  document.title = "Reset Password";
  const history = useHistory();
  const passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  const [input, setInput] = useState({
    new_password: "",
    confirm_password: "",
  });
  const { token } = useParams();
  const newpassRef = useRef(null);
  const confirmpassRef = useRef(null);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  const updatePassword = (e) => {
    e.preventDefault();
    if (input.new_password === "" || !passRegex.test(input.new_password)) {
      newpassRef.current.focus();
    } else {
      if (
        input.confirm_password === "" ||
        input.new_password !== input.confirm_password
      ) {
        confirmpassRef.current.focus();
      } else {
        const data = {
          password: input.new_password,
          confirmPassword: input.confirm_password,
          passwordResetToken: token,
        };
        resetPassword(data, history);
      }
    }
  };
  const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      backgroundColor: "whitesmoke",
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 450,
      overflowY: "hidden",
    },
  }));
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} direction="row-reverse">
            <Grid item xs={12} md={8} lg={8} justify="space-around">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <div className="container-login-blank">
                  <div className="container-login-blank-image">
                    <img src={logo} alt="" />
                  </div>
                  <div className="container-login-blank-header">
                    
                    <h1>Indian Society for Technical Education</h1>
                    <h1>ISTE Membership Portal</h1>
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <div className="container-login-creds-header">
                  <h1>Change Password</h1>
                </div>
                <form
                  className="container-login-creds-form"
                  onSubmit={updatePassword}
                >
                  <div className="container-login-creds-form-input">
                    <TextField
                      error={
                        input.new_password !== ""
                          ? !passRegex.test(input.new_password)
                          : false
                      }
                      helperText={
                        input.new_password !== ""
                          ? !passRegex.test(input.new_password)
                            ? "Password Must be of atleast 8 characters and must contain 1 lowercase 1 uppercase and 1 number"
                            : ""
                          : ""
                      }
                      inputRef={newpassRef}
                      variant="outlined"
                      label="New Password"
                      name="new_password"
                      type="password"
                      onChange={handleInput}
                      autoFocus
                      style={{ width: "80%" }}
                    />
                  </div>
                  <div className="container-login-creds-form-input">
                    <TextField
                      error={
                        input.confirm_password !== ""
                          ? input.new_password !== input.confirm_password
                          : false
                      }
                      helperText={
                        input.confirm_password !== ""
                          ? input.new_password !== input.confirm_password
                            ? "Passwords Donot Match"
                            : ""
                          : ""
                      }
                      variant="outlined"
                      inputRef={confirmpassRef}
                      onChange={handleInput}
                      margin="normal"
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                      style={{ width: "80%" }}
                    />
                  </div>
                  <div className="container-login-creds-form-submit">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: "50%" }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <KJSIEIT />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
};

export default ResetPassword;
