import axios from "axios";
import BASE_URL from "./constants";
const API = axios.create({ baseURL: `${BASE_URL}/api/user` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

export const signIn = (formData) => API.post("/signin", formData);
export const signUp = (formData) => API.post("/add-employee", formData);
export const forgotpassrequest = (formdata) =>
  API.post("/reset-password-request", formdata);
export const resetpassword = (formdata) =>
  API.post("/reset-password", formdata);
export const changepassword = (formdata, token) =>
  API.post("/change-password", formdata);
export const updateProfile = (formdata) =>
  API.post("/update-profile", formdata);

/*------------------------------------ User Account ----------------------------------------*/
export const getUserData = () => API.get("/account/get-user-data");
export const fetchUserProfile = (data) => API.get('/accounts/get-users-data', {params : data});
export const updatePassword = (data) => API.post('/account/update-password', data);

