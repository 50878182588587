import React, { useRef, useState } from "react";
import clsx from "clsx";
import {
  TextField,
  Button,
  Grid,
  Box,
  makeStyles,
  Paper,
  Container,
} from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import KJSIEIT from "../../components/KJSIEIT/KJSIEIT";
import logo from "../../assets/images/small-logo.svg";
import { forgotPassRequest } from "../../actions/resetpassword";
const ForgotPassword = () => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  document.title = "Forgot Password";
  const emailRef = useRef(null);
  const [data, setData] = useState({
    email: "",
  });

  const handleInput = (obj) => {
    const { name, value } = obj.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const Submit = (obj) => {
    obj.preventDefault();
    if (data.email === "" || !emailRegex.test(data.email)) {
      emailRef.current.focus();
    } else {
      forgotPassRequest(data);
    }
  };
  const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      backgroundColor: "whitesmoke",
      flexGrow: 1,
      height: "90vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 450,
      overflowY: "hidden",
    },
  }));

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <>
      <main className={classes.content}>
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} direction="row-reverse">
            <Grid item xs={12} md={8} lg={8}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <div className="container-login-blank">
                  <div className="container-login-blank-image">
                    <img src={logo} alt="" />
                  </div>
                  <div className="container-login-blank-header">
                    
                    <h1>Indian Society for Technical Education</h1>
                    <h1>ISTE Membership Portal</h1>
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <div
                  className="container-login-creds-header"
                  style={{ marginTop: "20%" }}
                >
                  <h2>Change Password</h2>
                </div>
                <form className="container-login-creds-form" onSubmit={Submit}>
                  <div className="container-login-creds-form-input">
                    <div style={{ width: "80%" }}></div>
                    <TextField
                      error={
                        data.email !== "" ? !emailRegex.test(data.email) : false
                      }
                      helperText={
                        data.email !== ""
                          ? !emailRegex.test(data.email)
                            ? "Enter a valid email"
                            : ""
                          : ""
                      }
                      variant="outlined"
                      inputRef={emailRef}
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={handleInput}
                      style={{ width: "80%" }}
                    />
                  </div>

                  <div className="container-login-creds-form-submit">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: "50%" }}
                    >
                      Send Email
                    </Button>
                  </div>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      <footer>
        <Box>
          <KJSIEIT />
        </Box>
      </footer>
    </>
  );
};

export default ForgotPassword;
