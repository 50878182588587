import React, { useState,useEffect } from 'react';
import { 
    Paper,
    Grid,
    CircularProgress,
    Typography,
    Divider,
    TextField,
    Button,
    MenuItem,
    
} from '@material-ui/core';
import useStyles from './styles';
import { toast } from 'react-toastify';
import { addStudentChapter } from '../../api';
import { getAllStudentSections } from '../../api/registrationForm';

const AddNewStudentChapterForm = () => {
    const [loading,setLoading] = useState(false);
    const [chapter, setChapter] = useState({});
    const [chapterError, setChapterError] = useState({});
    const [sections,setSections] = useState([]);
    const classes = useStyles();


    const handleChange = (e)=>{
        setChapter({...chapter, [e.target.name]:e.target.value})
    }
    const validateData = ()=>{
        const newChapterError = {
            membership_no : !chapter.membership_no || isNaN(chapter.membership_no),
            director : !chapter.director,
            faculty_advisor : !chapter.faculty_advisor,
            chapter_incharge : !chapter.chapter_incharge,
        }
        setChapterError(newChapterError);
        let isValid = true;
        for(var key in newChapterError){
            if(newChapterError[key]) isValid = false;
        }
        return isValid;
    }
    const submitForm = async ()=>{
        setLoading(true);
        try{
            const isValid = validateData(chapter);
            if(!isValid) return;
            const data = await addStudentChapter(chapter);
            console.log(data);
            if(true){
                toast.success('Chapter Added', {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
              
          }
        }catch(error){
          console.log(error.response);
          toast.error('Something went wrong! Make sure that the Institute Number is Valid and havent added before', {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }finally{
          setLoading(false);
        }
      }

      const fetchAllSections = async ()=>{
        try{
            const data = await getAllStudentSections();
            if(data?.status === 200){
                setSections(data?.data?.result);
            }
        }catch(error){
            console.log(error);
        }
      }
    

    useEffect(() => {
        fetchAllSections();
    }, [])
    return (
        <Grid>
            <Grid item className={classes.gridMargin}>
                {
                    loading ? 
                    <Paper className={classes.loading} align="center">
                        <CircularProgress />
                    </Paper> :
                    (
                        <>
                        <Paper className={classes.paper}>
                            <Typography className={classes.formTitle} component="h5" variant="h5" >
                                Add Student Chapter
                            </Typography>
                            <Divider/>
                            <Grid container spacing={3} className={classes.searchMargin}>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.membership_no}
                                        helperText={chapterError.membership_no && "Enter Valid Membership No"}
                                        label="Institute ID"
                                        name="membership_no"
                                        value={chapter.membership_no}
                                        onChange={handleChange}
                                        type="Number"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.section_code}
                                        helperText={chapterError.section_code && "Select Section"}
                                        label="Section"
                                        name="section_code"
                                        value={chapter.section_code}
                                        onChange={handleChange}
                                        select
                                    >
                                        {
                                            sections.map((value)=>(
                                                <MenuItem key={value._id} value={value.section_code}>
                                                    {value.section_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error = {chapterError.chairman}
                                        helperText={chapterError.chairman && "Invalid Entry"}
                                        label="Principal/Director"
                                        name="director"
                                        value={chapter.director}
                                        onChange={handleChange}
                                    >

                                    </TextField>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.secretary}
                                        helperText={chapterError.secretary && "Invalid Entry"}
                                        label="Faculty Advisor"
                                        name="faculty_advisor"
                                        value={chapter.faculty_advisor}
                                        onChange={handleChange}
                                    >
                                        
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.secretary_department}
                                        helperText={chapterError.secretary_department && "Invalid Entry"}
                                        label="ISTE Student Chapter In-charge / Coordinator"
                                        name="chapter_incharge"
                                        value={chapter.chapter_incharge}
                                        onChange={handleChange}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                    >
                                        Add Student Chapter
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        </>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default AddNewStudentChapterForm;
