import React from 'react'
import StudentMembershipForm from '../../components/StudentMembershipForm/StudentMembershipForm';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';


const StudentMembership = () => {
    return (
        <>
        <FormHeader />
        <StudentMembershipForm />
        <KJSIEIT />
        </>
    );
}

export default StudentMembership;
