import React,{useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box,Grid,TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Link , useRouteMatch, useParams} from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentIcon from '@material-ui/icons/Assessment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import {
  MenuItem,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import useStyles from './styles';
import { getInstitutionalMembers, getAllDistricts, getAllStates,  } from '../../api'; 
import BASE_URL from '../../api/constants';


const columns = [
  { id: 'membershipNo',label: 'Membership No'},
  { id: 'name_of_institution', label: 'Name of Institute'},
  { id: 'category_of_institution', label:'Category of Institute'},
  // { id: 'first_name_of_the_officer_incharge', label: 'Name of officer in charge' },
  // { id: 'email_of_officer_incharge',label: 'Email of officer in charge'},
  { id: 'section_code',label: 'Section Code'},
  { id: 'state',label: 'State'},
  { id: 'city',label: 'City'},
  { id: 'view', label: 'View'},
];

export default function InstituteMembersTable({approved,rejected})  {
  const classes = useStyles();
  let { page } = useParams();
  const [loading,setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const [institutionalMembers ,setInstitutionalMembers] = useState([]);
  const [searchParameters, setSearchParameters] = useState({status : 'Member'});
  const [next,setNextPage] = useState(null);
  const [prev,setPrevPage] = useState(null);
  const [count,setCount] = useState(0);
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);

  

  const handleLoading = (value)=>{
    setLoading(value);
  }
  const handleChange = (e)=>{
    setSearchParameters({...searchParameters, [e.target.name] : e.target.value});
  }

    const searchMembers = ()=>{
    getData();
  }
  const generateReport = async ()=>{
    try{

      axios({
        url: `${BASE_URL}/api/institute-membership/reports`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
        params : {...searchParameters},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Institutional Membership Report - exported at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  const downloadAddress = async ()=>{
    try{

      axios({
        url: `${BASE_URL}/api/institute-membership/download-member-address`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
        params : {...searchParameters},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Institutional Membership Address - exported at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.docx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  
  // const handleSubmit = () =>{
  //   getData();
  // } 

  const prevPage = (val)=>{
    setPrevPage(val);
  }
  const nextPage = (val)=>{
    setNextPage(val);
  }
  const getData = async ()=>{
    setLoading(true);
    const data = await getInstitutionalMembers({
      page : page, 
      limit : 100,
      ...searchParameters
    });
    if(data?.data?.result){
      
      setInstitutionalMembers(data?.data?.result?.results);
      setCount(data?.data?.result?.count)
      nextPage(data?.data?.result?.next?.page)
      prevPage(data?.data?.result?.previous?.page)
      handleLoading(false);
    }
    
  }

  const getStates = async ()=>{
    try{
        const data = await getAllStates();
        console.log(data);
        if(data?.status === 200){
          setStatesData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
const getDistricts = async ()=>{
    try{
        const data = await getAllDistricts();
        console.log(data);
        if(data?.status === 200){
          setDistrictsData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
  // getData();
  useEffect(()=>{
    getData();
    getStates();
    getDistricts();
  },[page]);
  // getData();

  return (
    <Paper className={classes.root}>
      { loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) :  (
          <>
        <Box elevation={2} className={classes.box}>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="h6">Total Count : {count}</Typography>
              </Grid>
              <Grid item  sm={12} lg={6}>
                <TextField
                label = "Name of Institute"
                variant="outlined"
                name="name_of_institution"
                onChange={handleChange}
                value={searchParameters.name_of_institution}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item  sm={12} lg={3}>
                <TextField
                label = "Type of Institute"
                variant="outlined"
                name="type_of_institution"
                onChange={handleChange}
                value={searchParameters.category_of_institution}
                select
                fullWidth
                >
                   {['Degree', 'Diploma', 'MBA-MCA','Others(Industry/Private/Public Sectors)'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item  sm={12} lg={3}>
                <TextField
                label = "Membership Status"
                variant="outlined"
                name="status"
                onChange={handleChange}
                value={searchParameters.status}
                fullWidth
                select
                >
                  {['In Progress', 'Approved', 'Rejected', 'Member'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={12} lg={3}>
                <TextField
                label = "Membership No"
                variant="outlined"
                name="membershipNo"
                onChange={handleChange}
                fullWidth
                value={searchParameters.membershipNo}
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "state"
                        label= "Name of State"
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                        value={searchParameters.state}
                        select
                    >
                      {stateData.map((option) => (
                        <MenuItem key={option._id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              
             
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "section_code"
                        label= "Section Code"
                        fullWidth
                        value={searchParameters.section_code}
                        onChange={handleChange}
                        variant="outlined"
                    >
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "city"
                        label= "City"
                        fullWidth
                        value={searchParameters.city}
                        onChange={handleChange}
                        variant="outlined"
                    >
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format="dd/MM/yyyy"
                  name="startDate"
                  value={searchParameters.startDate || new Date()}
                  InputAdornmentProps={{ position: "start" }}
                  onChange = {(date)=>{
                    setSearchParameters({...searchParameters, startDate : date});
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="To"
                    name="endDate"
                    format="dd/MM/yyyy"
                    value={searchParameters.endDate || new Date()}
                    InputAdornmentProps={{ position: "start" }}
                    onChange = {(date)=>{
                      setSearchParameters({...searchParameters, endDate : date});
                    }}
                  />
              </Grid>
              <Grid item sm={12} lg={3}>
                <TextField
                label = "Start Membership No"
                variant="outlined"
                name="startMembershipNo"
                onChange={handleChange}
                fullWidth
                value={searchParameters.startMembershipNo}
                >
                </TextField>
              </Grid>
              <Grid item sm={12} lg={3}>
                <TextField
                label = "End Membership No"
                variant="outlined"
                name="endMembershipNo"
                onChange={handleChange}
                fullWidth
                value={searchParameters.endMembershipNo}
                >
                </TextField>
              </Grid>
              <Grid item sm={12} lg={12} align="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={searchMembers}
                  className={classes.buttonPadding}
                >
                  <SearchIcon/> Search
                </Button>
                
                
              </Grid>
              <Grid item sm={12} lg={4} align="center">
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={generateReport}
                    fullWidth
                    mt={2}
                  >
                    <AssessmentIcon/> Generate Report
                  </Button>
              </Grid>
              <Grid item sm={12} lg={4} align="center">
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={downloadAddress}
                    fullWidth
                    mt={2}
                  >
                    Download Address
                  </Button>
              </Grid>
          </Grid>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {institutionalMembers.map((row) => {
                return (
                  <TableRow key={row._id} hover role="checkbox" tabIndex={-1}>
                      <TableCell>
                            {row.membership_no}
                      </TableCell>
                      <TableCell >
                            {`${row.name_of_institution}`}
                      </TableCell>
                      <TableCell >
                            {`${row.category_of_institution}`}
                      </TableCell>
                      {/* <TableCell >
                            {`${row.first_name_of_the_officer_incharge} ${row.last_name_of_the_officer_incharge}`}
                      </TableCell>
                      <TableCell>
                            {row.email_of_officer_incharge}
                      </TableCell> */}
                      <TableCell>
                            {row.section_code}
                      </TableCell>
                      <TableCell>
                            {row.state}
                      </TableCell>
                      <TableCell>
                            {row.city}
                      </TableCell>
                      <TableCell>
                          <Button 
                          size="small"
                          variant="outlined" 
                          color="primary" 
                          component={Link} 
                          onClick={() => window.open(`/dashboard/institute-membership-applications/${row._id}`, "_blank")}
                          >
                            <OpenInNewIcon />
                          </Button>
                      </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box align="right">
          <Button  to={`${prev}`} component={Link} disabled={!prev}><NavigateBeforeIcon /></Button>
          <Button  to={`${next}`} component={Link} disabled={!next}><NavigateNextIcon /></Button>
        </Box>
        </>
        )}
    </Paper>
  );
}
