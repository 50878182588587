import React,{useState,useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import useStyles from "./styles";
import InputForm from "./InputForm";
import Preview from './Preview';
import validation from './validation';
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle ,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import * as api from '../../api/registrationForm';
import convertUTCDateToLocalDate from '../../utils/dateConverter'

import { toast } from 'react-toastify';

const initials = [
  {
    value : 'Dr',
    label : 'Dr'
  },
  {
    value : 'Mr',
    label : 'Mr'
  },
  {
    value : 'Mrs',
    label : 'Mrs'
  },
  {
    value : 'Miss',
    label : 'Miss'
  },
  {
    value : 'Mx',
    label : 'Mx'
  },
]

const designation = [
  {
    value : 'Professor',
    label : 'Professor'
  },
  {
    value : 'Associate Professor',
    label : 'Associate Professor'
  },
  {
    value : 'Assistant Professor',
    label : 'Assistant Professor'
  },
  {
    value : 'Lecturer',
    label : 'Lecturer'
  },
  {
    value: "Others", 
    label: "Others"
  },
]

const branch = [
  {
    value : 'AERONAUTICAL ENGINEERING',
    label : 'AERONAUTICAL ENGINEERING'
  },
  {
    value : 'AUTOMOBILE ENGINEERING',
    label : 'AUTOMOBILE ENGINEERING'
  },
  {
    value : 'BIO-MEDICAL ENGINEERING',
    label : 'BIO-MEDICAL ENGINEERING'
  },
  {
    value : 'BIO-TECHNOLOGY',
    label : 'BIO-TECHNOLOGY'
  },
  {
    value : 'CHEMICAL ENGINEERING',
    label : 'CHEMICAL ENGINEERING'
  },
  {
    value : 'CHEMICAL TECHNOLOGY',
    label : 'CHEMICAL TECHNOLOGY'
  },
  {
    value : 'CIVIL ENGINEERING',
    label : 'CIVIL ENGINEERING'
  },
  {
    value : 'CIVIL & INFRASTRUCTURE ENGINEERING',
    label : 'CIVIL & INFRASTRUCTURE ENGINEERING'
  },  
  {
    value : 'COMPUTER ENGINEERING',
    label : 'COMPUTER ENGINEERING'
  },
  {
    value : 'COMPUTER SCIENCE & ENGINEERING',
    label : 'COMPUTER SCIENCE & ENGINEERING'
  },
  {
    value : 'ELECTRICAL & ELECTRONICS ENGINEERING',
    label : 'ELECTRICAL & ELECTRONICS ENGINEERING'
  },
  {
    value : 'ELECTRICAL ENGINEERING',
    label : 'ELECTRICAL ENGINEERING'
  },
  {
    value : 'ELECTRONICS ENGINEERING',
    label : 'ELECTRONICS ENGINEERING'
  },
  {
    value : 'ELECTRONICS & COMMUNICATION ENGINEERING',
    label : 'ELECTRONICS & COMMUNICATION ENGINEERING'
  },
  {
    value : 'ELECTRONICS & TELECOMMUNICATION ENGINEERING',
    label : 'ELECTRONICS & TELECOMMUNICATION ENGINEERING'
  },
  {
    value : 'ENVIRONMENTAL ENGINEERING',
    label : 'ENVIRONMENTAL ENGINEERING'
  },
  {
    value : 'ENVIRONMENTAL SCIENCE & TECHNOLOGY',
    label : 'ENVIRONMENTAL SCIENCE & TECHNOLOGY'
  },
  {
    value : 'ENVIRONMENTAL SCIENCE AND ENGINEERING',
    label : 'ENVIRONMENTAL SCIENCE AND ENGINEERING'
  },
  {
    value : 'FOOD PROCESSING TECHNOLOGY',
    label : 'FOOD PROCESSING TECHNOLOGY'
  },
  {
    value : 'INDUSTRIAL ENGINEERING',
    label : 'INDUSTRIAL ENGINEERING'
  },
  {
    value : 'INFORMATION TECHNOLOGY',
    label : 'INFORMATION TECHNOLOGY'
  },
  {
    value : 'INFORMATION & COMMUNICATION TECHNOLOGY',
    label : 'INFORMATION & COMMUNICATION TECHNOLOGY'
  },
  {
    value : 'INSTRUMENTATION & CONTROL ENGINEERING',
    label : 'INSTRUMENTATION & CONTROL ENGINEERING'
  },
  {
    value : 'MANUFACTURING ENGINEERING',
    label : 'MANUFACTURING ENGINEERING'
  },
  {
    value : 'MARINE ENGINEERING',
    label : 'MARINE ENGINEERING'
  },
  {
    value : 'MECHANICAL ENGINEERING',
    label : 'MECHANICAL ENGINEERING'
  },
  {
    value : 'MECHATRONICS ENGINEERING',
    label : 'MECHATRONICS ENGINEERING'
  },
  {
    value : 'METALLURGY ENGINEERING',
    label : 'METALLURGY ENGINEERING'
  },
  {
    value : 'MINING ENGINEERING',
    label : 'MINING ENGINEERING'
  },
  {
    value : 'PLASTIC TECHNOLOGY',
    label : 'PLASTIC TECHNOLOGY'
  },
  {
    value : 'POWER ELECTRONICS',
    label : 'POWER ELECTRONICS'
  },
  {
    value : 'PRODUCTION ENGINEERING',
    label : 'PRODUCTION ENGINEERING'
  },
  {
    value : 'ROBOTICS AND AUTOMATION',
    label : 'ROBOTICS AND AUTOMATION'
  },  
  {
    value : 'RUBBER TECHNOLOGY',
    label : 'RUBBER TECHNOLOGY'
  },
  {
    value : 'TEXTILE PROCESSING',
    label : 'TEXTILE PROCESSING'
  },
  {
    value : 'TEXTILE TECHNOLOGY',
    label : 'TEXTILE TECHNOLOGY'
  },
  {
    value : 'OTHERS (PLEASE SPECIFY)',
    label : 'OTHERS (PLEASE SPECIFY)'
  },

]

const genderCategory = [
  {
    value : 'M',
    label : 'Male'
  },
  {
    value : 'F',
    label : 'Female'
  },
  {
    value : 'O',
    label : 'Non-Binary'
  }
]
const membershipCategory = [
  {
    value : 'LM',
    label : 'LM'
  },
  {
    value : 'FM',
    label : 'FM'
  },
]

const initialState = {
  initials : "",
  firstName : "",
  lastName : "",
  middleName: "",
  dateOfBirth : '',
  qualifications : "",
  gender : "",
  branch: "",
  areaOfSpecialisation : "",
  designation : "",
  other_designation : "",
  institute : "",
  city : "",
  state : "",
  pincode : "",
  mailingAddress : "",
  pincodeOfMailingAddress : "",
  email : "",
  alternateEmail: "",
  membershipCategory : "LM",
  mobileNo : "",
  landlineTelephone : "",
  panNo : "",
  aadharNo : "",
  district : null,
  // highestQualificationCertificate : "",
  recommendersFirstName : "",
  recommendersLastName : "",
  recommendersEmail : "",
  recommendersMembershipNo : ""
}


export default function Checkout() {
  const classes = useStyles();
  const [formData,setFormData] = useState(initialState);
  const [file, setFile] = useState('');
  const [image,setImage] = useState(null);
  const [signature, setSignature] = useState(null);
  const [certificateOfRecommendation, setCertificateOfRecommendation] = useState(null);
  const [ formError, setFormError ] = useState({});
  const [preview, setPreview] = useState(false);
  const [backDrop,setBackDrop] = useState(false);
  const [ dialog,setDialog ] = useState(false);
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [sections,setSections] = useState([]);
  const [termsAndConditions, setTermsAndConditions]  = useState(false);
  const [institutes,setInstitutes] = useState([]);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getStates = async ()=>{
    try{
        const data = await api.getAllStates();
        console.log(data);
        if(data?.status === 200){
          setStatesData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
const getDistricts = async ()=>{
    try{
        const data = await api.getAllDistricts();
        console.log(data);
        if(data?.status === 200){
          setDistrictsData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
const getSections = async ()=>{
  try{
      const data = await api.getAllSections();
      console.log(data);
      if(data?.status === 200){
        setSections(data?.data?.result);
      }
  }catch(error){
      console.log(error);
  }
}

  const openDialog = ()=>{
    setDialog(true);
  }
  const closeDialog = ()=>{
    setDialog(false);
    window.location.reload();
  }

  const closeBackDrop = ()=>{
    setBackDrop(false);
    
  }
  const toggleBackDrop = ()=>{
    setBackDrop(!backDrop);
  }
  
  const submitForm = async ()=>{
    console.log(formData);
    console.log(file);
    toggleBackDrop();
    try{
      // console.log(formData);
      const newFormData = new FormData();
      newFormData.append('initials', formData.initials);
      newFormData.append('firstName', formData.firstName);
      newFormData.append('lastName', formData.lastName);
      newFormData.append('middleName', formData.middleName);
      const dateOfBirth = convertUTCDateToLocalDate(formData.dateOfBirth);
      newFormData.append('dateOfBirth', dateOfBirth);
      newFormData.append('qualifications', formData.qualifications);
      newFormData.append('gender', formData.gender);
      // newFormData.append('institute', formData.institute);
      if(formData.alternateEmail){
        newFormData.append('alternateEmail', formData.alternateEmail);
      }
      if(formData.landlineTelephone){
        newFormData.append('landlineTelephone', formData.landlineTelephone);
      }

      newFormData.append('city', formData.city);
      newFormData.append('state', formData.state);
      newFormData.append('pincode', formData.pincode);
      newFormData.append('mailingAddress', formData.mailingAddress);
      newFormData.append('pincodeOfMailingAddress', formData.pincodeOfMailingAddress);
      newFormData.append('email', formData.email);
      
      newFormData.append('membershipCategory', formData.membershipCategory);
      newFormData.append('mobileNo', formData.mobileNo);
      
      newFormData.append('panNo', formData.panNo);
      newFormData.append('aadharNo', formData.aadharNo);
      newFormData.append('district', formData.district);
      newFormData.append('recommendersFirstName', formData.recommendersFirstName);
      newFormData.append('recommendersLastName', formData.recommendersLastName);
      newFormData.append('recommendersEmail', formData.recommendersEmail);
      if(formData.chapter_code !=='' && formData.chapter_code){
        newFormData.append('chapter_code', formData.chapter_code);
      }
      if(formData.branch==='OTHERS (PLEASE SPECIFY)'){
          newFormData.append('branch', formData.areaOfSpecialisation);
      }else{
          newFormData.append('branch', formData.branch);
      }
      if (formData.designation === "Others") {
          newFormData.append('designation', formData.other_designation);
      } else {
          newFormData.append('designation', formData.designation);
      }
      if(formData.institute==='other_institute'){
        newFormData.append('institute', formData.otherInstitute)
      }else{
        newFormData.append('institute', formData.institute);
      }
      newFormData.append('recommendersMembershipNo', formData.recommendersMembershipNo);
      newFormData.append('section', formData.section);
      newFormData.append(
        'highestQualificationCertificate', 
        file
      );
      newFormData.append(
        'image', 
        image
      );
      newFormData.append(
        'signature', 
        signature
      );
      newFormData.append(
        'certificateOfRecommendation', 
        certificateOfRecommendation
      );
      console.log(newFormData);
      

      const result = await api.lifeTimeRegistration(newFormData);
      console.log(result);
      console.log(result.status);
      if(result.status===200){
        
        openDialog();
      }
    }catch(error){
      console.log(error.response);
      toast(
        error?.response?.data?.message || 'Something went wrong!',
        {autoClose : false}
      );
    }
    toggleBackDrop();

  }
  const handleHighestQualificationUpload = e => {
    if(e.target.files[0] && e.target.files[0].size > 500000){
      toast('The filesize for Highest Qualification Certificate should be less than 500KB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setFile(e.target.files[0]);
  }
  const handleImageUpload = e => {
    if(e.target.files[0] && e.target.files[0].size > 250000){
      toast('The filesize for Photograph should be less than 250KB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setImage(e.target.files[0]);
  }
  const handleSignatureUpload = e =>{
    if(e.target.files[0] && e.target.files[0].size > 500000){
      toast('The filesize for Signature should be less than 250KB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setSignature(e.target.files[0]);
  }
  const handleCertificateOfRecommendationUpload = e =>{
    if(e.target.files[0] && e.target.files[0].size > 1000000){
      toast('The filesize for certificate of recommendation should be less than 1MB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setCertificateOfRecommendation(e.target.files[0]);
  }
  const handleChange = (e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
  }
  
  const handleSubmit = (e)=>{
    e.preventDefault();
    let isFormValid = true;
    // validations 
    
    const newFormError = validation(formData,formError);
    if(!termsAndConditions) {
      toast('Please accept the terms and conditions to proceed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    if(!file || !image || !signature || !certificateOfRecommendation) {
      isFormValid = false;
      toast('Please Upload Highest Qualification Certificate/Image/Signature/Certificate of Recommendation', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    // checks if any of the fields are not validated 
    // and changes isFormValid accordingly 
    for(const key in newFormError){
      if(newFormError[key]){
        console.log(key);
        isFormValid = false;
      }
    }
    
    setFormError(newFormError);
    if(!isFormValid) return;
    togglePreview();
  }

  const togglePreview = ()=>{
    setPreview(!preview);
  }
  const fetchAllInstitutes = async ()=>{
    try{
      const data = await api.getAllInstituteName();
      setInstitutes(data?.data?.institutes);
    }catch(error){
      console.log(error);
    }
  }
  useEffect(()=>{
    getStates();
    getDistricts();
    getSections();
    fetchAllInstitutes();
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          { preview ? 
            <Preview 
              formData={formData} 
              togglePreview={togglePreview} 
              submitForm={submitForm}
              stateData={stateData}
              districtsData={districtsData}
              image={image}
            /> : 
            <InputForm 
              formData={formData} 
              formError = {formError}
              handleSubmit={handleSubmit} 
              handleChange={handleChange} 
              membershipCategory={membershipCategory}
              branch={branch}
              genderCategory={genderCategory}
              highestQualificationCertificate={setFile}
              file={file}
              designation={designation}
              handleHighestQualificationUpload = {handleHighestQualificationUpload}
              handleImageUpload={handleImageUpload}
              handleCertificateOfRecommendationUpload={handleCertificateOfRecommendationUpload}
              handleSignatureUpload={handleSignatureUpload}
              initials={initials}
              image={image}
              signature={signature}
              certificateOfRecommendation={certificateOfRecommendation}
              stateData={stateData}
              districtsData={districtsData}
              setFormData={setFormData}
              setTermsAndConditions={setTermsAndConditions}
              termsAndConditions={termsAndConditions}
              sections={sections}
              institutes={institutes}
            />
          }
        </Paper>
      </main>
      {/* <Backdrop className={classes.backdrop} open={backDrop}  >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Dialog
        fullScreen={fullScreen}
        open={dialog}
        onClose={closeDialog}

        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Life Time Registration Form Successfully Submiited"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You form have been submitted. Check your email for confirmation 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}