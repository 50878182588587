import React from 'react'

import useStyles from './styles';
import LandingPageCards from '../../components/LandingPageCards/LandingPageCards';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';

const LandingPage = () => {
    const classes = useStyles();
    return (
        <>
            <FormHeader />
            <LandingPageCards />
            <KJSIEIT />
        </>
    )
}

export default LandingPage;
