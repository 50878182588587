import React, { useState, useEffect, useMemo } from 'react'
import {
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
  MenuItem,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Table,
  TableHead,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import axios from 'axios'
import BASE_URL from '../../api/constants'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { getInstititeAndConvenerApplications, getInstituteSuggestion } from '../../api'
import useStyles from './styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import GetAppIcon from '@material-ui/icons/GetApp'
import AssessmentIcon from '@material-ui/icons/Assessment'
const columns = [
  { id: 'index', label: 'Serial Number'},
  { id: 'institutionMembershipNo', label: 'Institution Membership Number' },
  { id: 'nameOfISTEChapter', label: 'Name of Institution' },
  { id: 'name', label: 'Name of Convener' },
  { id: 'email', label: 'Email of Convener' },
  { id: 'membershipId', label: 'Membership ID of Convener' },
  { id: 'date_of_creation', label: 'Date of Creation'},
  { id: 'view', label: 'View' },
]

function GenerateReportDialog(props) {
  const [open, setOpen] = React.useState(false)


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <br></br>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AssessmentIcon /> Generate Report
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Generate Report
          <br />
          Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download Data in the .xlsx format. Since we have more than{' '}
            {props.count} entries you can download them in batches
          </DialogContentText>
          {Array(Math.ceil(props.count / 100))
            .fill(null)
            .map((value, idx) => (
              <Button
                style={{ margin: '2px' }}
                variant="outlined"
                onClick={() => {
                  props.generateReport(idx * 100, (idx + 1) * 100)
                }}
              >
                <GetAppIcon /> Download Entries between ({idx * 100 + 1} and{' '}
                {(idx + 1) * 100})
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const StudentApplications = () => {
  const classes = useStyles()
  const [searchParameters, setSearchParameters] = useState({
    status: 'InProgress',
  })
  
  const [searchInstitute, setSearchInstitute] = useState('')

  const [suggestionData, setSuggestionData] = useState([])

  // const filteredSuggestion = useMemo(()=>{
  //   return suggestionData.filter(item => {
  //     return item.includes(searchInstitute.toUpperCase())
  //   })
  // }, [searchInstitute, suggestionData])
 
  let { page } = useParams()
  let { path, url } = useRouteMatch()
  const [lifeTimeMemberApplications, setLifeTimeMemberApplications] = useState(
    []
  )
  const [next, setNextPage] = useState(null)
  const [prev, setPrevPage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const handleLoading = (value) => {
    setLoading(value)
  }
  const handleSearchParameterChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      [e.target.name]: e.target.value,
    })
    // if(e.target.name === 'name_of_iste_chapter'){
    //   setSearchInstitute(e.target.value)
    //   console.log(filteredSuggestion)
    // }
  }

  const getData = async () => {
    console.log(searchParameters)
    const data = await getInstititeAndConvenerApplications({
      ...searchParameters,
      page: page,
    });
    if (data?.data?.results.results) {
      console.log(data?.data)
      console.log(data?.data?.results.results)
      console.log(data?.data?.results.count)
      setLifeTimeMemberApplications(data?.data?.results.results)
      setCount(data?.data?.results.count)
      setNextPage(data?.data?.results.next?.page)
      setPrevPage(data?.data?.results.previous?.page)
      handleLoading(false)
    }
  }
  const generateReport = async (start, end) => {
    try {
      const urlToCall = searchParameters.status === 'Completed' ? `${BASE_URL}/api/student-membership/applications/completed-report` : `${BASE_URL}/api/student-membership/applications/report`
      axios({
        url: urlToCall, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { ...searchParameters, start, end },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Student Membership Application Report - exported at ${moment().format(
            'YYYY-MM-DD-HH-MM-SS'
          )}.xlsx`
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }

  
  useEffect(() => {
    getData()
  }, [page])

  const getSuggestionData = async () => {
    const data =  await getInstituteSuggestion({
      searchInstitute: searchInstitute,
    })
    console.log(data.data)
    setSuggestionData(data.data)
  }

  useEffect(() => {
    getSuggestionData()
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} lg={6}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">
            Student Membership Applications
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <Paper elevation={5} className={classes.root}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={4} className="try">
              <Autocomplete
                // variant="outlined"
                name="name_of_iste_chapter"
                freeSolo
                value={searchParameters.name_of_iste_chapter}
                onChange={(event, newValue) => {
                    setSearchParameters({
                        ...searchParameters,
                        ["name_of_iste_chapter"]: newValue,
                    })
                }}
                options={suggestionData}
                renderInput={(params) => <TextField {...params} label="Name of Institution" />}
                fullWidth
              />
              {/* <div classname="dataResult" style={{"height":"200px","backgroundColor":"white","boxShadow":"rgba(0, 0, 0, 0.35) 0px 5px 15px","overflow":"hidden","overflowY":"auto","borderStyle":"solid","borderColor":"grey","borderWidth":"1px","borderTop":"0px","zIndex":"1"}}>
                {filteredSuggestion.map((item)=>{
                  return(<a className='dataItem'>
                  <p>{item}</p>
                </a>)
                })} 
            </div> */}
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <TextField
                variant="outlined"
                name="institute_membership_id"
                label="Institution Membership No"
                value={searchParameters.institute_membership_id}
                onChange={handleSearchParameterChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <TextField
                variant="outlined"
                name="status"
                label="Status"
                value={searchParameters.status}
                onChange={handleSearchParameterChange}
                fullWidth
                select
              >
                {['InProgress', 'Approved', 'Rejected', 'Completed'].map(
                  (value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <TextField
                variant="outlined"
                name="section_code"
                label="Section Code"
                fullWidth
                value={searchParameters.section_code}
                onChange={handleSearchParameterChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <TextField
                variant="outlined"
                name="student_chapter_code"
                label="Chapter Code"
                fullWidth
                value={searchParameters.student_chapter_code}
                onChange={handleSearchParameterChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                name="startDate"
                fullWidth
                value={searchParameters.startDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => {
                  setSearchParameters({ ...searchParameters, startDate: date })
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="To"
                name="endDate"
                format="dd/MM/yyyy"
                fullWidth
                value={searchParameters.endDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => {
                  setSearchParameters({ ...searchParameters, endDate: date })
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} align="center">
              <Button variant="contained" color="primary" onClick={getData}>
                <SearchIcon /> Search
              </Button>
              <GenerateReportDialog
                count={count}
                searchParameters={searchParameters}
                generateReport={generateReport}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={5} className={classes.root}>
          <Paper className={classes.root}>
            {loading ? (
              <div align="center" className={classes.loading}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lifeTimeMemberApplications.map((row, index) => {
                        return (
                          <TableRow
                            key={row._id}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell>
                              {`${index+1}`}
                            </TableCell>
                            <TableCell>
                              {`${row.institute_membership_id}`}
                            </TableCell>
                            <TableCell>{row.name_of_iste_chapter}</TableCell>
                            <TableCell>
                              {`${row.first_name} ${row.last_name}`}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.membership_id}</TableCell>
                            <TableCell>{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                component={Link}
                                //   /student-membership-applications/students/:applicationId
                                onClick={() =>
                                  window.open(
                                    `${BASE_URL}/dashboard/student-membership-applications/students/${row._id}`,
                                    '_blank'
                                  )
                                }
                                // to={`/dashboard/student-membership-applications/students/${row._id}`}
                              >
                                <OpenInNewIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box align="right">
                  <Button to={`${prev}`} component={Link} disabled={!prev}>
                    <NavigateBeforeIcon />
                  </Button>
                  <Button to={`${next}`} component={Link} disabled={!next}>
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default StudentApplications
