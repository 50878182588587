export const initialStateOfInstituteAndConvener = {
    first_name : "",
    last_name : "",
    email : "", 
    membership_id : "",
    institute_membership_id : "",
    name_of_iste_chapter : "",
    new_chapter : false
}

export const initialOfStateStudent = {
    first_name : "",
    last_name : "",
    student_email : "",
    date_of_birth: "",
    year_of_study: "",
    semester_of_study: "",
    branch_of_study: "",
    home_address: "",
    special_interests: "",
    career_preference: "",
    special_interests : ['Sports', 'Games','Reading', 'Literary', 'Drama', 'Music', 'Photography'],
    career_preference : [
        'Teaching',
        'Research Work',
        'Govt Job',
        'Public Sector Undertaking',
        'Private Industry',
        'Self Employement',
        'Higher Studies',
        'Management Studies'
    ],
    type_of_service_expected : [
        'Coaching for competitive examination,Job Interview etc',
        'Supervisory and communication skill development',
        'Training for self-employement',
        'Guidance on Job opportunities',
        'Arranging Training in industries and visits to Industry',
        'Awareness on social,cultural,and ethical values and norms',
        'Special Coaching,General counselling services',
    ],
}