import React from 'react';
import LifeTimeMembershipForm from '../../components/LifeTimeMembershipForm/LifeTimeMembershipForm';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import Header from '../../components/Header/Header';
import FormHeader from '../../components/FormHeader/FormHeader';

const LifeTimeMembership = () => {
    return (
        <>
        <FormHeader />
        <LifeTimeMembershipForm />
        <KJSIEIT />
        </>
    );
}

export default LifeTimeMembership;
