import React,{useState} from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Collapse,
    Typography,
    Grid,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    TextField
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';

const Row = ({idx,value, states,  handleDeleteDistrict, handleUpdateDistrict})=>{
    const [open, setOpen] = useState(false);
    const [districtData,setDistrictData] = useState({...value, state : value?.state?._id}); 
    const classes = useStyles();
    console.log(value);
    const handleChange = (e)=>{
        setDistrictData({...districtData,[e.target.name] : e.target.value});
    }
    const handleUpdate = async ()=>{
        setOpen(false);
        await handleUpdateDistrict({
            name : districtData.name,
            id : districtData._id,
            state : districtData.state,
        });
    }
    const handleDelete = async ()=>{
        setOpen(false);
        await handleDeleteDistrict({
            id : districtData._id,
        });
    }
    return (
        <>
        {
            !open ? 
            (
                <TableRow>
                    <TableCell colSpan={2}>
                        {value.name}
                    </TableCell>
                    <TableCell colSpan={2}>
                        {value.state?.name}
                    </TableCell>
                    <TableCell>
                        <Button variant="outlined" size="small" onClick={()=>{setOpen(true)}}>
                            Edit
                        </Button>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
            ) : 
            (
                <TableRow>
                    <TableCell colSpan={2}>      
                        <TextField 
                            className={classes.tableTextField}
                            variant="outlined" 
                            label="Name of District"
                            size="small"
                            name="name"
                            value={districtData.name}
                            onChange={handleChange}
                            
                        >
                        </TextField>
                    </TableCell>
                    <TableCell colSpan={2}>      
                        <FormControl size="small" fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel>State</InputLabel>
                                <Select
                                label="State"
                                name="state"
                                value={districtData?.state}
                                onChange={handleChange}
                                >
                                    {
                                        states.map((value,idx)=>(
                                        <MenuItem value={value._id}>
                                            {value.name}
                                        </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="outlined" 
                            size="small" 
                            onClick={handleUpdate}
                            color="primary"
                        > 
                            Update
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="contained" 
                            size="small"
                            onClick={handleDelete}
                            color="secondary"
                        > 
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }
        </>
    );
}

const Districts = ({districts, districtQuery, states, handleDeleteDistrict, handleUpdateDistrict, handleDistrictQueryChange}) => {
    return (
        <Paper elevation={5}>
        <TableContainer>
            <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            Name of Districts
                        </TableCell>
                        <TableCell colSpan={2}>
                            Name of State
                        </TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={()=>handleDistrictQueryChange()}
                            >
                                View All
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {districts
                    .filter(
                        (value,idx) =>
                        (!districtQuery?.state || value.state?._id===districtQuery.state))
                    .map((value,idx)=>{
                    return (
                        <Row 
                            key={value._id}
                            id={value._id}
                            idx={idx} 
                            value={value} 
                            states={states} 
                            handleUpdateDistrict={handleUpdateDistrict}
                            handleDeleteDistrict={handleDeleteDistrict}
                        />
                    );
                })}
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    )
}

export default Districts;
