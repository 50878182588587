import React from 'react';
import { Grid,Breadcrumbs,Typography,Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';

import AddEmployee from '../../components/AddEmployee/AddEmployee';
import ViewEmployees from '../../components/ViewEmployees/ViewEmployees';

const AdminControl = () => {
    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Admin Controls</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <AddEmployee />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <ViewEmployees />
            </Grid>
        </Grid>
        </>
    )
}

export default AdminControl;
