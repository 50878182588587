import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 280;

export default makeStyles((theme) => ({
    marginCard : {
        marginTop : theme.spacing(3),
        marginBottom : theme.spacing(3)
    }  ,
    card : {
        borderRadius :theme.spacing(2),
        marginTop : theme.spacing(1),
        // backgroundColor : theme.palette.grey[200],
        borderWidth: "10px", 
        borderColor : '#010057' ,
        marginLeft : theme.spacing(2),
        marginRight : theme.spacing(2) 
    } ,
    title : {
        color : '#010057',
        marginBottom : theme.spacing(4)
    }
}));