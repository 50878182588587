import React from "react";
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import FormAlertDialog from '../FormAlertDialog/FormAlertDialog';

const Preview = ({ formData, togglePreview, submitForm, stateData,districtsData,sections }) => {
  const classes = useStyles();
  const states = stateData.filter((value)=>value._id===formData.state);
  const district = districtsData.filter((value)=>value._id===formData.district);
  const section = sections.filter((value)=>value._id===formData.section);
  return (
    <Box border={1} px={2} m={1}>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography
          className={classes.formTitle}
          component="h5"
          variant="h5"
          align="center"
        >
          Preview Application Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12} justify="center">
        <Typography component="body1" variant="body1">
          Institute Details
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Name of Institution/Industry/Organsation</b> 
              </Grid>
              <Grid item>
              : {formData.nameOfInstitution}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} variant="body1">
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Address for Correspondence</b> 
              </Grid>
              <Grid item>
              : {formData.addressForCorrespondence}
              </Grid>
          </Grid>
          
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Pincode for Correspondence</b> 
              </Grid>
              <Grid item>
              : {formData.pincodeForCorrespondence}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Phone No. for Correspondence</b> 
              </Grid>
              <Grid item>
              : {formData.phoneNumberForCorrespondence}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Alternate Contact Number</b> 
              </Grid>
              <Grid item>
              : {formData.telephoneNumberForCorrespondence}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Institute Email ID</b> 
              </Grid>
              <Grid item>
              : {formData.instituteEmail}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Alternate Email ID</b> 
              </Grid>
              <Grid item>
              : {formData.alternateEmail}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Nature of Principal Activity</b> 
              </Grid>
              <Grid item>
              : {formData.natureOfPrincipalActivity}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Category of Institution</b> 
              </Grid>
              <Grid item>
              : {formData.categoryOfInstitution}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Type of Institution</b> 
              </Grid>
              <Grid item>
              : {formData.typeOfInstitute}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Name of State</b> 
              </Grid>
              <Grid item>
              : {states && states[0]?.name}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Name of District</b> 
              </Grid>
              <Grid item>
              : {district && district[0]?.name}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Section</b> 
              </Grid>
              <Grid item>
              : {section && section[0]?.section_name}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12} justify="center"  className={classes.title} >
        <Typography component="body1" variant="body1" className={classes.title}>
          Officer Incharge Details
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Name of Officer Incharge</b> 
              </Grid>
              <Grid item>
              :  {formData.firstNameOfOfficerIncharge +
            " " +
            formData.lastNameOfOfficerIncharge}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Email ID of Officer Incharge</b> 
              </Grid>
              <Grid item>
              : {formData.emailOfOfficerIncharge}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Designation</b> 
              </Grid>
              <Grid item>
              : {formData.designation}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Address of Officer Incharge</b> 
              </Grid>
              <Grid item>
              : {formData.addressNameOfTheOfficerIncharge}
              </Grid>
          </Grid>
        </Typography>
      </Grid>

      
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} >
          <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
              <b>Phone Number of Officer Incharge</b> 
              </Grid>
              <Grid item>
              : {formData.phoneNumberOfOfficerIncharge}
              </Grid>
          </Grid>
        </Typography>
      </Grid>
      

      {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Typography className={classes.previewTitle} variant="body1">
          <b>Date of Submission</b> :{" "}
          {formData.dateOfSubmission}
        </Typography>
      </Grid> */}

      {/* <Grid container justify="center" spacing="-5"> */}
      <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          onClick={togglePreview}
          className={classes.button}
        >
          Back
        </Button>
        <FormAlertDialog  title="Submit Institutional Membership Form?" handleSubmit={submitForm}/>
        {/* <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={submitForm}
          className={classes.button}
        >
          Submit
        </Button> */}
      </Grid>
    </Box>
  );
};

export default Preview;
