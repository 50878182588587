import React from "react";
import { Paper, Box, makeStyles, Grid,Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import SocialMedia from "../SocialMedia/SocialMedia";
import icon1 from "../../assets/images/somaiya1.png";
// import icon2 from "../../assets/images/somaiya2.jpeg";
import logo1 from '../../assets/images/logo1.png';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  image : {
    width : theme.spacing(10)
  }
}));

const KJSIEIT = () => {
  const classes = useStyles();
  return (
    <Box  borderColor="rgba(0, 0, 0, 0.1)">
      <Paper
        className={classes.paper}
        elevation={2}
        style={{ overflowY: "hidden" }}
      >
        <Grid container align="center">
          <Grid item xs={4} sm={4}>
            <div >
              <img className={classes.image} alt="" src={logo1} />
            </div>
          </Grid>
          <Grid item xs={6} md={12} lg={8} align="left">
              <br/>
              <Typography variant="h5">Indian Society For Technical Education</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default KJSIEIT;
