import React,{useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Collapse } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Link , useRouteMatch} from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import useStyles from './styles';
import { getLifeTimeRegistrationApplications } from '../../api'; 
import moment from "moment"

const columns = [
  { id: 'index', label: "Serial Number"},
  { id: 'name', label: 'Full Name'},
  { id: 'email', label: 'Email' },
  { id: 'mobile_no', label: 'Phone No' },
  { id: 'state',label: 'State'},
  { id: 'city',label: 'city'},
  { id: 'chapter_code',label: 'Chapter Code'},
  { id: 'date_of_creation', label: 'Date of Creation'},
  { id: 'view', label: 'View'},
];

export default function LifeTimeMembershipTable()  {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loading,setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const [lifeTimeMemberApplications, setLifeTimeMemberApplications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleLoading = (value)=>{
    setLoading(value);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = async ()=>{
    const data = await getLifeTimeRegistrationApplications();
    if(data?.data?.result){
      setLifeTimeMemberApplications(data?.data?.result);
      handleLoading(false);
    }
  }
  useEffect(()=>{
    getData();
  },[setLifeTimeMemberApplications, setLoading]);
  // getData();

  return (
    <Paper className={classes.root} elevation={5}>
      { loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) :  (
          <>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lifeTimeMemberApplications.map((row, index) => {
                return (
                  <TableRow key={row._id} hover role="checkbox" tabIndex={-1}>
                      <TableCell >
                            {`${index+1}`}
                      </TableCell>
                      <TableCell >
                            {`${row.first_name} ${row.last_name}`}
                      </TableCell>
                      <TableCell>
                            {row.email}
                      </TableCell>
                      <TableCell>
                            {row.mobile_no}
                      </TableCell>
                      <TableCell>
                            {row.state}
                      </TableCell>
                      <TableCell>
                            {row.city}
                      </TableCell>
                      <TableCell>
                            {row.chapter_code}
                      </TableCell>
                      <TableCell>
                            {moment(row.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      
                      <TableCell>
                          <Button 
                          size="small"
                          variant="outlined" 
                          color="primary" 
                          component={Link} 
                          onClick={() => window.open(`${path}/${row._id}`,"_blank")}
                          // to={`${path}/${row._id}`}
                          >
                            <OpenInNewIcon />
                          </Button>
                      </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </>
        )}
    </Paper>
  );
}
