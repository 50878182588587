import React from 'react';
import AddInstitutionalMemberComponent from '../../components/AddInstituteMemberComponent/AddInstituteMemberComponent';

const AddInstitutionalMember = () => {
    return (
        <div>
            <AddInstitutionalMemberComponent />
        </div>
    )
}

export default AddInstitutionalMember;
