import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Paper
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import EditIcon from "@material-ui/icons/Edit";
import changePassword from '../../actions/auth';
import { getUserData, updatePassword } from '../../api/user'

const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  paper : {
    // margin : theme.spacing(1),
    marginBottom : theme.spacing(5),
    borderRadius : theme.spacing(4),
    padding : theme.spacing(2)
  }
}));


const ProfileInformation = (props) => {
  const classes = useStyles();
    const [user, setUser] = useState({
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      employee_id : ''
    });
    const [passwords, setPasswords] = useState({});
    const handleChange = (e)=>{
      setPasswords({...passwords, [e.target.name] : e.target.value});
    }
    const handleSubmit = async (e)=>{
      e.preventDefault();
      if(passwords.new_password !== passwords.new_password2){
          toast.info('Passwords are not same', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
      }
      try{
        const data = await updatePassword({
          old_password : passwords.old_password, 
          new_password : passwords.new_password
        })
        if(data?.status === 200){
          toast.info('Password Changed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }else{
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }catch(error){
        console.log(error);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    }
    const getData = async ()=>{
      try{
        const data = await getUserData();
        console.log(data);
        if(data?.status===200){
          setUser(data?.data?.result);
        }
      }catch(error){
        toast.error('Server Error!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  }

  useEffect(()=>{
    getData();
  },[]);


  return (
    <>
      <Card className={classes.paper} elevation={5}>
        <CardHeader
          title="Your Profile"
          subheader= "To update your details, contact the admin"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              InputProps={{
                readOnly: true,
              }}
                fullWidth
                label="First name"
                name="firstName"
                value={user.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              InputProps={{
                readOnly: true,
              }}
                fullWidth
                label="Last name"
                name="lastName"
                value={user.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              InputProps={{
                readOnly: true,
              }}
                fullWidth
                label="Email Address"
                name="email"
                value={user.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              InputProps={{
                readOnly: true,
              }}
                fullWidth
                label="Employee ID"
                name="id"
                value={user.employee_id}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Divider/> */}
      <Card className={classes.paper} elevation={5}>
        <CardHeader title = "Update Password"></CardHeader>
        <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid
            container
            spacing={3}
            align="center"
          >
            <Grid item md={12} xs={12} align="center">
              <TextField
                fullWidth
                label="Enter Old Password"
                name="old_password"
                variant="outlined"
                required
                type="password"
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={12} xs={12} >
              <TextField
                fullWidth
                label="Enter New Password"
                name="new_password"
                variant="outlined"
                required
                type="password"
                onChange={handleChange}
              />
              
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Confirm New Password"
                name="new_password2"
                variant="outlined"
                required
                type="password"
                onChange = {handleChange}
              />
              
            </Grid>


              <Grid item md={12} xs={12} align="center" justify="center">
                {/* <Box alignItems="center" m={3}> */}
                    <Button
                          variant="contained"
                          color="primary"
                          startIcon={<EditIcon />}
                          type="submit"
                    >
                    Update
                  </Button>
                {/* </Box> */}
              </Grid>
            </Grid>
            </CardContent>
            </form>
      </Card>
    </>
  );
};

export default ProfileInformation;





              