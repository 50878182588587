import React,{ useState } from 'react';
import { 
    Grid, 
    Typography, 
    Divider, 
    TextField, 
    MenuItem
} from '@material-ui/core';

const StudentForm = ({
    student, 
    handleStudentDataChange,
    setStudentData, 
    handleSheetUpload,
    studentDataError
}) => {
    
    return (
        <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Typography  variant="h6">
                            Add Student Details Individually 
                        </Typography>
                        <Typography  >
                            Or upload in bulk using excel format given above 
                        </Typography>
                        <Typography  variant="p" style={{ fontStyle: 'italic' }}>
                            Please upload/add <strong>unique e-mail id</strong> and <strong>mobile number</strong>
                        </Typography>
                            
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "fullname"
                                label= "Full Name"
                                error={studentDataError.fullname}
                                helperText={studentDataError.fullname && `Student Name should be less than 22 characters if it exceeds then please restrict name as per your short name  
                                For example : RAVEENDRA JAADHAV MOHAPATRA may be restricted as RAVEENDRA J. MOHAPATRA`}
                                value={student.fullname}
                                onChange={handleStudentDataChange}
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "duration"
                                label= "Duration of Membership"
                                error={studentDataError.duration}
                                helperText={studentDataError.duration && 'Enter a Number between 2 and 6'}
                                value={student.duration}
                                onChange={handleStudentDataChange}
                                fullWidth
                                select
                                size="small"
                                variant="outlined"
                            >
                                {[2,3,4,5].map((value)=>(
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "branch"
                                label= "Branch"
                                error={studentDataError.branch}
                                helperText={studentDataError.branch && 'Invalid Entry'}
                                value={student.branch}
                                onChange={handleStudentDataChange}
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "mobile_no"
                                label= "Mobile No. "
                                error={studentDataError.mobile_no}
                                helperText={studentDataError.mobile_no && 'Invalid Entry'}
                                value={student.mobile_no}
                                onChange={handleStudentDataChange}
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "email"
                                label= "Email"
                                error={studentDataError.email}
                                helperText={studentDataError.email && 'Invalid Entry'}
                                value={student.email}
                                onChange={handleStudentDataChange}
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "gender"
                                label= "Gender"
                                error={studentDataError.gender}
                                helperText={studentDataError.gender && 'Please Select Gender'}
                                value={student.gender}
                                onChange={handleStudentDataChange}
                                fullWidth
                                size="small"
                                variant="outlined"
                                select
                            >
                                {[
                                    {value : 'M', name : 'Male'},
                                    {value : 'F', name : 'Female'},
                                    {value : 'O', name : 'Others'},
                                ].map((option) => (
                                    <MenuItem key={option._id} value={option.value}>
                                    {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                    </Grid>
                    
                    
                </Grid>
        </div>
    )
}

export default StudentForm
