import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
        borderRadius : theme.spacing(2)
      },
}));
const MembershipPolicy = () => {
    const classes = useStyles();
    return (
        <div>
            <FormHeader></FormHeader>
            <Paper className={classes.paper}>
                <br/>
                <h2> TERMS & CONDITIONS </h2>
<p>A. Terms of Services on the Indian Society for Technical Education (ISTE) Website and/or Mobile Apps: <br /> <br />
    The terms refer to the Society as platform for user friendly community commerce
</p>

<h3> General </h3>

<p>
    In terms of Information Technology Act, 2000, this document is an electronic record. Being generated by a computer
    system it does not require any physical or digital signatures. This document is published in accordance with the
    provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require
    publishing
    the rules and regulations, privacy policy and Terms of Use for access or usage of www.isteonline.in and all other
    associated web sites and applications
</p>

<p>
    (a) The domain name www.isteonline.in (hereinafter referred to as “the Website”) is owned by Indian Society for
    Technical
    Education (ISTE), New Delhi hereinafter referred to as the “Society”), having its registered office at Indian
    Society
    for Technical Education (ISTE), SJS Marg, Near Katwaria Saria, Opp. Sanskrit Vidyapeeth, New Delhi.
</p>

<p>
    (b) For the purpose of these Terms of Use, wherever the context so requires “You” or “User” shall mean any natural
    or
    legal person who has agreed to use the Website by providing Registration Data while registering on the Website. The
    term
    “ISTE” or “we” or “us” shall mean the Society. The headings of each section in this Agreement are only for the
    purpose
    of organizing the various provisions under this Agreement in an orderly manner. These headings shall not be used by
    either party to interpret the provisions contained with them in any manner. Further, the headings have no legal or
    contractual value.
</p>

<p>
    (c) The use of this Website by you is solely governed by these terms and conditions and any policy so mentioned by
    terms
    of reference. Moving past home page or using any of the services mentioned on the website shall be taken to mean
    that
    you have read and agreed to be bound by all of the policies. You will be subject to the rules, guidelines, policies,
    terms, and conditions applicable to any service that is provided by this site, and they shall be deemed to be
    incorporated into these Terms of Use and shall be considered a part and parcel of these Terms of Use.
</p>

<p>
    (d) We hold the sole right to modify the Terms of Service without prior permission from you or informing You. The
    relationship creates a duty on you to periodically check the terms and stay updated on its requirements. If you
    continue
    to use the website following such a change, this is deemed as consent by you to the so amended policies. As long as
    you
    comply with these Terms of Use, We grant you a personal, non-exclusive, non-transferable, limited privilege to enter
    and
    use the Website.
</p>

<p>
    ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE
    TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these
    Terms of Use, You also accept and agree to be bound by ISTE Policies (including but not limited to Privacy Policy
    available at the website) as amended from time to time.
</p>

<p>
    You, as a Buyer, may agree with the Seller/service provider through electronic communication and electronic records
    and
    using the automated features as may be provided by Payment Facility on any extension / increase in the Dispatch
    and/or
    Delivery time and the Transaction shall stand amended to such extent. Any such extension / increase of
    Dispatch/Delivery
    time or subsequent novation/variation of the Transaction should be in compliance with Payment Facility Rules and
    Policies.
</p>

<p>
    You, as a Buyer, shall electronically notify Payment Facility using the appropriate ISTE Website features
    immediately
    upon Delivery or non Delivery within the time period as provided in Policies. Refund, if any, shall be made at the
    same
    Issuing Bank from where Transaction Price was received.
</p>

<p>
    ISTE reserves the right to impose limits on the number of Transactions or Transaction Price which it may receive
    from on
    an individual Valid Credit/Debit/ Cash Card/Valid Bank Account/ and such other infrastructure or any other financial
    instrument directly or indirectly through payment aggregator or through any such facility authorized by Reserve Bank
    of
    India to provide enabling support facility for collection and remittance of payment or by an individual Buyer during
    any
    time period, and reserves the right to refuse to process Transactions exceeding such limit.
</p>

<ul>

    <li>
        ISTE reserves the right to refuse to process Transactions by Buyers with a prior history of questionable charges
        including without limitation breach of any agreements with ISTE or breach/violation of any law or any charges
        imposed by
        Issuing Bank or breach of any policy.
    </li>

    <li>
        ISTE may do such checks as it deems fit before approving the receipt of/Buyers commitment to pay (for Cash On
        Delivery
        transactions) Transaction Price from the Buyer for security or other reasons at the discretion of ISTE. As a
        result of
        such check if ISTE is not satisfied with the creditability of the Buyer or genuineness of the Transaction /
        Transaction
        Price, it will have the right to reject the receipt of / Buyers commitment to pay Transaction Price.
    </li>

    <li>
        ISTE may delay notifying the payment confirmation i.e. informing Seller/service provider, if ISTE deems
        suspicious or
        for Buyers/service providers conducting high transaction volumes to ensure safety of the Transaction and
        Transaction
        Price.
    </li>

    <li>
        The Buyer/Seller/service provider/ acknowledge that ISTE will not be liable for any damages, interests or claims
        etc.
        resulting from not processing a Transaction/Transaction Price or any delay in processing a
        Transaction/Transaction
        Price.

    </li>

    <li>
        Buyer/seller/service provider shall comply with all the applicable laws (including without limitation Foreign
        Exchange
        Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as
        may be
        issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended
        by the
        Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there
        under,
        Foreign Contribution Regulation Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules
        made there
        under, Export Import Policy of government of India).
    </li>

</ul>
            </Paper>
            <KJSIEIT></KJSIEIT>
        </div>
    )
}

export default MembershipPolicy
