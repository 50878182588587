import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import InputForm from "./InputForm";
import Preview from "./Preview";
import validation from "./validation";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as api from "../../api/registrationForm";


import { toast } from 'react-toastify';

const natureOfPrincipalActivity = [
  {
    value: "EDUCATIONAL",
    label: "EDUCATIONAL",
  },
  {
    value: "MANUFACTURING",
    label: "MANUFACTURING",
  },
  {
    value: "CONSULTANCY",
    label: "CONSULTANCY",
  },
  {
    value: "R&D/Corporate Sector",
    label: "R&D/Corporate Sector",
  },
  {
    value: "CONSTRUCTION",
    label: "CONSTRUCTION",
  },
  {
    value: "OTHERS",
    label: "OTHERS",
  },
];

const designation = [
  {
    value : 'Professor',
    label : 'Professor'
  },
  {
    value : 'Associate Professor',
    label : 'Associate Professor'
  },
  {
    value : 'Assistant Professor',
    label : 'Assistant Professor'
  },
  {
    value : 'Lecturer',
    label : 'Lecturer'
  },
  {
    value : 'Staff',
    label : 'Staff'
  },
]


const categoryOfInstitution = [
  {
    value: "UNIVERSITY",
    label: "UNIVERSITY",
  },
  {
    value: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
    label: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
  },
  {
    value: "ENGINEERING COLLEGE",
    label: "ENGINEERING COLLEGE",
  },
  {
    value: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
    label: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
  },
  {
    value: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
    label: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
  },
  {
    value: "POLYTECHNICS",
    label: "POLYTECHNICS",
  },
  {
    value: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
    label: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
  },
  {
    value: "CORPORATE INSTITUTIONAL MEMBER",
    label: "CORPORATE INSTITUTIONAL MEMBER",
  },
  {
    value: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
    label: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
  },
  {
    value: "STATUTORY BODIES",
    label: "STATUTORY BODIES",
  },
  {
    value: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
    label: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
  },
];

const initialState = {
  nameOfInstitution: "",
  addressForCorrespondence: "",
  pincodeForCorrespondence: "",
  telephoneNumberForCorrespondence: "",
  phoneNumberForCorrespondence: "",
  instituteEmail: "",
  alternateEmail: "",
  natureOfPrincipalActivity: "",
  categoryOfInstitution: "",
  firstNameOfOfficerIncharge: "",
  lastNameOfOfficerIncharge: "",
  emailOfOfficerIncharge: "",
  designation: "",
  addressNameOfTheOfficerIncharge: "",
  phoneNumberOfOfficerIncharge: "",
  state : "",
  district : null,
  // dateOfSubmission : "",
};

export default function InstitutionalMembershipForm() {
  const classes = useStyles();
  const [formData,setFormData] = useState(initialState);
  const [ formError, setFormError ] = useState({});
  const [preview, setPreview] = useState(false);
  const [backDrop,setBackDrop] = useState(false);
  const [ dialog,setDialog ] = useState(false);
  const [signature, setSignature] = useState(null);
  const [termsAndConditions, setTermsAndConditions]  = useState(false);
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [sections,setSections] = useState([]);
  


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getStates = async ()=>{
    try{
        const data = await api.getAllStates();
        console.log(data);
        if(data?.status === 200){
          setStatesData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
const getDistricts = async ()=>{
    try{
        const data = await api.getAllDistricts();
        console.log(data);
        if(data?.status === 200){
          setDistrictsData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
  const getSections = async ()=>{
    try{
        const data = await api.getAllSections();
        console.log(data);
        if(data?.status === 200){
          setSections(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
  }

  const openDialog = ()=>{
    setDialog(true);
  }
  const closeDialog = ()=>{
    setDialog(false);
    window.location.reload();
  }

  const closeBackDrop = ()=>{
    setBackDrop(false);
    
  }
  const toggleBackDrop = ()=>{
    setBackDrop(!backDrop);
  }
  
  const submitForm = async ()=>{
    console.log(formData);
    // console.log(file);
    toggleBackDrop();
    try{
      const newFormData = new FormData();
      delete formData.state;
      for(const dataItem in formData){
        newFormData.append(dataItem,formData[dataItem])
      }
      
      
    
      newFormData.append(
        'signature', 
        signature
      );
      console.log(newFormData);

      const result = await api.institutionalRegistration(newFormData);
      console.log(result);
      console.log(result.status);
      if(result.status===200){
        closeBackDrop();
        openDialog();
      }
    }catch(error){
      console.log(error.response);
    }
    toggleBackDrop();

  }
  const handleSignatureUpload = e =>{
    if(e.target.files[0].size > 500000){
      toast('The filesize for Signature should be less than 500KB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setSignature(e.target.files[0]);
  }

  const handleChange = async (e)=>{
    
      setFormData((prevState)=>{
        return {...prevState,[e.target.name]:e.target.value}
      })
  }
  
  const handleSubmit = (e)=>{
    e.preventDefault();
    let isFormValid = true;
    // validations 
    const newFormError = validation(formData,formError);
    if(!termsAndConditions) {
      isFormValid = false;
          toast('Please pledge undertaking', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
    if(!signature) {
      isFormValid = false;
      toast('Please Upload Digital Signature', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    // checks if any of the fields are not validated 
    // and changes isFormValid accordingly 
    for(const key in newFormError){
      if(newFormError[key]){
        isFormValid = false;
      }
    }
    setFormError(newFormError);
    if(!isFormValid) return;
    togglePreview();
  }

  const togglePreview = ()=>{
    setPreview(!preview);
  }

  useEffect(()=>{
    getStates();
    getDistricts();
    getSections();
    
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={6}>
          { preview ? 
            <Preview 
              formData={formData} 
              togglePreview={togglePreview} 
              submitForm={submitForm} 
              stateData={stateData}
              districtsData={districtsData}
              sections={sections}
            /> : 
            <InputForm 
              formData={formData} 
              formError = {formError}
              handleSubmit={handleSubmit} 
              handleChange={handleChange} 
              natureOfPrincipalActivity = {natureOfPrincipalActivity}
              designation = {designation}
              categoryOfInstitution={categoryOfInstitution}        
              handleSignatureUpload={handleSignatureUpload}
              signature={signature}
              setFormData={setFormData}
              setTermsAndConditions={setTermsAndConditions}
              termsAndConditions={termsAndConditions}
              stateData={stateData}
              districtsData={districtsData}
              sections={sections}
            /> 
            
          }
        </Paper>
      </main>
      <Backdrop className={classes.backdrop} open={backDrop} onClick={closeBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={dialog}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Institutional Registration Form Successfully Submiited"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You form have been submitted. Check your email for confirmation 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}