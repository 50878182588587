import axios from 'axios';
import {toast} from 'react-toastify';
import BASE_URL from './constants';


const API = axios.create(
    { baseURL: `${BASE_URL}/api` },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
    }}
);

export const recommendersApproval = (data) => API.post('/life-time-membership/application/recommenders-approval/', data);
export const recommendersRejection = (data) => API.post('/life-time-membership/application/recommenders-rejection/', data);



export const lifeTimeRegistration = (formData) => API.post('/life-time-membership/registration', formData);
export const institutionalRegistration = (formData) => API.post('/institute-membership/registration', formData);
export const studentRegistration = (formData) => API.post('/student-membership/registration', formData);
export const getAllInstituteName = (formData) => API.get('/institute-membership/all-institutes', formData);


export const getAllDistricts = ()=> API.get('/region/districts/view');
export const getAllStates = ()=> API.get('/region/states/view');
export const getAllSections = ()=> API.get('/region/sections/view');
export const getAllStudentSections = ()=> API.get('/student-chapters/student-chapter-sections');
  