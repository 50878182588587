import React from 'react';
import {
    Typography,
    Link,
    AppBar,
    CssBaseline,
    Toolbar,
    Container,
    Grid,
    Card,
    Box,
    Button,
    CardHeader,
    CardActions,
    CardContent,
    CardActionArea,
    CardMedia
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import useStyles from './styles';

  
const tiers = [
    {
      title: 'Free',
      price: '0',
      description: [
        '10 users included',
        '2 GB of storage',
        'Help center access',
        'Email support',
      ],
      buttonText: 'Sign up for free',
      buttonVariant: 'outlined',
    },
    {
      title: 'Pro',
      subheader: 'Most popular',
      price: '15',
      description: [
        '20 users included',
        '10 GB of storage',
        'Help center access',
        'Priority email support',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
    {
      title: 'Enterprise',
      price: '30',
      description: [
        '50 users included',
        '30 GB of storage',
        'Help center access',
        'Phone & email support',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
    },
];
  







  
function LandingPageCards() {
  const classes = useStyles();
    return (
      <React.Fragment>
        <Container maxWidth="xl" component="main" className={classes.marginCard}>
          <div align="center" className={classes.title}>
              <Typography gutterBottom variant="h3" component="h2">
                 <b>ISTE Membership Portal</b>
              </Typography>
          </div>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12} sm={12} lg={4}>
            <Link href="/life-time-membership/registration" style={{textDecoration :'none'}}>
              <Card className={classes.card} elevation={5}>
                  <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image="/assets/images/regformcard.png"
                        title="Contemplative Reptile"
                      />
                    <CardContent>
                      <Typography gutterBottom variant="h5" style={{fontSize : '1.9rem'}} component="h3">
                        <b>Life Membership Form</b>
                      </Typography>
                      <br/>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Click Here to fill out the Life Membership Form
                      </Typography>
                      <br/>
                      <br/>
                      <br/>
                        <br/>
                      <Button variant="contained" color="primary"  fullWidth>
                        Apply for Life Membership
                      </Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Link>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
            <Link href="/institutional-membership/registration" style={{textDecoration :'none'}}>
                <Card className={classes.card} elevation={5}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" style={{fontSize : '1.9rem'}} component="h3">
                        <b>Institutional Membership Form</b>
                        </Typography>
                        <br/>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click Here to fill out the Institutional Membership Form
                        </Typography>
                        <br/>
                        <br/>

                      
                      <Button variant="contained" color="primary" fullWidth>
                        Apply for Institutional Membership
                      </Button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
            </Link>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <Link href="/student-membership/registration" style={{textDecoration :'none'}}>
                <Card className={classes.card} elevation={5}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" style={{fontSize : '1.9rem'}}  component="h3">
                          <b>Student Membership Form</b>
                        </Typography>
                        <br/>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click Here to fill out the Student Membership Form
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                      <br/>
                      <Button variant="contained" color="primary"  fullWidth>
                        Apply for Student Membership
                      </Button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} align="center">
              
              <Typography style={{fontSize:'1rem' }}>
              <Link href="privacy-policy" style={{ textDecoration: "none", color: "#3F51B5"}}>
                <b>Privacy Policy </b>
                </Link>
                and 
              <Link href="/terms-and-conditions" style={{ textDecoration: "none", color: "#3F51B5"}}>
                <b> Terms & Conditions</b>
                </Link>
                
                </Typography>
                
              
              
            </Grid>
          </Grid>
        </Container>
        {/* Footer */}
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
        </Container>
        {/* End footer */}
      </React.Fragment>
    );
}

export default LandingPageCards;
