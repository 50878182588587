import React from 'react';
import { Grid,Breadcrumbs,Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LifeTimeMembersTable from '../../components/LifeTimeMembersTable/LifeTimeMembersTable';

const LifeTimeMembers = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Life Time Members</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                    <LifeTimeMembersTable />
            </Grid>
        </Grid>
        
    )
}

export default LifeTimeMembers;
