import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import InputForm from "./InputForm";
import validation from "./validation";
import {
  CircularProgress,
  Grid,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import * as api from "../../api";
import { getAllInstituteName } from "../../api/registrationForm";
import { Link } from "react-router-dom";
import convertUTCDateToLocalDate from "../../utils/dateConverter";
import { toast } from "react-toastify";

const initials = [
  {
    value: "Dr",
    label: "Dr",
  },
  {
    value: "Mr",
    label: "Mr",
  },
  {
    value: "Mrs",
    label: "Mrs",
  },
  {
    value: "Miss",
    label: "Miss",
  },
  {
    value: "Mx",
    label: "Mx",
  },
  {
    value: "Sh",
    label: "Sh",
  },
  {
    value: "Er",
    label: "Er",
  },
];
const genderCategory = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
  {
    value: "O",
    label: "Non-Binary",
  },
];

const branch = [
  {
    value: "AERONAUTICAL ENGINEERING",
    label: "AERONAUTICAL ENGINEERING",
  },
  {
    value: "AUTOMOBILE ENGINEERING",
    label: "AUTOMOBILE ENGINEERING",
  },
  {
    value: "BIO-MEDICAL ENGINEERING",
    label: "BIO-MEDICAL ENGINEERING",
  },
  {
    value: "BIO-TECHNOLOGY",
    label: "BIO-TECHNOLOGY",
  },
  {
    value: "CHEMICAL ENGINEERING",
    label: "CHEMICAL ENGINEERING",
  },
  {
    value: "CHEMICAL TECHNOLOGY",
    label: "CHEMICAL TECHNOLOGY",
  },
  {
    value: "CIVIL ENGINEERING",
    label: "CIVIL ENGINEERING",
  },
  {
    value: "CIVIL & INFRASTRUCTURE ENGINEERING",
    label: "CIVIL & INFRASTRUCTURE ENGINEERING",
  },
  {
    value: "COMPUTER ENGINEERING",
    label: "COMPUTER ENGINEERING",
  },
  {
    value: "COMPUTER SCIENCE & ENGINEERING",
    label: "COMPUTER SCIENCE & ENGINEERING",
  },
  {
    value: "ELECTRICAL & ELECTRONICS ENGINEERING",
    label: "ELECTRICAL & ELECTRONICS ENGINEERING",
  },
  {
    value: "ELECTRICAL ENGINEERING",
    label: "ELECTRICAL ENGINEERING",
  },
  {
    value: "ELECTRONICS ENGINEERING",
    label: "ELECTRONICS ENGINEERING",
  },
  {
    value: "ELECTRONICS & COMMUNICATION ENGINEERING",
    label: "ELECTRONICS & COMMUNICATION ENGINEERING",
  },
  {
    value: "ELECTRONICS & TELECOMMUNICATION ENGINEERING",
    label: "ELECTRONICS & TELECOMMUNICATION ENGINEERING",
  },
  {
    value: "ENVIRONMENTAL ENGINEERING",
    label: "ENVIRONMENTAL ENGINEERING",
  },
  {
    value: "ENVIRONMENTAL SCIENCE & TECHNOLOGY",
    label: "ENVIRONMENTAL SCIENCE & TECHNOLOGY",
  },
  {
    value: "ENVIRONMENTAL SCIENCE AND ENGINEERING",
    label: "ENVIRONMENTAL SCIENCE AND ENGINEERING",
  },
  {
    value: "FOOD PROCESSING TECHNOLOGY",
    label: "FOOD PROCESSING TECHNOLOGY",
  },
  {
    value: "INDUSTRIAL ENGINEERING",
    label: "INDUSTRIAL ENGINEERING",
  },
  {
    value: "INFORMATION TECHNOLOGY",
    label: "INFORMATION TECHNOLOGY",
  },
  {
    value: "INFORMATION & COMMUNICATION TECHNOLOGY",
    label: "INFORMATION & COMMUNICATION TECHNOLOGY",
  },
  {
    value: "INSTRUMENTATION & CONTROL ENGINEERING",
    label: "INSTRUMENTATION & CONTROL ENGINEERING",
  },
  {
    value: "MANUFACTURING ENGINEERING",
    label: "MANUFACTURING ENGINEERING",
  },
  {
    value: "MARINE ENGINEERING",
    label: "MARINE ENGINEERING",
  },
  {
    value: "MECHANICAL ENGINEERING",
    label: "MECHANICAL ENGINEERING",
  },
  {
    value: "MECHATRONICS ENGINEERING",
    label: "MECHATRONICS ENGINEERING",
  },
  {
    value: "METALLURGY ENGINEERING",
    label: "METALLURGY ENGINEERING",
  },
  {
    value: "MINING ENGINEERING",
    label: "MINING ENGINEERING",
  },
  {
    value: "PLASTIC TECHNOLOGY",
    label: "PLASTIC TECHNOLOGY",
  },
  {
    value: "POWER ELECTRONICS",
    label: "POWER ELECTRONICS",
  },
  {
    value: "PRODUCTION ENGINEERING",
    label: "PRODUCTION ENGINEERING",
  },
  {
    value: "ROBOTICS AND AUTOMATION",
    label: "ROBOTICS AND AUTOMATION",
  },
  {
    value: "RUBBER TECHNOLOGY",
    label: "RUBBER TECHNOLOGY",
  },
  {
    value: "TEXTILE PROCESSING",
    label: "TEXTILE PROCESSING",
  },
  {
    value: "TEXTILE TECHNOLOGY",
    label: "TEXTILE TECHNOLOGY",
  },
  {
    value: "OTHERS (PLEASE SPECIFY)",
    label: "OTHERS (PLEASE SPECIFY)",
  },
];

const designation = [
  {
    value: "Professor",
    label: "Professor",
  },
  {
    value: "Associate Professor",
    label: "Associate Professor",
  },
  {
    value: "Assistant Professor",
    label: "Assistant Professor",
  },
  {
    value: "Lecturer",
    label: "Lecturer",
  },
  { value: "Others", label: "Others" },
];

const membershipCategory = [
  {
    value: "LM",
    label: "LM",
  },
  {
    value: "FM",
    label: "FM",
  },
];

const initialState = {
  initials: "",
  firstName: "",
  lastName: "",
  middleName: "",
  dateOfBirth: "",
  qualifications: "",
  gender: "",
  areaOfSpecialisation: "",
  branch: "",
  designation: "",
  other_designation: "",
  institute: "",
  city: "",
  state: "",
  pincode: "",
  mailingAddress: "",
  pincodeOfMailingAddress: "",
  email: "",
  alternateEmail: "",
  mobileNo: "",
  landlineTelephone: "",
  panNo: "",
  aadharNo: "",
  district: null,
};

export default function Checkout() {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialState);
  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);
  const [signature, setSignature] = useState(null);
  const [certificateOfRecommendation, setCertificateOfRecommendation] =
    useState(null);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [sections, setSections] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [institutes, setInstitutes] = useState([]);

  const getStates = async () => {
    try {
      const data = await api.getAllStates();
      if (data?.status === 200) {
        setStatesData(data?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSections = async () => {
    try {
      const data = await api.getAllSections();
      if (data?.status === 200) {
        setSections(data?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDistricts = async () => {
    try {
      const data = await api.getAllDistricts();
      if (data?.status === 200) {
        setDistrictsData(data?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllInstitutes = async () => {
    try {
      const data = await getAllInstituteName();
      setInstitutes(data?.data?.institutes);
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const updatedApplication = {
        ...formData,
        dateOfBirth: convertUTCDateToLocalDate(formData.dateOfBirth),
      };
      const newFormData = new FormData();
      for (const field in updatedApplication) {
        if (field === "areaOfSpecialisation") continue;
        if (field === "other_designation") continue;
        if (field === "branch") {
          if (formData.branch === "OTHERS (PLEASE SPECIFY)") {
            newFormData.append(field, formData.areaOfSpecialisation);
          } else {
            newFormData.append(field, formData.branch);
          }
          continue;
        }
        if (field === "designation") {
          if (formData.designation === "Others") {
            newFormData.append(field, formData.other_designation);
          } else {
            newFormData.append(field, formData.designation);
          }
          continue;
        }
        if (field === "institute") {
          if (formData.institute === "other_institute") {
            newFormData.append("institute", formData.otherInstitute);
          } else {
            newFormData.append("institute", formData.institute);
          }
          continue;
        }
        if (field === "otherInstitute") {
          continue;
        }
        if (field === "alternateEmail" && formData.alternateEmail === "")
          continue;
        if (field === "landlineTelephone" && formData.landlineTelephone === "")
          continue;
        if (field === "chapter_code" && formData.chapter_code === "") continue;
        if (field === "panNo" && formData.panNo === "") continue;
        if (field === "aadharNo" && formData.aadharNo === "") continue;
        if (field === "district" && formData.aadharNo === "") continue;
        newFormData.append(field, formData[field]);
      }
      console.log(newFormData);
      if (file) {
        newFormData.append("highestQualificationCertificate", file);
      }
      if (image) {
        newFormData.append("image", image);
      }
      if (signature) {
        newFormData.append("signature", signature);
      }
      if (certificateOfRecommendation) {
        newFormData.append(
          "certificateOfRecommendation",
          certificateOfRecommendation
        );
      }
      const result = await api.adminAddLifeTimeMember(newFormData);
      console.log(result);
      if (result.status === 200) {
        toast(result?.data?.message || "Member Added", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormData({ institute: formData.institute });
        setFile(null);
        setImage(null);
        setSignature(null);
        setCertificateOfRecommendation(null);
      } else {
        toast("Something Went Wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error.response);
      toast("Something Went Wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleHighestQualificationUpload = (e) => {
    setFile(e.target.files[0]);
  };
  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };
  const handleSignatureUpload = (e) => {
    setSignature(e.target.files[0]);
  };
  const handleCertificateOfRecommendationUpload = (e) => {
    setCertificateOfRecommendation(e.target.files[0]);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isFormValid = true;
    // validations
    const newFormError = validation(formData, formError);
    // checks if any of the fields are not validated
    // and changes isFormValid accordingly
    for (const key in newFormError) {
      if (newFormError[key]) {
        isFormValid = false;
      }
    }
    console.log(newFormError);
    setFormError(newFormError);
    if (!isFormValid) return;
    await submitForm();
  };

  useEffect(() => {
    getStates();
    getDistricts();
    getSections();
    fetchAllInstitutes();
  }, []);

  return (
    <Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">Add Lifetime Member</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item className={classes.gridMargin}>
        {loading ? (
          <Paper className={classes.loading} align="center">
            <CircularProgress />
          </Paper>
        ) : (
          <Paper className={classes.paper} elevation={3}>
            <InputForm
              formData={formData}
              formError={formError}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              membershipCategory={membershipCategory}
              branch={branch}
              genderCategory={genderCategory}
              highestQualificationCertificate={setFile}
              file={file}
              designation={designation}
              handleHighestQualificationUpload={
                handleHighestQualificationUpload
              }
              handleImageUpload={handleImageUpload}
              handleCertificateOfRecommendationUpload={
                handleCertificateOfRecommendationUpload
              }
              handleSignatureUpload={handleSignatureUpload}
              initials={initials}
              image={image}
              signature={signature}
              certificateOfRecommendation={certificateOfRecommendation}
              stateData={stateData}
              districtsData={districtsData}
              setFormData={setFormData}
              setTermsAndConditions={setTermsAndConditions}
              termsAndConditions={termsAndConditions}
              sections={sections}
              institutes={institutes}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
