import React,{useState} from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    TextField
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';

const Row = ({idx,value,handleDistrictQueryChange,handleUpdateState, handleDeletState})=>{
    const [open, setOpen] = useState(false);
    const [stateData,setStateData] = useState(value); 
    const classes = useStyles();

    const handleChange = (e)=>{
        setStateData({...stateData, [e.target.name]:e.target.value});
    }
    const handleUpdate = async ()=>{
        
        setOpen(false);
        await handleUpdateState({name : stateData?.name, id:value._id});
    }
    const handleDelete = async ()=>{
        setOpen(false);
        await handleDeletState({id : value._id});
    }
    return (
        <>
        {
            !open ? 
            (
                <TableRow>
                    <TableCell>
                        {value.name}
                    </TableCell>
                    <TableCell>
                        <Button variant="outlined" size="small" onClick={()=>{setOpen(true)}}>
                            Edit
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            size="small"
                            onClick={()=>{handleDistrictQueryChange(value._id)}}
                        >
                            View Districts
                        </Button>
                    </TableCell>
                </TableRow>
            ) : 
            (
                <TableRow>
                    <TableCell colSpan={2}>      
                        <TextField 
                            className={classes.tableTextField}
                            variant="outlined" 
                            label="Name of State"
                            size="small"
                            name="name"
                            value={stateData.name}
                            onChange={handleChange}
                            
                        >
                        </TextField>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="outlined" 
                            size="small" 
                            onClick={handleUpdate}
                            color="primary"
                        > 
                            Update
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="contained" 
                            size="small"
                            onClick={handleDelete}
                            color="secondary"
                        > 
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }
        </>
    );
}

const States = ({states,handleDistrictQueryChange,handleUpdateState,handleDeletState}) => {
    return (
        <Paper elevation={5}>
        <TableContainer>
            <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>
                            Name of States
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {states.map((value,idx)=>{
                    return (
                        <Row 
                            idx={idx} 
                            value={value} 
                            handleDistrictQueryChange={handleDistrictQueryChange} 
                            handleDeletState={handleDeletState}
                            handleUpdateState={handleUpdateState}
                        />
                    );
                })}
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    )
}

export default States;
