import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
    paper: {
        borderRadius : theme.spacing(4),
        padding : theme.spacing(5),
        marginTop : theme.spacing(2)
    },
    gridMargin : {
        marginBottom : theme.spacing(10)
    },
    submitDiv : {
        marginTop : theme.spacing(3)
    },
    uploadButton: {
        display: 'none',
    },
    buttonIcon : {
    marginRight: theme.spacing(1),
    },
    loading : {
        padding : theme.spacing(5)
    },
    searchMargin : {
        marginTop : theme.spacing(3)
    },
    table : {
        minWidth : 500
    }
}));