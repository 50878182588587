import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';

import {
    Grid,
    Paper,
    TextField,
    CssBaseline,
    Typography,
    Divider,
    Button,
    Link
} from '@material-ui/core';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import useStyles from './styles';
import { BASE_URL } from '../../constants/actionTypes';


const CompletePayment = (props) => {
    const classes = useStyles();
    const {order_id} = useParams();
    return (
        <>
            <FormHeader />
            <CssBaseline />
            <main className={classes.paper}>    
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<Typography align="center" variant="h4" component="h4">
									Transaction Failed
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} align="center">
                                <form method="POST" name="customerData" action={`${BASE_URL}/api/payment/ccavRequestHandler`}>
                                <TextField
                                    value={order_id}
                                    name='order_id'
                                    style={{display : 'none'}}
                                >
                                </TextField>
                                <Button variant="outlined" type="submit">Click here to complete the payment</Button>
                                </form>
							</Grid>
						</Grid>
            </main>
            <KJSIEIT />
        </>
    )
}

export default CompletePayment;
