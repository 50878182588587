const validation = (formData,formError)=>{
    const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const panNoRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const newFormError = {...formError}
    newFormError.initials = !formData.initials;
    newFormError.firstName = (!formData.firstName || formData.firstName === "" ||  !nameRegex.test(formData.firstName));
    // newFormError.lastName = (!formData.lastName || formData.lastName === "" || !nameRegex.test(formData.lastName) );
    // newFormError.middleName = (!formData.middleName || formData.middleName ==="" || !nameRegex.test(formData.middleName));
    newFormError.email = (!formData.email || !emailRegex.test(formData.email));
    newFormError.dateOfBirth = (!formData.dateOfBirth);
    newFormError.gender = (!formData.gender);
    newFormError.mobileNo = !mobileNoRegex.test(formData.mobileNo);
    
    // newFormError.panNo = !panNoRegex.test(formData.panNo) || formData.panNo==='';
    // newFormError.aadharNo = (
    //     !formData.aadharNo || 
    //     formData.aadharNo === '' || 
    //     formData.aadharNo.length > 12 || 
    //     formData.aadharNo.length < 12 || 
    //     isNaN(formData.aadharNo)
    // );
    newFormError.qualifications = (!formData.qualifications || formData.qualifications === '');
    if(formData.branch==='OTHERS (PLEASE SPECIFY)'){
        newFormError.areaOfSpecialisation = (!formData.areaOfSpecialisation || formData.areaOfSpecialisation === '');
    } else {
        newFormError.branch = (!formData.branch || formData.branch === '');
        newFormError.areaOfSpecialisation = false
    }
    if(formData.designation==='Others'){
        newFormError.other_designation = (!formData.other_designation || formData.other_designation === '');
    } else {
        newFormError.designation = (!formData.designation || formData.designation === '');
        newFormError.other_designation = false
    }
    newFormError.institute = (!formData.institute || formData.institute === '');
    newFormError.city = (!formData.city || formData.city === '');
    newFormError.state = (!formData.state || formData.state === '');
    newFormError.section = (!formData.section || formData.section === '');
    // newFormError.district = (!formData.district || formData.district === '');
    newFormError.pincode = (!formData.pincode || formData.pincode === '');
    // newFormError.chapter_code = (!formData.chapter_code || formData.chapter_code === '');
    
    newFormError.mailingAddress = (!formData.mailingAddress || formData.mailingAddress === '');
    newFormError.pincodeOfMailingAddress = (!formData.pincodeOfMailingAddress || formData.pincodeOfMailingAddress === '');

    // newFormError.recommendersFirstName = (!formData.recommendersFirstName || formData.recommendersFirstName === '');
    // newFormError.recommendersLastName = (!formData.recommendersLastName || formData.recommendersLastName==='');
    // newFormError.recommendersEmail = (!formData.recommendersEmail || formData.recommendersEmail==='');
    // newFormError.recommendersMembershipNo = (!formData.recommendersMembershipNo || formData.recommendersMembershipNo==='');

    return newFormError;
}

export default validation;