import React,{useState} from 'react';
import { 
    Grid,
    Divider,
    Typography,
    TextField, 
    Button,
    Chip,
    Link,
    MenuItem 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
    updateInstitutionalAndConvener
} from '../../api/index';
import BASE_URL from '../../api/constants';
import { toast } from 'react-toastify';


const InstituteAndConvenerForm = ({institute,sections}) => {
    const [institutionAndConvener, setInstituteAndConvener] = useState(institute);
    const handleChange = (e)=>{
        setInstituteAndConvener({...institutionAndConvener, [e.target.name]:e.target.value});
    }
    const UpdateData = async ()=>{
        try{
            const data = await updateInstitutionalAndConvener(institutionAndConvener);
            if(data?.status===200){
                toast('Institution and Conveners Data Updated');
            }
        }catch(error){
            console.log(error);
        }
    }
    return (
        <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Typography  variant="h5">
                            Institution and Convener Details {institute.is_new_chapter && (<Chip style={{padding:'5px', margin:'20px'}} label="New Chapter" color="secondary"/>)}
                            
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "name_of_iste_chapter"
                                label= "Name of Institution"
                                value={institutionAndConvener.name_of_iste_chapter}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "institute_membership_id"
                                label= "Institution Membership Id"
                                value={institutionAndConvener.institute_membership_id}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "student_chapter_code"
                                label= "Student Chapter Code"
                                value={institutionAndConvener.student_chapter_code}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "address_of_institution"
                                label= "Address of Institution"
                                value={institutionAndConvener.address_of_institution}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "pincode"
                                label= "Pincode of Institution"
                                value={institutionAndConvener.pincode}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "first_name"
                                label= "First Name of Convener"
                                value={institutionAndConvener.first_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "last_name"
                                label= "Last Name of Convener"
                                value={institutionAndConvener.last_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "membership_id"
                                label= "Membership ID of Convener"
                                value={institutionAndConvener.membership_id}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "email"
                                label= "Email of Convener"
                                value={institutionAndConvener.email}
                                onChange={handleChange}
                                type="email"
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "mobile_no"
                                label= "Phone No of Convener"
                                value={institutionAndConvener.mobile_no}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "email_of_institution"
                                label= "Email of Institution"
                                value={institutionAndConvener.email_of_institution}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "section_code"
                                label= "Section Code"
                                value={institutionAndConvener.section_code}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                select
                            >
                                {
                                    sections.map((value)=>(
                                        <MenuItem key={value._id} value={value.section_code}>
                                            {value.section_name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                    </Grid>
                    
                    
                    <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={UpdateData}
                            >
                                <SaveIcon /> {' Update'} 
                            </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12} align="left">
                            <Typography>
                                Supporting Documents
                            </Typography>
                            <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <Button
                        component={Link}
                        target="_blank"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        href={`${BASE_URL}/files/studentmembership/signature/${institutionAndConvener.signature}`}
                    >
                        Signature
                    </Button>
                    </Grid>
                </Grid>
        </div>
    )
}

export default InstituteAndConvenerForm
