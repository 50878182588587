import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Button} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { getEmployees } from '../../api/index';


const columns = [
  { id: 'name', label: 'Full Name',  },
  { id: 'email', label: 'Email Id', },
  { id: 'role', label: 'Role', },
  { id: 'employeeId', label: 'Employee ID' },  
  { id: 'update', label: 'Update' },   
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
    padding : theme.spacing(5),
    borderRadius : theme.spacing(4),
    marginBottom : theme.spacing(6)
  },
  container: {
    maxHeight: 440,
  },
  paper : {
    padding : theme.spacing(3),
        borderRadius : theme.spacing(4),
  }
}));

export default function StickyHeadTable() {
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const getData = async ()=>{
    try{
        const data = await getEmployees();
        console.log(data);
        if(data?.status===200){
          setEmployees(data?.data?.result);
        }
    }catch(error){
      toast.error('Server Error!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  useEffect(()=>{
    getData();
  },[]);

  return (
    <Paper className={classes.root} elevation={5}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((row) => {
              return (
                <TableRow>
                    <TableCell >
                        {`${row.firstName} ${row.lastName}`}
                    </TableCell>
                    <TableCell >
                        {row.email}
                    </TableCell>
                    <TableCell >
                        {row.role}
                    </TableCell>
                    <TableCell >
                        {row.employee_id}
                    </TableCell>
                    <TableCell >
                        <Button variant="contained" color="primary" size="small" component={Link} to={`/dashboard/admin-control/update-employee/${row._id}`} >Update</Button>
                    </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
