import APIBASE_URL from "../api/constants";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const UPDATE = "UPDATE";

export const GETREPORTS = "GETREPORTS";
export const GETSUBORDINATEREPORTS = "GETSUBORDINATEREPORTS";
export const GETALLREPORTS = "GETALLREPORTS";
export const CHECKIN = "CHECKIN";
export const CHECKOUT = "CHECKOUT";
export const GETEMPLOYEES = "GETEMPLOYEES";
export const ADDEMPLOYEE = "ADDEMPLOYEE"
export const REMOVEEMPLOYEE = "REMOVEEMPLOYEE"
export const UPDATEEMPLOYEE = "UPDATEEMPLOYEE";

export const BASE_URL = APIBASE_URL;