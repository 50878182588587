import React from 'react';
import { Paper,Grid,TextField,Button } from '@material-ui/core';

import useStyles from './styles';

const LifeTimeMembershipAdminForm = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Paper elevation={5} className={classes.paper}>
                    <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <TextField
                                required
                                name= "note"
                                label= "Remark"
                                fullWidth
                                multiline
                                onChange={props.handleAdminFormDataChange}
                                rows={3}
                                variant="outlined"
                            />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} xl={6} align="center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    size="large"
                                    onClick={props.handleApplicationRejection}
                                    className={classes.button}
                                >
                                    Reject Application
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} xl={6} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    onClick={props.handleApplicationApproval}
                                    className={classes.button}
                                >
                                    Accept Application
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default LifeTimeMembershipAdminForm;
