const validation = (formData,formError)=>{
    const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const newFormError = {...formError};
    const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    newFormError.name_of_institution = (!formData.name_of_institution || formData.name_of_institution === "");
    newFormError.address_for_correspondence = (!formData.address_for_correspondence || formData.address_for_correspondence === '');
    newFormError.pincode_for_correspondence = (!formData.pincode_for_correspondence || formData.pincode_for_correspondence === '');
    newFormError.telephone_number_for_correspondence = !mobileNoRegex.test(formData.telephone_number_for_correspondence);
    newFormError.phone_number_for_correspondence = (!mobileNoRegex.test(formData.phone_number_for_correspondence));
    newFormError.institute_email = (!formData.institute_email || !emailRegex.test(formData.institute_email));
    newFormError.alternate_email = (!formData.alternate_email || !emailRegex.test(formData.alternate_email));
    newFormError.nature_of_principal_activity = (!formData.nature_of_principal_activity || formData.nature_of_principal_activity==='');
    newFormError.category_of_institution = (!formData.category_of_institution)
    newFormError.first_name_of_the_officer_incharge = (!formData.first_name_of_the_officer_incharge || formData.first_name_of_the_officer_incharge === "" ||  !nameRegex.test(formData.first_name_of_the_officer_incharge));
    newFormError.last_name_of_the_officer_incharge = (!formData.last_name_of_the_officer_incharge || formData.last_name_of_the_officer_incharge === "" || !nameRegex.test(formData.last_name_of_the_officer_incharge) );
    newFormError.email_of_officer_incharge = (!formData.email_of_officer_incharge || !emailRegex.test(formData.email_of_officer_incharge));
    newFormError.address_name_of_the_officer_incharge = (!formData.address_name_of_the_officer_incharge || formData.address_name_of_the_officer_incharge === '');
    // newFormError.designation = (!formData.designation || formData.designation === '') ;
    newFormError.state = (!formData.state || formData.state === '');
    newFormError.district = (!formData.district || formData.district === '');
    newFormError.section_code = (!formData.section_code || formData.section_code === '');
    newFormError.type_of_institution = (!formData.type_of_institution || formData.type_of_institution === '');
    newFormError.city = (!formData.city || formData.city === '');
    newFormError.phone_number_of_officer_incharge = !mobileNoRegex.test(formData.phone_number_of_officer_incharge);



    if( 
        (formData.category_of_institution === 'POLYTECHNICS' && formData.type_of_institution !== 'Diploma') ||
        (formData.category_of_institution !== 'POLYTECHNICS' && formData.type_of_institution === 'Diploma') ||
        (formData.category_of_institution !== 'STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)' && formData.type_of_institution==='MBA-MCA')
    ){
        newFormError.category_of_institution = true;
        newFormError.type_of_institution = true;
    }

    // newFormError.dateOfSubmission = (!formData.dateOfSubmission);
    return newFormError;
}

export default validation;