import { AUTH, LOGOUT, UPDATE } from "../constants/actionTypes";
import { signIn, signUp, updateProfile } from "../api/user.js";
import { toast } from "react-toastify";

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await signIn(formData);
    if (data?.error) {
      console.log(data.error);
      return;
    }
    dispatch({ type: AUTH, data: data });

    router.push("/dashboard");
  } catch (error) {
    toast("Invalid username or password");
  }
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await signUp(formData);
    if (data?.error) {
      console.log(data.error);
    }
    dispatch({ type: AUTH, data });

    router.push("/");
  } catch (error) {
    console.log(error);
  }
};

export const logout = (router) => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT });
    router.push("/");
  } catch (error) {
    console.log(error);
  }
};

export const updateprofile = (formdata) => async (dispatch) => {
  try {
    const { data } = updateProfile(formdata);
    if (data?.error) {
      console.log(data.error);
      return;
    }
    dispatch({ type: UPDATE, data: formdata.ph_no });
    toast("Credentials Updated");
  } catch (error) {
    toast("Something went wrong");
  }
};
