import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import json2mq from "json2mq";
import {
  TextField,
  Button,
  Grid,
  Box,
  makeStyles,
  Paper,
  Container,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import logo from "../../assets/images/small-logo.svg";
import "../../sass/main.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { signin } from "../../actions/auth";
import { useDispatch } from "react-redux";
import KJSIEIT from "../../components/KJSIEIT/KJSIEIT";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailError, setEmailError] = useState(false);
  document.title = "ISTE-Membership";
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const [user, changeUser] = useState({
    email: "",
    password: "",
  });

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const handleInput = (obj) => {
    const { name, value } = obj.target;
    if (name === "email") {
      if (emailError) {
        setEmailError(!emailError);
      }
    }
    changeUser((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const Submit = (obj) => {
    obj.preventDefault();
    if (user.email === "") {
      emailRef.current.focus();
    } else {
      if (!emailRegex.test(user.email)) {
        setEmailError(true);
        emailRef.current.focus();
      } else {
        if (user.password === "") {
          passRef.current.focus();
        } else {
          dispatch(signin(user, history));
        }
      }
    }
  };
  const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      backgroundColor: "whitesmoke",
      flexGrow: 1,
      height: "90vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 450,
      overflowY: "hidden",
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      // padding: theme.spacing(6),
    },
  }));
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const matches = useMediaQuery(

    json2mq({
      maxWidth: 1025,
    })
  );
  const textFieldSize = matches ? "95%" : "80%";
  const divSize = matches ? "100%" : "80%";

    useEffect(()=>{
      if (localStorage.getItem('profile')) {
        history.push('/dashboard');
      }
    },[]);

  return (
    <>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <ToastContainer
            position="top-center"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Grid container spacing={0} direction="row-reverse">
            <Grid item xs={12} md={8} lg={8}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <div className="container-login-blank">
                  <div className="container-login-blank-image">
                    <img src={logo} alt="" />
                  </div>
                  <div className="container-login-blank-header">
                    
                    <h1>Indian Society for Technical Education</h1>
                    <h1>ISTE Membership Portal</h1>
                  </div>
                  <div className="container-login-blank-motto">
                    
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper} elevation={6}>
                <div className="container-login-creds-image">
                  <img src={logo} alt="" />
                </div>
                <div className="container-login-creds-header">
                  <h1>Login</h1>
                </div>
                <form className="container-login-creds-form">
                  <div className="container-login-creds-form-input">
                    <div style={{ width: divSize }}></div>
                    <TextField
                      error={emailError}
                      helperText={emailError ? "Enter a valid email" : ""}
                      variant="outlined"
                      inputRef={emailRef}
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={handleInput}
                      style={{ width: textFieldSize }}
                    />
                  </div>
                  <div className="container-login-creds-form-input">
                    <div style={{ width: divSize }}></div>
                    <TextField
                      inputRef={passRef}
                      variant="outlined"
                      onChange={handleInput}
                      name="password"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      style={{ width: textFieldSize }}
                    />
                    <Link to="/forgotpass">Forgot Password?</Link>
                  </div>
                  <div className="container-login-creds-form-submit">
                    <Button
                      type="submit"
                      onClick={Submit}
                      variant="contained"
                      color="primary"
                      style={{ width: "80%" }}
                    >
                      Log in
                    </Button>
                  </div>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      <footer>
        <KJSIEIT />
      </footer>
    </>
  );
};

export default Login;
