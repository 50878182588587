import React,{useState,useEffect} from "react";
import moment from "moment";
import axios from "axios";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Button,
  Link,
  Box
} from "@material-ui/core";
import useStyles from "./styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BackupIcon from '@material-ui/icons/Backup';
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useHistory } from "react-router";
import {toast} from 'react-toastify';
import BASE_URL from '../../api/constants';
import {
  deleteLifeTimeMember,
  updateLifeTimeMember,
  getAllSections,getAllStates,
  getAllDistricts,
  updateLifeTimeMemberImage
} from '../../api/index';
import convertUTCDateToLocalDate from "../../utils/dateConverter";

function DeleteAlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async ()=>{
    await props.handleDelete();
    history.push('/dashboard/life-time-members/1');
  }
  
  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Delete Member Application
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Lifetime member application?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted you won't be able to restore it again.
            Please Click on cancel or anywhere outside the dialog box if you don't want to delete the application
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const ViewLifeTimeMembershipComponent = ({  application, handleApplicationDataChange ,setApplication }) => {
 
  const classes = useStyles();
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [sections,setSections] = useState([]);
  const [image, setImage] = useState(null);
  
  const handleDelete = async ()=>{
    try{
      await deleteLifeTimeMember(application);
    }catch(error){
      console.log(error);
    }
  }
  const downloadDetails = async (start,end)=>{
    try{
      axios({
        url: `${BASE_URL}/api/life-time-membership/download-member-data`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
        params : {id : application._id},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LM-Details-${application.membership_no || ''}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  } 
  const handleUpdate = async ()=>{
    const updatedApplication  = {...application, date_of_birth :convertUTCDateToLocalDate(application.date_of_birth) };
    try{
      
      await updateLifeTimeMember(updatedApplication);
      if(image){
        let formData = new FormData();
        formData.append('id',application._id);
        formData.append('image',image);
        await updateLifeTimeMemberImage(formData);
      }
      toast.success('Application Updated', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    }catch(error){
      console.log(error);
    }
  }
  const getStates = async ()=>{
    try{
        const data = await getAllStates();
        if(data?.status === 200){
          setStatesData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
const getDistricts = async ()=>{
    try{
        const data = await getAllDistricts();
        if(data?.status === 200){
          setDistrictsData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
}
  const getSections = async ()=>{
    try{
        const data = await getAllSections();
        if(data?.status === 200){
          setSections(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
  }
  const handleImageUpload = e => {
    if(e.target.files[0] && e.target.files[0].size > 250000){
      toast('The filesize for Photograph should be less than 250KB', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        return;
    }
    setImage(e.target.files[0]);
  }
  useEffect(()=>{
    getSections();
    getStates();
    getDistricts();
  },[])
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.membership_no && (
            <Typography component="h5" variant="h5">
              <b>Membership No : {application?.membership_no}</b>
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography variant="body1">Personal Details</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
          <img
            src={`${BASE_URL}/files/lifetimemembership/image/${application.image}`}
            width="20%"
            alt="Profile Image"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={2} xl={2}>
          <TextField
            required
            name="initials"
            label="Initials"
            fullWidth
            variant="outlined"
            value={application.initials}
            onChange={handleApplicationDataChange}
            color="black"
          >
            Dr.
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            name="first_name"
            label="First Name"
            fullWidth
            variant="outlined"
            onChange={handleApplicationDataChange}
            value={application.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="middle_name"
            label="Middle Name"
            fullWidth
            variant="outlined"
            onChange={handleApplicationDataChange}
            value={application.middle_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            // required
            name="last_name"
            label="Last Name"
            fullWidth
            variant="outlined"
            onChange={handleApplicationDataChange}
            value={application.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Birth"
                  format="dd/MM/yyyy"
                  name="date_of_birth"
                  value={application.date_of_birth}
                  InputAdornmentProps={{ position: "start" }}
                  onChange = {(date)=>{
                    setApplication({...application, date_of_birth : date});
                  }}
                />
              </Grid>
        <Grid item xs={12} sm={12} lg={2} xl={2}>
          <TextField
            required
            name="gender"
            label="Gender"
            fullWidth
            variant="outlined"
            onChange={handleApplicationDataChange}
            value={application.gender}
            select
          >
            {
              [
                {value : 'M', name : 'MALE'},
                {value : 'F', name : 'FEMALE'},
                {value : 'O', name : 'OTHERS'},
             ].map((value,idx)=>(
               <MenuItem value={value.value} key ={value.value}>
                {value.name}
               </MenuItem>
             ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            name="email"
            label="Email"
            type="email"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            name="mobile_no"
            label="Mobile Phone Number"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.mobile_no}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            name="landline_tel"
            label="Landline Telephone Number"
            type="number"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.landline_tel}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            name="pan_no"
            label="Pandcard Number"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.pan_no}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            name="aadhar_no"
            label="Aadhar Number"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.aadhar_no}
            variant="outlined"
          ></TextField>
        </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
                <input
                  style={{display:'none'}}
                  id="contained-button-file1"
                  name="imageUpload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file1">
                  <Box display="flex">
                    <Button variant="outlined" component="span">
                      <BackupIcon className={classes.buttonIcon} /> Upload Photograph *
                    </Button>
                    <Typography>&nbsp;&nbsp;{image?.name} </Typography>
                  </Box>
                </label>
              </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Professional Details
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="institute"
            label="Institute Name"
            value={application.institute}
            onChange={handleApplicationDataChange}
            rows="3"
            fullWidth
            multiline
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="qualifications"
            label="Qualification(s)"
            rows="3"
            fullWidth
            multiline
            onChange={handleApplicationDataChange}
            value={application.qualifications}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="branch"
            label="Branch"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.branch}
            variant="outlined"
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="area_of_specialisation"
            label="Area of Specialisation(s)"
            rows="3"
            fullWidth
            onChange={handleApplicationDataChange}
            value={application.area_of_specialisation}
            multiline
            variant="outlined"
          />
        </Grid> */}
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="designation"
            label="Designation"
            value={application.designation}
            onChange={handleApplicationDataChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="chapter_code"
            label="Chapter Code"
            value={application.chapter_code}
            onChange={handleApplicationDataChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="city"
            label="Name of City"
            fullWidth
            value={application.city}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="state"
            label="Name of State"
            fullWidth
            value={application?.state}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            {
              stateData.map((value,idx)=>(
                <MenuItem value={value.name}>
                  {value.name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="district"
            label="Name of District"
            fullWidth
            value={application?.district}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            
            {
              districtsData.map((value,idx)=>(
                <MenuItem value={value._id}>
                  {value.name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="section_code"
            label="Section"
            fullWidth
            value={application?.section_code}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            
            {
              sections.map((value,idx)=>(
                <MenuItem value={value.section_code}>
                  {value.section_name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={2} xl={2}>
          <TextField
            required
            name="pincode"
            label="Pincode"
            type="number"
            value={application.pincode}
            fullWidth
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            name="membership_category"
            label="Membership Category"
            value={application.membership_category}
            disabled
            fullWidth
            variant="outlined"
          >
            
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography>Address</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            name="mailing_address"
            label="Mailing Address"
            rows="3"
            fullWidth
            multiline
            value={application.mailing_address}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            name="pincode"
            label="Mailing Address - Pincode"
            type="number"
            fullWidth
            value={application.pincode}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Recommenders Details
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="recommenders_first_name"
            label="First Name"
            fullWidth
            value={application.recommenders_first_name}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="recommenders_last_name"
            label="Last Name"
            fullWidth
            value={application.recommenders_last_name}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="recommenders_email"
            label="Email"
            type="email"
            fullWidth
            value={application.recommenders_email}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            name="recommenders_membership_no"
            label="Membership No. "
            fullWidth
            value={application.recommenders_membership_no}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
          <Button variant="contained"  onClick={handleUpdate} color="primary">Update Application</Button>
        </Grid>
        {
          JSON.parse(localStorage.getItem('profile'))?.result?.role==='Admin' && 
          <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
            <DeleteAlertDialog handleDelete={handleDelete}/>
          </Grid>
        }
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Supporting Documents
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Button
            component={Link}
            target="_blank"
            variant="outlined"
            color="primary"
            fullWidth
            href={`${BASE_URL}/files/lifetimemembership/highestQualificationCertificate/${application.highest_qualification_certificate}`}
          >
            View Higest Qualification Certificate
          </Button>
        </Grid>
        
        
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Button
            component={Link}
            target="_blank"
            variant="outlined"
            color="primary"
            fullWidth
            href={`${BASE_URL}/files/lifetimemembership/signature/${application.signature}`}
          >
            View Signature
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Button
            component={Link}
            target="_blank"
            variant="outlined"
            color="primary"
            fullWidth
            href={`${BASE_URL}/files/lifetimemembership/certificateOfRecommendation/${application.certificate_of_recommendation}`}
          >
            View Certificate of Recommendation
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={downloadDetails}
              >
                Download Member Details
              </Button>
            </Grid>
        {
          application.membership_no && (
            <>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <Button
                component={Link}
                target="_blank"
                variant="outlined"
                color="primary"
                fullWidth
                href={`${BASE_URL}/api/life-time-membership/download-certificate?id=${application._id}`}
              >
                Download Certificate
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <Button
                component={Link}
                target="_blank"
                variant="outlined"
                color="primary"
                fullWidth
                href={`${BASE_URL}/api/life-time-membership/download-qr-certificate?id=${application._id}`}
              >
                Download Online QR Certificate
              </Button>
            </Grid>
            
            </>
          )
        }
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Application Details
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          {
            application.last_updated_by && (
            <Typography component="body1" variant="body1">
              Last Updated By : {application?.last_updated_by?.email}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          {
            application.reviewed_by && (
            <Typography>
              Last Reviwed By : {application?.reviewed_by?.email}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.createdAt && (
            <Typography>
              Created At : {moment(application?.createdAt).format('DD-MM-YYYY hh:mm:ss')}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.updatedAt && (
            <Typography>
              Updated At : {moment(application?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}
            </Typography>
            )
          }
        </Grid>
        

        
        
      </Grid>
    </div>
  );
};

export default ViewLifeTimeMembershipComponent;
