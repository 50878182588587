import React from 'react';
import { 
  Grid,
  Typography,
  Divider,
  Button,
  Box 
} from '@material-ui/core';
import moment from 'moment';
import FormAlertDiaog from '../FormAlertDialog/FormAlertDialog';
import useStyles from './styles';

const Preview = ({ formData ,togglePreview,submitForm,stateData,districtsData,image}) => {
    const classes = useStyles();
    const states = stateData.filter((value)=>value._id===formData.state);
    const district = districtsData.filter((value)=>value._id===formData.district);
    return (
        <Box border={2} p={2}>
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Typography className={classes.formTitle} component="h5" variant="h5" align="center">
                Preview Application Details
              </Typography>
            </Grid>


            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Typography component="body1" variant="body1">
                    Personal Details
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} align="center">
              
                {
                  image && (
                    <>
                      
                    <img src={URL.createObjectURL(image)} width="20%" alt="Profile Image" />
                      <Typography  variant="body1">
                        {image?.name}
                      </Typography>
                    </>
                  )
                }
                  
              </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Full Name</b> : { formData.initials} {formData.firstName} {formData.middleName} {formData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Date of Birth</b> : { moment(formData.dateOfBirth).format('DD-MM-YYYY') }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Gender</b> : { formData.gender }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Email</b> : { formData.email }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Alternate Email</b> : { formData.alternateEmail }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Mobile Phone No.</b> : { formData.mobileNo }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Alternate Contact No.</b> : { formData.landlineTelephone }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Pancard No.</b> : { formData.panNo }
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Aadhar No.</b> : { formData.aadharNo }
              </Typography>
            </Grid>


            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Typography component="body1" variant="body1">
                    Professional Details
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Qualification(s)</b> : { formData.qualifications} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              
              <Typography className={classes.formTitle} variant="body1">
                <b>Branch</b> :{ formData.branch==='OTHERS (PLEASE SPECIFY)' ? formData.areaOfSpecialisation : formData.branch}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Others (Please Specify)</b> : { formData.areaOfSpecialisation} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Designation</b> : { formData.designation} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Institute Name</b> : { formData.institute==='other_institute' ? formData.otherInstitute : formData.institute} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Name of City</b> : { formData.city} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Name of State</b> :  {formData.state}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Name of District</b> :  {district && district[0]?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Pincode</b> : { formData.pincode} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Membership Category</b> : { formData.membershipCategory} 
              </Typography>
            </Grid>


            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Typography component="body1" variant="body1">
                    Address 
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Mailing Address</b> : { formData.mailingAddress} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Mailing Address - Pincode</b> : { formData.pincodeOfMailingAddress} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Typography component="body1" variant="body1">
                    Recommenders Details 
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>First Name</b> : { formData.recommendersFirstName} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Last Name</b> : { formData.recommendersLastName} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Email </b> : { formData.recommendersEmail} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Typography className={classes.formTitle} variant="body1">
                <b>Membership No.</b> : { formData.recommendersMembershipNo} 
              </Typography>
            </Grid>

            
            <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick = {togglePreview}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <FormAlertDiaog title="Submit Life Membership Form?" handleSubmit={submitForm}/>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button> */}
            </Grid>
          </Grid>
          </Box>
    )
}

export default Preview;
