import React from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Breadcrumbs,
    Typography,

} from '@material-ui/core';


import AddFacultyChapter from '../../components/AddFacultyChapter/AddFacultyChapter';

const AddNewChapter = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Add Faculty Chapters</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <AddFacultyChapter />
            </Grid>
        </Grid>
    )
}

export default AddNewChapter;
