import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    collapse : {
        paddingLeft : theme.spacing(2),
        margin : theme.spacing(1)
    },
    tableTextField : {
        margin : theme.spacing(1)
    }
}));