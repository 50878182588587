import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { red } from '@material-ui/core/colors';

import useStyles from './styles';
  
const Budget = (props) => {
  const classes = useStyles();
  return (
  <>
    <Card
      sx={{ height: '100%' }}
      {...props}
      elevation={2}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {props.title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {props.count}
            </Typography>
          </Grid>
          <Grid item>
              <Avatar className={classes.iconColor}>
              {<props.icon > </props.icon>}
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
        </Box>
      </CardContent>
    </Card>
    </>
  );
};
  
export default Budget;