import React from 'react'
import axios from 'axios'
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  CircularProgress,
} from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { makeStyles } from '@material-ui/core'
import BASE_URL from '../../api/constants'
import {
  approveLifeTimeMembership,
  approveInstitutionalMembership,
  deletePayment,
  haha,
} from '../../api/index'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}))

export default function PaymentDialog(props) {
  const [open, setOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const classes = useStyles()
  // causing artifically delay to prevent user from exploiting this feature
  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay))
  }
  const sendPaymentLink = async () => {
    setShow(true)
    try {
      if (props.value.membership_type === 'lifetime membership') {
        await approveLifeTimeMembership({
          lifeTimeMemberId: props.value.membership_id,
          note: 'Resending Payment URL',
        })
      } else if (props.value.membership_type === 'institute membership') {
        await approveInstitutionalMembership({
          institutionalMemberId: props.value.membership_id,
          note: 'Resending Payment URL',
        })
      } else if (props.value.membership_type === 'student membership') {
      }
    } catch (error) {
      console.log(error)
    } finally {
      await timeout(3000)
      setShow(false)
    }
  }
  const downloadReceipt = async () => {
    try {
      let receipt_no = props.value.receipt_no.toString()
      while (receipt_no.length < 5) receipt_no = '0' + receipt_no
      if (props.value.membership_type === 'lifetime membership') {
        receipt_no = `LM-O-${receipt_no}`
      } else if (props.value.membership_type === 'institute membership') {
        receipt_no = `IM-O-${receipt_no}`
      } else if (props.value.membership_type === 'student membership') {
        receipt_no = `SM-O-${receipt_no}`
      }
      axios({
        url: `${BASE_URL}/api/payment/download-receipt`, //your url
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('profile'))?.token
          }`,
        },
        params: { id: props.value.membership_id },
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Receipt-${receipt_no}.pdf`) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <OpenInNewIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Order ID : {props.value._id}{' '}
          {props.value.order_status ? (
            <Chip label="Completed" color="primary" />
          ) : (
            <Chip label="Not Completed" color="secondary" />
          )}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            Payment Details for Order Id : {props.value._id}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="first_name"
                label="First Name"
                value={props.value?.first_name}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="last_name"
                label="Last Name"
                value={props.value?.last_name}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                value={props.value?.email}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="membership_type"
                label="Membership Type"
                value={props.value?.membership_type}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="bank_ref_no"
                label="Bank Reference No"
                value={props.value?.bank_ref_no}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="card_name"
                label="Card Name"
                value={props.value?.card_name}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="payment_mode"
                label="Payment Mode"
                value={props.value?.payment_mode}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="tracking_id"
                label="Tracking ID"
                value={props.value?.tracking_id}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="transaction_date"
                label="Transaction Date"
                value={props.value?.transaction_date}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="receipt_no"
                label="Receipt No"
                value={props.value?.receipt_no}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="amount"
                label="Amount (INR)"
                value={props.value?.amount}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                margin="dense"
                id="order_status_desc"
                label="Order Status Description"
                value={props.value?.order_status_desc}
                type="email"
                fullWidth
              />
            </Grid>
            {props.value.order_status && (
              <Grid item xs={12} sm={12} lg={12} align="center">
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  onClick={downloadReceipt}
                >
                  Download Receipt
                </Button>
              </Grid>
            )}
            {!props.value.order_status && !show && (
              <Grid item xs={12} sm={12} lg={12} align="center">
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  disabled={show}
                  onClick={sendPaymentLink}
                >
                  Send Payment Link
                </Button>
              </Grid>
            )}
            {show && (
              <Grid item xs={12} sm={12} lg={12} align="center">
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={4} align="center">
              `{' '}
              {JSON.parse(localStorage.getItem('profile'))?.result?.role ===
                'Admin' && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      await deletePayment({ id: props.value._id })
                      handleClose()
                    }}
                  >
                    Delete Payment
                  </Button>
                  {/* <Button
                    onClick={async () => {
                      await haha({
                        membership_id: props.value.membership_id,
                        membership_type: props.value?.membership_type,
                      })
                      handleClose()
                    }}
                  >
                    {props.value.membership_id}
                    Complete payment
                  </Button> */}
                </>
              )}
            </Grid>
            `
          </Grid>
        </DialogContent>
        <DialogActions className={classes.button}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
