import axios from 'axios';
import {toast} from 'react-toastify';
import BASE_URL from './constants';


const API = axios.create(
    { baseURL: `${BASE_URL}/api` },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
    }}
);

export const getPaymentData = (formData) => API.post('/payment/payment-data', formData);
export const downloadReceipt = (formData) => API.post('/payment/download-payment-receipt', formData);
export const getPaymentDetails = (formData) => API.get('/payment/payment-details', {params : formData});
