import React from 'react';
import AddLifeTimeMemberComponent from '../../components/AddLifeTimeMemberComponent/AddLifeTimeMemberComponent';

const AddLifeTimeMember = () => {
    return (
        <AddLifeTimeMemberComponent />
    )
}

export default AddLifeTimeMember;
