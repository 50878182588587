import React,{ useState } from 'react';
import { 
    Paper, 
    Grid, 
    Typography, 
    Divider, 
    TextField, 
    FormControl, 
    FormLabel, 
    FormGroup, 
    FormControlLabel,
    Checkbox,
    FormHelperText ,
    Button,
    Box,
    MenuItem
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import BackupIcon from '@material-ui/icons/Backup';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles';

const AddStudentForm = ({
    student, 
    handleStudentDataChange,
    studentDataError,
    addStudent,
}) => {
    const classes = useStyles();
    return (
        <div>
            <br/>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Typography  variant="h6">
                            Add Students
                        </Typography>
                            
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "fullname"
                                label= "Full Name"
                                error={studentDataError.fullname}
                                helperText={studentDataError.fullname && `Student Name should be less than 22 characters if it exceeds then please restrict name as per your short name  
                                For example : RAVEENDRA JAADHAV MOHAPATRA may be restricted as RAVEENDRA J. MOHAPATRA`}
                                value={student.fullname}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "duration"
                                label= "Duration of Membership"
                                error={studentDataError.duration}
                                helperText={studentDataError.duration && 'Duration Should be between 1 and 5'}
                                value={student.duration}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                                select
                            >
                                {
                                    [2,3,4,5].map((value)=>(
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "branch"
                                label= "Branch"
                                error={studentDataError.branch}
                                helperText={studentDataError.branch && 'Invalid Entry'}
                                value={student.branch}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "mobile_no"
                                label= "Mobile No. "
                                error={studentDataError.mobile_no}
                                helperText={studentDataError.mobile_no && 'Invalid Entry'}
                                value={student.mobile_no}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "email"
                                label= "Email"
                                error={studentDataError.email}
                                helperText={studentDataError.email && 'Invalid Valid Email Id'}
                                value={student.email}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                            <TextField
                                required
                                name= "gender"
                                label= "Gender"
                                error={studentDataError.gender}
                                helperText={studentDataError.gender && 'Please Select Gender'}
                                value={student.gender}
                                onChange={handleStudentDataChange}
                                fullWidth
                                variant="outlined"
                                select
                            >
                                {[
                                    {value : 'M', name : 'Male'},
                                    {value : 'F', name : 'Female'},
                                    {value : 'O', name : 'Others'},
                                ].map((option) => (
                                    <MenuItem key={option._id} value={option.value}>
                                    {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                    </Grid>
                    
                    
                    <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addStudent}
                            >
                                <AddIcon /> Add Student
                            </Button>
                    </Grid>
                </Grid>
                <br/>
        </div>
    )
}

export default AddStudentForm
