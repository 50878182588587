import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});





export default function CollapsibleTable(props) {
  const classes = useRowStyles();
  return (
    <TableContainer >
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableRow>
          <TableCell >#</TableCell>
            <TableCell >Full Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Phone No</TableCell>
            <TableCell >Membership No</TableCell>
            {/* <TableCell />
            <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.studentData.map((row,idx) => (
            <TableRow className={classes.root}>
              <TableCell component="th" scope="row">
                {idx+1}
              </TableCell>
              <TableCell >
                {row.fullname}
              </TableCell>
              <TableCell>
                {row.email}
              </TableCell>
              <TableCell>
                {row.duration}
              </TableCell>
              <TableCell>
                {row.branch}
              </TableCell>
              <TableCell>
                {row.mobile_no}
              </TableCell>
              <TableCell>
                {row.membership_no}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
