import React,{useState} from 'react';
import useStyles from './styles';
import { 
    Container,
    Avatar,
    Typography,
    TextField ,
    Button,
    Grid,
    Box,
    CssBaseline,
    Paper,
    Backdrop,
    CircularProgress 
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import { recommendersApproval, recommendersRejection } from '../../api/registrationForm';


const Recommender = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [remark, setRemark] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const { token } = useParams();
    const handleChange = (event)=>{
        setRemark(event.target.value);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const handleApproveClick = async ()=>{
        setOpen(true);
        try{
            const result = await recommendersApproval({ note : remark || "NO REMARK", recommendersToken : token });
            if(result.status === 200){
                setSubmitted(true);
                setOpen(false);
            }else{
                toast.error('Something Went Wrong', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            console.log(error);
            toast.error('Something Went Wrong', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(false);
        }
        
    };
    const handleRejectClick = async ()=>{
        setOpen(true);
        try{
            const result = await recommendersRejection({ note : remark || "NO REMARK", recommendersToken : token });
            if(result.status === 200){
                setSubmitted(true);
                setOpen(false);
            }else{
                toast.error('Something Went Wrong', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            console.log(error);
            toast.error('Something Went Wrong', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(false);
        }
        
    };
    return (
        <>
        <FormHeader />
        <Container component="main" >
             <CssBaseline />
                <div className={classes.paper}>
                    {
                        submitted ? 
                            <>
                                <Typography component="h1" variant="h3">
                                    Feedback Submitted
                                </Typography>
                                <Typography>
                                    Thanks for your feedback. 
                                </Typography>
                            </>
                        :
                        (<>
                            <Typography component="h1" variant="h5">
                            Recommenders Approval
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                className={classes.textField}
                                name="remark"
                                rows="4"
                                onChange={handleChange}
                                multiline
                                label="Remark"
                                id="remark"
                            />
                            <Box justifyContent="space-between" align="center">
                                <Button 
                                    className={classes.button} 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={handleRejectClick}
                                    
                                >
                                        Reject
                                </Button>
                                <Button 
                                    className={classes.button} 
                                    variant="contained" 
                                    color="primary"
                                    onClick={handleApproveClick}
                                >
                                    Approve
                                </Button>
                            </Box>
                            </>
                            )  

                    }
                </div>
        </Container>
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <KJSIEIT/>
        </>
    );
}

export default Recommender;
