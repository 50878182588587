import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    root: {
      width: '100%',
      borderRadius : theme.spacing(3),
      marginBottom : theme.spacing(4),
      marginTop : theme.spacing(4),
      padding : theme.spacing(2)
    },
    container: {
      minHeight: 500,
    },
    loading : {
      padding : theme.spacing(10)
    }
  }));