import React from 'react';

import {
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';


const useStyles =  makeStyles((theme)=>({
   
    
}));
export default function FormAlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    
    setOpen(false);
  };
  const handleSubmitForm = async ()=>{
    setSubmit(true);
    await props.handleSubmit();
    setSubmit(false);
    handleClose();
  } 

  return (
    <div>
      
      <Button style={{marginTop:'10px',marginBottom:'10px'}} variant="contained" color="primary" onClick={handleClickOpen}>
        Submit Application
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm if the details are correct.Once submitted you won't be able to edit it again
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmitForm} disabled={submit} color="primary" autoFocus>
            Submit Application
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
