import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import {Grid, MenuItem, Link} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';

import BASE_URL from '../../api/constants';
import { updateFacultyChapter,deleteFacultyChapter } from '../../api';
import useStyles from './styles';


function FormDialog(props) {
    const [open, setOpen] = React.useState(false);

    
    const handleClickOpen = async () => {
      setOpen(true);
    };
    const handleDelete = async ()=>{
        await props.handleDelete();
        handleClose();
    }
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Delete
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Are you sure you want to delete the faculty chapter? </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Once deleted you won't be able to retrive the data of the chapter with
              <br/>chapter code : <b>{props.value.chapter_code}</b>  
              <br/>chapter name : <b>{props.value.institute_name}</b>
            </DialogContentText>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  


export default function FacultyChapterFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [chapterData, setChapterData] = useState(props.value);
  const handleChange = (e)=>{
      setChapterData({...chapterData, [e.target.name]:e.target.value});
  }
  const handleUpdate = async ()=>{
      try{
        await updateFacultyChapter(chapterData);
      }catch(error){
        console.log(error);
      }
  }
  const handleDelete = async ()=>{
    try{
      await deleteFacultyChapter(chapterData);
      setOpen(false);
    }catch(error){
      console.log(error);
    }
  }
  const classses = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <OpenInNewIcon />
      </Button>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth="md" 
        // scroll={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Chapter Code : {props.value.chapter_code}</DialogTitle>
        
        <DialogContent dividers="papers">
          <DialogContentText>
            Please click on cancel if you dont want to update any information 
          </DialogContentText>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={12}>
                    <TextField
                        variant="outlined"
                        label="Institute Name"
                        name="institute_name"
                        value={chapterData.institute_name}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        
                        variant="outlined"
                        label="Institute Address"
                        name="institute_address"
                        value={chapterData.institute_address}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute Address 2"
                        name="institute_address2"
                        value={chapterData.institute_address2}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="State"
                        name="state"
                        value={chapterData.state}
                        onChange={handleChange}
                        fullWidth
                    >
                    </TextField>
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute Pin"
                        name="institute_pin"
                        value={chapterData.institute_pin}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute Type"
                        name="institute_type"
                        value={chapterData.institute_type}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={chapterData.email}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Chairman"
                        name="chairman"
                        value={chapterData.chairman}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Chairman Department"
                        name="chairman_department"
                        value={chapterData.chairman_department}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Secretary"
                        name="secretary"
                        value={chapterData.secretary}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Secretary Department"
                        name="secretary_department"
                        value={chapterData.secretary_department}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Chapter Code"
                        value={chapterData.chapter_code}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Chapter Number"
                        value={chapterData.code_number}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Section Code"
                        value={chapterData.section_code}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Application Date"
                        type="application_date"
                        value={moment(chapterData.application_date).format('DD-MM-YYYY')}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={12}>
                    <Button
                      component={Link}
                      target="_blank"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      href={`${BASE_URL}/api/faculty-chapters/download-qr-certificate?id=${chapterData._id}`}
                    >
                      Download Online QR Certificate
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={classses.dialogFooter}>
          <Button onClick={handleClose}  color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Update
          </Button>
          {
            JSON.parse(localStorage.getItem('profile'))?.result?.role==='Admin' && 
          <FormDialog value={props.value} handleDelete={handleDelete} />
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
