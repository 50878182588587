import axios from 'axios'
import { toast } from 'react-toastify'
import BASE_URL from './constants'

const API = axios.create(
  { baseURL: `${BASE_URL}/api` },
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
)

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem('profile')).token
    }`
  } else {
    window.location.href = '/'
  }
  return req
})

API.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (typeof error.response === 'undefined') {
      toast.error('Server Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return Promise.reject(error)
    }
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/'
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  }
)

export const signIn = (formData) => API.post('/user/signin', formData)
export const signUp = (formData) => API.post('/user/signup', formData)

export const lifeTimeRegistration = (formData) =>
  API.post('/life-time-membership/registration', formData)
export const institutionalRegistration = (formData) =>
  API.post('/institute-membership/registration', formData)
export const studentRegistration = (formData) =>
  API.post('/student-membership/registration', formData)

export const getDashboardData = () =>
  API.get('/life-time-membership/dashboard-data')

/*------------------------------Life Time Membership--------------------------------------*/
export const getLifeTimeRegistrationApplications = () =>
  API.get('/life-time-membership/applications')
export const getLifeTimeApplicant = (applicationId) =>
  API.get(`/life-time-membership/application/view`, {
    params: { applicationId: applicationId },
  })

export const approveLifeTimeMembership = (data) =>
  API.post('/life-time-membership/application/approve', data)
export const rejectLifeTimeMembership = (data) =>
  API.post('/life-time-membership/application/reject', data)

export const recommendersApproval = (data) =>
  API.post('/life-time-membership/application/recommenders-approval/', data)
export const recommendersRejection = (data) =>
  API.post('/life-time-membership/application/recommenders-rejection/', data)

export const adminAddLifeTimeMember = (formData) =>
  API.post('/life-time-membership/members/add', formData)
export const deleteLifeTimeMember = (formData) =>
  API.post('/life-time-membership/members/delete', formData)
export const updateLifeTimeMember = (formData) =>
  API.post('/life-time-membership/members/update', formData)
export const updateLifeTimeMemberImage = (formData) =>
  API.post('/life-time-membership/members/update-photo', formData)

export const getLifeTimeMembers = (data) =>
  API.get('/life-time-membership/members', { params: data })
export const getLifeTimeRejectedApplications = (data) =>
  API.get('/life-time-membership/members', { params: data })

/*-------------------------------Student Membership---------------------------------- */
export const getInstititeAndConvenerApplications = (data) =>
  API.get('/student-membership/applications', { params: data })
export const getInstituteSuggestion = (data) =>
  API.get('/student-membership/applications/suggestion', { params: data })
export const getStudentApplicationData = (data) =>
  API.get('/student-membership/applications/students', { params: data })
export const approveStudentMembershipApplication = (data) =>
  API.post('/student-membership/applications/approve', data)
export const rejectStudentMembershipApplication = (data) =>
  API.post('/student-membership/applications/reject', data)
export const getStudentMembers = (data) =>
  API.get('/student-membership/members/view', { params: data })
export const addStudentMembers = (data) =>
  API.post('/student-membership/members/add', data)
export const updateStudentMember = (data) =>
  API.post('/student-membership/members/update', data)
export const deleteStudentMember = (data) =>
  API.post('/student-membership/members/delete', data)
export const updateInstitutionalAndConvener = (data) =>
  API.post('/student-membership/institution-and-conveners/update', data)

/*-------------------------------------------Institutional Membership------------------------------------------*/
export const getInstitutionalMembers = (data) =>
  API.get('/institute-membership/members/view', { params: data })

export const getInstitutionalRegistrationApplications = (data) =>
  API.get('/institute-membership/applications', { params: data })
export const approveInstitutionalMembership = (data) =>
  API.post('/institute-membership/application/approve', data)
export const rejectInstitutionalMembership = (data) =>
  API.post('/institute-membership/application/reject', data)
export const getInstitutionalApplicant = (data) =>
  API.get('/institute-membership/application/view', { params: data })
export const getInstitutionalMembersStats = (data) =>
  API.get('/institute-membership/stats', { params: data })
export const adminAddInstitutionalMember = (formData) =>
  API.post('/institute-membership/members/add', formData)
export const updateInstitutionalMember = (formData) =>
  API.post('/institute-membership/members/update', formData)
export const deleteInstitutionalMember = (formData) =>
  API.post('/institute-membership/members/delete', formData)

/*------------------------------------------- Faculty Chapters ------------------------------------------*/
export const getAllChapters = (data) =>
  API.get('/faculty-chapters/view', { params: data })
export const addFacultyChapter = (data) =>
  API.post('/faculty-chapters/add', data)
export const updateFacultyChapter = (data) =>
  API.post('/faculty-chapters/update', data)
export const deleteFacultyChapter = (data) =>
  API.post('/faculty-chapters/delete', data)

/*------------------------------------------- Student Chapters ------------------------------------------*/
export const getAllStudentChapters = (data) =>
  API.get('/student-chapters/view', { params: data })
export const addStudentChapter = (data) =>
  API.post('/student-chapters/add', data)
export const updateStudentChapter = (data) =>
  API.post('/student-chapters/update', data)
export const deleteStudentChapter = (data) =>
  API.post('/student-chapters/delete', data)

/*------------------------------------Admin Controls ----------------------------------------*/
export const getEmployees = () => API.get('/admin/get-employees')
export const getEmployeeById = (data) =>
  API.get('/admin/get-employee', { params: data })
export const addEmployee = (data) => API.post('/admin/add-employee', data)
export const updateEmployee = (data) => API.post('/admin/update-employee', data)
export const removeEmployee = (data) => API.post('/admin/remove-employee', data)

/*------------------------------------State And Districts ----------------------------------------*/

export const getAllStates = () => API.get('/region/states/view')
export const addState = (data) => API.post('/region/states/add', data)
export const updateState = (data) => API.post('/region/states/update', data)
export const deleteState = (data) => API.post('/region/states/delete', data)

export const getAllDistricts = (data) =>
  API.get('/region/districts/view', { params: data })
export const addDistrict = (data) => API.post('/region/districts/add', data)
export const updateDistrict = (data) =>
  API.post('/region/districts/update', data)
export const deleteDistrict = (data) =>
  API.post('/region/districts/delete', data)

export const getAllSections = () => API.get('/region/sections/view')

/*------------------------------------Payment Details ----------------------------------------*/
export const updatePaymentData = (formData) =>
  API.post('/payment/update-payment-data', formData)
export const getPaymentData = (formData) =>
  API.get('/payment/get-payment-data', formData)
export const getPayments = (data) =>
  API.get('/payment/all-payments', { params: data })
export const updateOrderStatus = (data) =>
  API.post('/payment/ccavOrderStatus', data)
export const deletePayment = (data) => API.post('/payment/delete-payment', data)
export const haha = (data) => API.post('/payment/temp', data)

/*------------------------------------Reports ----------------------------------------*/

export const getLifeMembersReport = () => API.get('/reports/report/lifemember')
export const getStudentMembersReport = () => API.get('/reports/report/students')
export const getInstituteMembersReport = () =>
  API.get('/reports/report/institutes')
export const getStudentChaptersReport = () =>
  API.get('/reports/report/studentchapters')
export const getFacultyChaptersReport = () =>
  API.get('/reports/report/facultychapters')
