import React from 'react';
import { 
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Button 
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';

import BASE_URL from '../../api/constants';


export default function DownloadReceiptDialog(props){
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const download = async (start,end)=>{
        try{

            axios({
              url: `${BASE_URL}/api/payment/download-receipt-in-bulk`, //your url
              method: 'GET',
              headers : {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
              },
               params : {...props.searchParameters, start,end},
              responseType: 'blob', // important
              }).then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Receipts.pdf`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
              });
      
          }catch(error){
            console.log(error);
          }
    }
  
    return (
      <div>
        <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
         Download Receipts
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"> Download Receipts
          <br/>
          Total Count : {props.count}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
             Download Receipts. Since we have more than {props.count} entries you can download them in batches 
            </DialogContentText>
            {
              Array(Math.ceil(props.count/500)).fill(null).map((value,idx)=>(
                <Button 
                  style={{margin:'2px'}} 
                  variant="outlined"
                  onClick={()=>{download((idx)*500 , (idx+1)*500)}}
                  >
                    <GetAppIcon /> Download Entries between ({(idx)*500 + 1} and {(idx+1)*500}) 
                  </Button>
              ))
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }