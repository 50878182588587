import React,{useEffect,useState} from 'react';
import useStyles from './styles';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    Tab,
    Tabs,
    Box,
    AppBar
} from '@material-ui/core';
import {
    getStudentMembersReport,
    getLifeMembersReport,
    getInstituteMembersReport,
    getStudentChaptersReport,
    getFacultyChaptersReport
} from '../../api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {props.children}
          </Box>
        )}
      </div>
    );
}
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  




const Reports = () => {
    const classes = useStyles();
    const [studentsReports, setStudentsReports] = useState({
        studentMembersPerState : [], 
        studentMembersPerSection : []
    });
    const [studentsChaptersReports, setStudentsChaptersReports] = useState({
        studentChaptersPerState : [], 
        studentChaptersPerSection : []
    });
    const [facultyChaptersReports, setFacultyChaptersReports] = useState({
        facultyChaptersPerState : [], 
        facultyChaptersPerSection : []
    });
    const [instituteReports, setInstituteReports] = useState({
        institutesPerStates : [],
        institutesPerSecion : [],
        typeOfInstitutesPerState : []
    })
    const [lifeMemberReports, setLifeMemberReports] = useState({
        lifeMemberPerState : [],
        lifeMemberPerSection : []
    })
    const [value, setValue] = React.useState(2);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const fetchStudentReport = async ()=>{
        try{
            const data = await getStudentMembersReport();
            setStudentsReports(data?.data);
        }catch(error){
            console.log(error);
        }
    }
    const fetchLifeMemberReport = async ()=>{
        try{
            const data = await getLifeMembersReport();
            setLifeMemberReports(data?.data);
        }catch(error){
            console.log(error);
        }
    }
    const fetchInstituteMemberReport = async ()=>{
        try{
            const data = await getInstituteMembersReport();
            
            setInstituteReports(data?.data);
        }catch(error){
            console.log(error);
        }
    }
    const fetchStudentChaptersReport = async ()=>{
        try{
            const data = await getStudentChaptersReport();
            setStudentsChaptersReports(data?.data);
        }catch(error){
            console.log(error);
        }
    }
    const fetchFacultyChaptersReport = async ()=>{
        try{
            const data = await getFacultyChaptersReport();
            setFacultyChaptersReports(data?.data);
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        fetchInstituteMemberReport();
        fetchLifeMemberReport();
        fetchStudentReport();
        fetchStudentChaptersReport();
        fetchFacultyChaptersReport();
    },[])
    return (
        <div style={{margin : '20px'}}>
        <AppBar position="static" color="default" className={classes.root}>
            <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            >
            <Tab label="Life Members" {...a11yProps(0)} />
            <Tab label="Institutional Members" {...a11yProps(1)} />
            <Tab label="Student Members" {...a11yProps(2)} />
            <Tab label="Student Chapters" {...a11yProps(3)} />
            <Tab label="Faculty Chapters" {...a11yProps(4)} />
            </Tabs>
        </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Paper className={classes.root} align="center">
                    <Typography variant="h5" component="h5">
                        <b>Life Members Report</b>
                    </Typography>
                </Paper>
            </Grid>
            {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Paper className={classes.root} align="center">
                <TableContainer>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name of State
                            </TableCell>
                            <TableCell>
                            No. of Life Members
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            lifeMemberReports?.lifeMemberPerState?.map((value)=>(
                                <TableRow>
                                    <TableCell>{value.state}</TableCell>
                                    <TableCell>{value.total}</TableCell>
                                </TableRow>
                            ))
                        }
                        
                    </TableBody>
                </TableContainer>
                </Paper>
            </Grid> */}
            <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Paper className={classes.root} align="center">
                <TableContainer>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Section Code
                            </TableCell>
                            <TableCell>
                            No. of Life Members
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            lifeMemberReports?.lifeMemberPerSection?.map((value)=>(
                                <TableRow>
                                    <TableCell>{value.section_code}</TableCell>
                                    <TableCell>{value.total}</TableCell>
                                </TableRow>
                            ))
                        }
                        
                    </TableBody>
                </TableContainer>
                </Paper>
            </Grid>
        </Grid>
        </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Paper className={classes.root} align="center">
                        <Typography variant="h5" component="h5">
                            <b>Institute Members Report</b>
                        </Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name of State
                                </TableCell>
                                <TableCell>
                                No. of Institutional Members
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                instituteReports?.institutesPerStates?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value.state}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Section Code
                                </TableCell>
                                <TableCell>
                                No. of Institutional Members
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                instituteReports?.institutesPerSecion?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value.section_code}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Paper className={classes.root} align="center">
                        <Typography variant="h5" component="h5">
                            <b>Student Members Report</b>
                        </Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name of State
                                </TableCell>
                                <TableCell>
                                No. of Student Members
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentsReports?.studentMembersPerState?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Section Code
                                </TableCell>
                                <TableCell>
                                No. of Student Members
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentsReports?.studentMembersPerSection?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Paper className={classes.root} align="center">
                        <Typography variant="h5" component="h5">
                            <b>Student Chapters Report</b>
                        </Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name of State
                                </TableCell>
                                <TableCell>
                                No. of Student Chapters
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentsChaptersReports?.studentChaptersPerState?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Section Code
                                </TableCell>
                                <TableCell>
                                No. of Student Chapters
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentsChaptersReports?.studentChaptersPerSection?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Paper className={classes.root} align="center">
                        <Typography variant="h5" component="h5">
                            <b>Faculty Chapters Report</b>
                        </Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name of State
                                </TableCell>
                                <TableCell>
                                No. of Faculty Chapters
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                facultyChaptersReports?.facultyChaptersPerState?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <Paper className={classes.root} align="center">
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Section Code
                                </TableCell>
                                <TableCell>
                                No. of Faculty Chapters
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                facultyChaptersReports?.facultyChaptersPerSection?.map((value)=>(
                                    <TableRow>
                                        <TableCell>{value._id}</TableCell>
                                        <TableCell>{value.total}</TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
      </TabPanel>
            
        </div>
    )
}

export default Reports
