import React,{useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { 
  Box,
  Grid,
  TextField, 
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent 
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Link , useParams} from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import {
  MenuItem
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import useStyles from './styles';
import { 
  getLifeTimeMembers,
  getAllStates,  
} from '../../api';
import BASE_URL from '../../api/constants';

function GenerateReportDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
      <AssessmentIcon/> Generate Report 
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Generate Report
        <br/>
        Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Download Data in the .xlsx format. Since we have more than {props.count} entries you can download them in batches 
          </DialogContentText>
          {
            Array(Math.ceil(props.count/50000)).fill(null).map((value,idx)=>(
              <Button 
                style={{margin:'2px'}} 
                variant="outlined"
                onClick={()=>{props.generateReport((idx)*50000 , (idx+1)*50000)}}
                >
                  <GetAppIcon /> Download Entries between ({(idx)*50000 + 1} and {(idx+1)*50000}) 
                </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function DownloadCardsDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
      <AssessmentIcon/> Download Cards
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Download Cards
        <br/>
        Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Download Cards. Since we have more than {props.count} entries you can download them in batches 
          </DialogContentText>
          {
            Array(Math.ceil(props.count/1000)).fill(null).map((value,idx)=>(
              <Button 
                style={{margin:'2px'}} 
                variant="outlined"
                onClick={()=>{props.downloadCards((idx)*1000 , (idx+1)*1000)}}
                >
                  <GetAppIcon /> Download Entries between ({(idx)*1000 + 1} and {(idx+1)*1000}) 
                </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DownloadCertificatesDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
       Download Certificates
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Download Certificates
        <br/>
        Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Download Certificates. Since we have more than {props.count} entries you can download them in batches 
          </DialogContentText>
          {
            Array(Math.ceil(props.count/1000)).fill(null).map((value,idx)=>(
              <Button 
                style={{margin:'2px'}} 
                variant="outlined"
                onClick={()=>{props.downloadCertificates((idx)*1000 , (idx+1)*1000)}}
                >
                  <GetAppIcon /> Download Entries between ({(idx)*1000 + 1} and {(idx+1)*1000}) 
                </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DownloadAddressDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
       Download Address
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Download Address
        <br/>
        Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Download Address. Since we have more than {props.count} entries you can download them in batches 
          </DialogContentText>
          {
            Array(Math.ceil(props.count/1000)).fill(null).map((value,idx)=>(
              <Button 
                style={{margin:'2px'}} 
                variant="outlined"
                onClick={()=>{props.downloadAddress((idx)*1000 , (idx+1)*1000)}}
                >
                  <GetAppIcon /> Download Entries between ({(idx)*1000 + 1} and {(idx+1)*1000}) 
                </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function DownloadDetailDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onClick={handleClickOpen}>
       Download Details
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Download Details
        <br/>
        Total Count : {props.count}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Download Details. Since we have more than {props.count} entries you can download them in batches 
          </DialogContentText>
          {
            Array(Math.ceil(props.count/1000)).fill(null).map((value,idx)=>(
              <Button 
                style={{margin:'2px'}} 
                variant="outlined"
                onClick={()=>{props.downloadDetails((idx)*1000 , (idx+1)*1000)}}
                >
                  <GetAppIcon /> Download Entries between ({(idx)*1000 + 1} and {(idx+1)*1000}) 
                </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const columns = [
  { id: 'membershipNo',label: 'Membership No'},
  { id: 'name', label: 'Full Name'},
  { id: 'designation', label: 'Designation' },
  { id: 'state',label: 'State'},
  // { id: 'section',label: 'Section Code'},
  // { id: 'recommendersApproval', label: 'Recommenders Approval'},
  { id: 'city', label: 'City'},
  { id: 'view', label: 'View'},
];

export default function LifeTimeMembersTable()  {
  const classes = useStyles();
  let { page } = useParams();
  const [loading,setLoading] = useState(true);
  const [lifeTimeMembers ,setLifeTimeMembers] = useState([]);
  const [next,setNextPage] = useState(null);
  const [prev,setPrevPage] = useState(null);
  const [count, setCount] = useState(0);
  const [searchParameters, setSearchParameters] = useState({status : 'Member'});
  
  const [stateData, setStatesData] = useState([]);

  const handleLoading = (value)=>{
    setLoading(value);
  }
  const handleChange = (e)=>{
    setSearchParameters({...searchParameters, [e.target.name] : e.target.value});
  }
  const searchMembers = ()=>{
    getData();
  }
  const generateReport = async (start,end)=>{
    try{

      axios({
        url: `${BASE_URL}/api/life-time-membership/reports`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
         params : {...searchParameters, start,end},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LifeTime Membership Report - exported at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  const downloadCards = async (start,end)=>{
    try{

      axios({
        url: `${BASE_URL}/api/life-time-membership/download-cards`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
         params : {...searchParameters, start,end},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LM CARDS - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  const downloadCertificates = async (start,end)=>{
    try{

      axios({
        url: `${BASE_URL}/api/life-time-membership/download-certificates`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
         params : {...searchParameters, start,end},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LM Certificates - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  const downloadAddress = async (start,end)=>{
    try{

      axios({
        url: `${BASE_URL}/api/life-time-membership/download-members-post-address`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
         params : {...searchParameters, start,end},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LM Address - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.docx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }
  const downloadDetails = async (start,end)=>{
    try{

      axios({
        url: `${BASE_URL}/api/life-time-membership/download-member-data-in-bulk`, //your url
        method: 'GET',
        headers : {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
        },
         params : {...searchParameters, start,end},
        responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LM Details - ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }catch(error){
      console.log(error);
    }
  }

  const prevPage = (val)=>{
    setPrevPage(val);
  }
  const nextPage = (val)=>{
    setNextPage(val);
  }
  const getData = async ()=>{
    setLoading(true);
    const data = await getLifeTimeMembers({page : page, limit : 100, ...searchParameters});
    if(data?.data?.result){
      console.log(data?.data);
      setLifeTimeMembers(data?.data?.result?.results);
      setCount(data?.data?.result.count)
      nextPage(data?.data?.result?.next?.page)
      prevPage(data?.data?.result?.previous?.page)
      handleLoading(false);
    }
    
  }
    const getStates = async ()=>{
      try{
          const data = await getAllStates();
          if(data?.status === 200){
            setStatesData(data?.data?.result);
          }
      }catch(error){
          console.log(error);
      }
  }

  // getData();
  useEffect(()=>{
    getData();
    getStates();
  },[page]);
  // getData();

  return (
    <Paper className={classes.root}>
      { loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) :  (
          <>
        <Box elevation={2} className={classes.box}>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="h6">Total Count : {count}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "First Name"
                variant="outlined"
                name="firstName"
                onChange={handleChange}
                value={searchParameters.firstName}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "Last Name"
                variant="outlined"
                name="lastName"
                onChange={handleChange}
                value={searchParameters.lastName}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                label = "Institute Name"
                variant="outlined"
                name="institutionName"
                onChange={handleChange}
                value={searchParameters.institutionName}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "Membership No"
                variant="outlined"
                name="membershipNo"
                onChange={handleChange}
                value={searchParameters.membershipNo}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "status"
                        label= "Status"
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                        value={searchParameters.status}
                        select
                    >
                      {['In Progress', 'Approved', 'Rejected', 'Member'].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "state"
                        label= "Name of State"
                        value={searchParameters.state}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                    >
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "City"
                variant="outlined"
                name="city"
                value={searchParameters.city}
                onChange={handleChange}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "section_code"
                        label= "Section Code"
                        fullWidth
                        value={searchParameters.section_code}
                        onChange={handleChange}
                        variant="outlined"
                    >
                     
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                        required
                        name= "chapter_code"
                        label= "Chapter Code"
                        fullWidth
                        value={searchParameters.chapter_code}
                        onChange={handleChange}
                        variant="outlined"
                    >
                     
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format="dd/MM/yyyy"
                  name="startDate"
                  value={searchParameters.startDate || new Date()}
                  InputAdornmentProps={{ position: "start" }}
                  onChange = {(date)=>{
                    setSearchParameters({...searchParameters, startDate : date});
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="To"
                    name="endDate"
                    format="dd/MM/yyyy"
                    value={searchParameters.endDate || new Date()}
                    InputAdornmentProps={{ position: "start" }}
                    onChange = {(date)=>{
                      setSearchParameters({...searchParameters, endDate : date});
                    }}
                  />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "Start Membership No."
                variant="outlined"
                name="startMembershipNo"
                onChange={handleChange}
                value={searchParameters.startMembershipNo}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                label = "End Membership No."
                variant="outlined"
                name="endMembershipNo"
                onChange={handleChange}
                value={searchParameters.endMembershipNo}
                fullWidth
                >
                </TextField>
              </Grid>
              
              {/*Buttons*/}
              <Grid item xs={12} sm={12} lg={12} align="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={searchMembers}
                  
                  
                >
                  <SearchIcon/> Search
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} lg={3}>
                <GenerateReportDialog
                 count={count}
                 searchParameters={searchParameters}
                 generateReport={generateReport}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3}>
                <DownloadCardsDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadCards={downloadCards}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <DownloadCertificatesDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadCertificates={downloadCertificates}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <DownloadAddressDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadAddress={downloadAddress}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <DownloadDetailDialog
                  count={count}
                  searchParameters={searchParameters}
                  downloadDetails={downloadDetails}
                />
              </Grid>
              
          </Grid>
        </Box>
          
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lifeTimeMembers.map((row) => {
                let last_name = '';

                if(row.last_name){
                  last_name = row?.last_name
                }
                return (
                  <TableRow key={row._id} hover role="checkbox" tabIndex={-1}>
                      <TableCell>
                            {row.membership_no}
                      </TableCell>
                      <TableCell >
                            {`${row.first_name} ${last_name}`}
                      </TableCell>
                      <TableCell>
                            {row.designation}
                      </TableCell>
                      <TableCell>
                            {row.district?.state?.name || row.state}
                      </TableCell>
                      {/* <TableCell>
                            {row.section_code}
                      </TableCell> */}
                      <TableCell>
                            {row.city}
                      </TableCell>
                      
                      
                      <TableCell>
                          <Button 
                          size="small"
                          variant="outlined" 
                          color="primary" 
                          component={Link} 
                          onClick={() => window.open(`/dashboard/life-time-membership-applications/${row._id}`,"_blank")}
                          >
                            <OpenInNewIcon />
                          </Button>
                      </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box align="right">
          <Button  to={`${prev}`} component={Link} disabled={!prev}><NavigateBeforeIcon /></Button>
          <Button  to={`${next}`} component={Link} disabled={!next}><NavigateNextIcon /></Button>
        </Box>
        </>
        )}
    </Paper>
  );
}
