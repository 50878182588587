import React,{useEffect, useState} from 'react';
import { 
    Grid,
    Paper,
    Breadcrumbs,
    Typography,
    Button,
    CircularProgress,
    LinearProgress, 
    TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';


import AddStudentForm from './AddStudentForm';
import TablePreview from './TablePreview';
import useStyles from './styles';
import { studentValidation } from './validation';
import { addStudentMembers,getAllSections,getAllStates } from '../../api';
import { toast } from 'react-toastify';

const AddStudent = () => {
    const classes = useStyles();
    const [studentData, setStudentData] = useState({});
    const [studentDataError, setStudentDataError] = useState({});
    const [studentTable, setStudentTable] = useState([]);
    const [chapterCode, setChapterCode] = useState("");
    const [loading, setLoading] = useState(false);
    const handleStudentDataChange = (e)=>{
        setStudentData({...studentData, [e.target.name]:e.target.value});
    }
    const handleChapterCodeChange = (e)=>{
        setChapterCode(e.target.value);
    }
    const addStudent = ()=>{
        const {newFormError, isValid} = studentValidation(studentData);
        setStudentDataError(newFormError);
        if(!isValid) return;
        setStudentTable([...studentTable, studentData]);
    }
    const handleStudentDataUpdate = (idx,value)=>{
        const newStudentDataTable = [...studentTable];
        if(idx < newStudentDataTable.length){
            newStudentDataTable[idx] = value;
        }
        console.log(newStudentDataTable);
        setStudentTable(newStudentDataTable);
    }
    const deleteStudent = (indexOfElement)=>{
        const newStudentTable = studentTable.filter((value,index)=>(index!==indexOfElement));
        setStudentTable(newStudentTable);
    }
    
    const handleStudentCSVData = (
        fullname,
        duration,
        branch,
        mobile_no,
        email,
        gender
    )=>{
        const data = {
            fullname,
            duration,
            branch,
            email,
            mobile_no : mobile_no?.toString(),
            gender
        };
        return data;
    }
    
    const handleSheetUpload = (e)=>{
        try{
            var files = e.target.files, f = files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                
                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
                const students = [];
                for(let i = 1; i < Math.max(dataParse.length,500); i++){
                    let student =  dataParse[i];
                    try{
                        students.push(handleStudentCSVData(...student));
                    }catch(error){
                        console.log(error);
                        break;
                    }
                }
                setStudentTable(students);
            };
            reader.readAsBinaryString(f)
        }catch(error){
            console.log(error);
            toast.info('Please Upload a .xlsx file', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        return;
    }
    const handleSubmit = async ()=>{
        setLoading(true);
        try{
            if(!chapterCode){
                toast('Enter Student Chapter Code');
                return;
            }
            const data = await addStudentMembers({student_members : studentTable, chapter_code : chapterCode});
            if(data?.status===201){
                toast.success('Student Members Added', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
               });
               setStudentData({});
               setStudentTable([]);
            }
        }catch(error){
            toast.error('Validation Failed for Students Data. Please re-check and submit again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
           });
        }finally{
            setLoading(false);
        }
    }
    const downloadTemplate = () => {
        const link = document.createElement("a");
        link.download = `Template.xlsx`;
        link.href = "../../../assets/excelsheet/sheet.xlsx";
        link.click();
      };
    return (
        <div>
                {
                    loading ? 
                    <div  className={classes.gridMargin}>
                        <Paper className={classes.paper} elevation={5} align="center">
                            <CircularProgress />
                        </Paper>
                    </div>
                    :
                    <Grid container spacing={3}  className={classes.gridMargin}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" to="/dashboard" >
                                    Dashboard
                                </Link>
                                <Typography color="textPrimary">Add Student</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Paper className={classes.paper} elevation={5}>
                            <Typography  variant="h6">
                                Enter Student Chapter Code
                            </Typography>
                                <TextField
                                        variant="outlined"
                                        name="chapter_code"
                                        label="Chapter Code"
                                        onChange={handleChapterCodeChange}
                                >

                                </TextField>
                                <br/>
                                <AddStudentForm 
                                    handleStudentDataChange={handleStudentDataChange}
                                    student={studentData}
                                    studentDataError={studentDataError}
                                    addStudent={addStudent}
                                />
                                    {/* <label htmlFor="ontained-button-file6">
                                    <Button variant="raised" component="span" className={classes.button}>
                                        Upload
                                    </Button>
                                    </label> 
                                    <input
                                        // className={classes.uploadButton}
                                        id="contained-button-file6"
                                        name="sheetUpload"
                                        type="file"
                                        onChange={handleSheetUpload}
                                        accept=".xlsx"
                                        style={{ display: 'none' }}
                                    /> */}
                                    <input
                                        accept=".xlsx"
                                        className={classes.input}
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        
                                        type="file"
                                        onChange={handleSheetUpload}
                                        />
                                    <label htmlFor="raised-button-file">
                                        <Button variant="outlined" component="span" className={classes.button}>
                                            Upload Excel Sheet of the students 
                                        </Button>
                                    </label> 
                                    <Button variant="outlined" style={{marginLeft : '10px'}} onClick={downloadTemplate}>
                                        Download Excel Template
                                    </Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Paper className={classes.paper} elevation={5}>
                                <TablePreview 
                                    studentTable={studentTable}
                                    handleStudentDataUpdate={handleStudentDataUpdate}
                                    deleteStudent={deleteStudent}
                                />
                                <div align="center" className={classes.submitDiv}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Add All Students
                                </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                }
        </div>
    )
}

export default AddStudent;
