import React from 'react';
import InstitutionalMembershipForm from '../../components/InstitutionalMembershipForm/InstitutionalMembershipForm';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';

const InstitutionalMembership = () => {
    return (
        <>
        <FormHeader />
        <InstitutionalMembershipForm />
        <KJSIEIT />
        </>
    );
}

export default InstitutionalMembership;
