import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Collapse } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Button } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import useStyles from "./styles";
import { getInstitutionalRegistrationApplications } from "../../api";

const columns = [
  { id:"application_no", label: "Serial Number"},
  { id: "name_of_institution", label: "Institute Name" },
  { id: "category_of_institution", label: "Category of Institute" },
  { id: "date_of_creation", label: "Date of Creation"},
  {
    id: "first_name_of_the_officer_incharge",
    label: "Name of Officer-in-charge",
  },
  { id: "email_of_officer_incharge", label: "Email of Officer-in-charge" },
  { id: "view", label: "View" },
];

export default function InstitutionalMembershipTable() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const [institutionalMemberApplications, setInstitutionalMemberApplications] =
    useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleLoading = (value) => {
    setLoading(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = async () => {
    try {
      const data = await getInstitutionalRegistrationApplications();
      console.log(data);
      if (data?.data?.result) {
        setInstitutionalMemberApplications(data?.data?.result);
        handleLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  // getData();

  return (
    <Paper className={classes.root}>
      {loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
            {/* <colgroup>
                <col style={{width:'30%'}}/>
                <col style={{width:'15%'}}/>
                <col style={{width:'20%'}}/>
                <col style={{width:'10%'}}/>
            </colgroup> */}

              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell  
                      key={column.id}
                      // align= "center"
                      style={{ width: 200 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {institutionalMemberApplications.map((row, index) => {
                  return (
                    <TableRow
                      key={row._id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell style={{ width: 150 }}>{`${index+1}`}</TableCell>
                      <TableCell style={{ width: 350 }}>{`${row.name_of_institution}`}</TableCell>
                      <TableCell style={{ width: 400 }}>{row.category_of_institution}</TableCell>
                      <TableCell style={{ width: 400 }}>{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                      <TableCell style={{ width: 250 }}>
                        {`${row.first_name_of_the_officer_incharge} `}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>{row.email_of_officer_incharge}</TableCell>
                      
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          component={Link}
                          onClick={() => window.open(`${path}/${row._id}`,'_blank')}
                          // to={`${path}/${row._id}`}
                        >
                          <OpenInNewIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
}
