import React from 'react';
import { Paper,Grid,TextField,Button,MenuItem } from '@material-ui/core';

import useStyles from './styles';

const StudentApplicationAdminForm = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Paper elevation={5} className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "note"
                                label= "Select Remark"
                                fullWidth
                                onChange={props.handleRemarkChange}
                                variant="outlined"
                                select
                            >
                                {
                                    [
                                        'College is not ISTE institutional Member',
                                        'Signature of the Faculty Advisor / Student Incharge is not there',
                                    ].map((value,idx)=>(
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                    ))
                                }
                            </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} xl={6}>
                                <TextField
                                    required
                                    name= "payment_type"
                                    label= "Payment Type"
                                    fullWidth
                                    value={props.paymentType}
                                    onChange={(e)=>{props.setPaymentType(e.target.value)}}
                                    variant="outlined"
                                    select
                                >
                                    {
                                        [
                                            {value:"student-with-deduction", name:"With Deduction"},
                                            {value:"student-without-deduction", name:"Without Deduction"},
                                        ].map((value,idx)=>(
                                            <MenuItem key={value.value} value={value.value}>{value.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <TextField
                                required
                                name= "note2"
                                label= "Other Remarks"
                                fullWidth
                                multiline
                                onChange={props.handleRemarkChange}
                                rows={2}
                                variant="outlined"
                            />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} lg={6} xl={6} align="center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    onClick={props.handleReject}
                                    className={classes.button}
                                >
                                    Reject Application
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} xl={6} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={props.handleAccept}
                                    className={classes.button}
                                >
                                    Accept Application
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default StudentApplicationAdminForm;
