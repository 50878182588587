import {
  forgotpassrequest,
  resetpassword,
  changepassword,
} from "../api/user.js";
import { toast } from "react-toastify";

export const forgotPassRequest = async (formdata) => {
  try {
    const { data } = await forgotpassrequest(formdata);
    if (data?.error) {
      return toast("Something wrong occured");
      }
      return toast("Your change password request has been Initiated. An email has been sent to your registered e-mail id");
      
  } catch (error) {
       toast("Something wrong occured");
   
  }
};

export const resetPassword = async (formdata, router) => {
  try {
    const { data } = await resetpassword(formdata);
    if (data?.error) {
      console.log(data.error);
    }
    router.push("/");
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (formdata) => {
  try {
    const { data } = await changepassword(formdata);
    if (data?.error) {
      return toast(data.error[1]);
    }
    toast("Password Updated");
  } catch (error) {
    toast("Invalid Credentials");
  }
};
