import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';

import {
    Grid,
    Paper,
    TextField,
    CssBaseline,
    Typography,
    Divider,
    Button,
    Link
} from '@material-ui/core';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import useStyles from './styles';
import BASE_URL from '../../api/constants';

const CompletePayment = () => {
    const {order_id} = useParams();
    const classes = useStyles();
    return (
        <>
            <FormHeader />
            <CssBaseline />
            <main className={classes.paper}>    
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<Typography align="center" variant="h4" component="h4">
									Payment Already Completed
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} align="center">
								<Button 
                                    component={Link} 
                                    variant="outlined"
                                    href={`${BASE_URL}/payment-details/${order_id}`}
                                >
                                    Click Here To View Payment Details
                                </Button>
							</Grid>
						</Grid>
            </main>
            <KJSIEIT />
        </>
    )
}

export default CompletePayment;
