import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {Link} from 'react-router-dom';



import Navbar from '../Navbar/Navbar';
import useStyles from './styles';




function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { mobileOpen, handleDrawerToggle } = props;
  console.log(handleDrawerToggle);
  console.log(mobileOpen)
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      
        <Divider />
        <List>
            <ListItem button component={Link} to="/dashboard" >
              <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary={"Home"} />
            </ListItem>
            <ListItem button component={Link} to="/dashboard/life-time-membership-applications/" >
              <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText primary={"Life Membership Applications"} />
            </ListItem>
            <ListItem button component={Link} to="/dashboard/institute-membership-applications/" >
              <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText primary={"Institute Membership Applications"} />
            </ListItem>
            <ListItem button component={Link} to="/dashboard/student-membership-applications/1" >
              <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText primary={"Student Membership Applications"} />
            </ListItem>
        </List>
        <Divider />
          <List>
              <ListItem button component={Link} to="/dashboard/student-members/1" >
                <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                  <ListItemText primary={"Student Members"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/life-time-members/1" >
                <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                  <ListItemText primary={"Life Members"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/institutional-members/approved/1" >
                <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                  <ListItemText primary={"Institute Members"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/faculty-chapters/admin/view/1" >
                <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                  <ListItemText primary={"Faculty Chapters"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/student-chapters/admin/view/1" >
                <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                  <ListItemText primary={"Student Chapters"} />
              </ListItem>
          </List>
        <Divider />
        <Divider />
          <List>
              <ListItem button component={Link} to="/dashboard/student-membership-applications/admin/add-student" >
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={"Add Student Members"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/life-time-membership/admin/add" >
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={"Add Life Member"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/institute-membership/admin/add" >
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={"Add Institute Member"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/faculty-chapters/admin/add" >
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={"Add Faculty Chapters"} />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/student-chapters/admin/add" >
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                  <ListItemText primary={"Add Student Chapters"} />
              </ListItem>
          </List>
        <Divider />
          <List>
              <ListItem button component={Link} to="/dashboard/reports/" >
                <ListItemIcon><AssessmentIcon /></ListItemIcon>
                  <ListItemText primary={"Reports"}  />
              </ListItem>
              {
                props.isAdmin && (
                  <>
                  <ListItem button component={Link} to="/dashboard/states-and-districts/" >
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                      <ListItemText primary={"State And Districts"}  />
                  </ListItem>
                  <ListItem button component={Link} to="/dashboard/admin/payment-details" >
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                      <ListItemText primary={"Payment Details"}  />
                  </ListItem>
                  </>
                )
              }
              <ListItem button component={Link} to="/dashboard/admin/all-payments/1" >
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                      <ListItemText primary={"All Payments"} secondary={"View all Payments"}  />
                  </ListItem>
              <ListItem button component={Link} to="/dashboard/account/">
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText primary={"Account"} />
              </ListItem>
              {
                props.isAdmin && 
                <ListItem button component={Link} to="/dashboard/admin-control/" >
                  <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary={"Admin Controls"}  />
                </ListItem>
              }
              
          </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
            <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            >
            {drawer}
            </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
            <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            >
            {drawer}
            </Drawer>
        </Hidden>
    </nav>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
