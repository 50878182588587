import React, { useEffect,useState } from 'react';
import { Container, CssBaseline,Typography } from '@material-ui/core';
import { BrowserRouter, Switch, Route,useRouteMatch } from 'react-router-dom';


import useStyles from './styles';
import SideBar from '../../components/SideBar/SideBar';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import LifeTimeApplications from '../LifeTimeApplications/LifeTimeApplications';
import LifeTimeMembers from '../LifeTimeMembers/LifeTimeMembers';
import ViewLifeTimeApplication from '../ViewLifeTimeApplication/ViewLifeTimeApplication';
import LifeTimeRejctedApplications from '../LifeTimeApplicationsRejected/LifeTimeApplicationsRejected';
import StudentApplications from '../StudentApplications/StudentApplications';
import Dashboard from '../Dashboard/Dashboard';
import ViewStudentApplications from '../ViewStudentApplications/ViewStudentApplications';
import InstitutionalApplications from '../InstitutionalApplications/InstitutionalApplications';
import InstitutionalMembers from '../InstitutionalMembers/InstitutionalMembers';
import ViewInstitutionalApplication from '../ViewInstitutionalApplication/ViewInstitutionalApplication';
import AdminControl from '../AdminControl/AdminControl';
import UpdateEmployee from '../UpdateEmployee/UpdateEmployee';
import Account from '../Account/Account';
import StudentMembers from '../StudentMembers/StudentMembers';
import StatesAndDistricts from '../StatesAndDistricts/StatesAndDistricts';
import AddStudentMembers from '../AddStudentMembers/AddStudentMembers';
import AddLifeTimeMember from '../AddLifeTimeMember/AddLifeTimeMember';
import AddInstitutionalMember from '../AddInstitutionalMember/AddInstitutionalMember';
import AddNewChapter from '../AddNewChapter/AddNewChapter';
import FacultyChapters from '../FacultyChapters/FacultyChapters';
import StudentChapters from '../StudentChapters/StudentChapters';
import AddNewStudentChapter from '../AddNewStudentChapter/AddNewStudentChapter';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import AllPayments from '../AllPayments/AllPayments';
import Reports from '../Reports/Reports';


const Layout = () => {

    let { path, url } = useRouteMatch();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const classes = useStyles();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    useEffect(()=>{
        const user = JSON.parse(localStorage.getItem('profile'))?.result;
        if(user?.role && user.role === 'Admin'){
            setIsAdmin(true);
        }
    },[])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Navbar handleDrawerToggle={handleDrawerToggle} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path={path}>
                        <Dashboard />
                    </Route>

                    {/*Student Members*/}
                    <Route path={`${path}/student-membership-applications/:page`} exact>
                        <StudentApplications />
                    </Route>
                    <Route path={`${path}/student-membership-applications/admin/add-student`} exact>
                        <AddStudentMembers />
                    </Route>
                    <Route path={`${path}/student-membership-applications/students/:applicationId`}>
                        <ViewStudentApplications />
                    </Route>
                    <Route path={`${path}/student-members/:page`}>
                        <StudentMembers />
                    </Route>

                    {/*Life Time Members*/}
                    <Route path={`${path}/life-time-membership-applications`} exact>
                        <LifeTimeApplications />
                    </Route>
                    <Route path={`${path}/life-time-membership/admin/add`} exact>
                        <AddLifeTimeMember />
                    </Route>
                    <Route path={`${path}/life-time-membership-rejected-applications/:page`} exact>
                        <LifeTimeRejctedApplications />
                    </Route>
                    <Route path={`${path}/life-time-membership-applications/:applicationId`}>
                        <ViewLifeTimeApplication />
                    </Route>
                    <Route path={`${path}/life-time-members/:page`} >
                        <LifeTimeMembers />
                    </Route>
                    
                    {/*Institutional Members*/}
                    <Route path={`${path}/institute-membership/admin/add`} exact>
                        <AddInstitutionalMember />
                    </Route>
                    <Route path={`${path}/institute-membership-applications`} exact>
                        <InstitutionalApplications />
                    </Route>
                    <Route path={`${path}/institute-membership-applications/:applicationId`}>
                        <ViewInstitutionalApplication />
                    </Route>
                    <Route path={`${path}/institutional-members/approved/:page`} >
                        <InstitutionalMembers  approved={true} rejected={false} />
                    </Route>
                    <Route path={`${path}/institutional-members/rejected/:page`} >
                        <InstitutionalMembers  approved={false} rejected={true} />
                    </Route>

                    {/*faculty chapters*/}
                    <Route path={`${path}/faculty-chapters/admin/add`} exact>
                        <AddNewChapter />
                    </Route>
                    <Route path={`${path}/faculty-chapters/admin/view/:page`} exact>
                        <FacultyChapters />
                    </Route>

                    {/* Student Chapters */}
                    <Route path={`${path}/student-chapters/admin/add`} exact>
                        <AddNewStudentChapter />
                    </Route>
                    <Route path={`${path}/student-chapters/admin/view/:page`} exact>
                        <StudentChapters />
                    </Route>
                   
                    {/*States and Districts*/}
                    <Route path={`${path}/states-and-districts`}>
                        <StatesAndDistricts />
                    </Route>
                    <Route path={`${path}/reports`}>
                        <Reports />
                    </Route>

                    {/*Payment Details*/}
                    <Route path={`${path}/admin/payment-details`}>
                        <PaymentDetails />
                    </Route>
                    <Route path={`${path}/admin/all-payments/:page`}>
                        <AllPayments />
                    </Route>
                    
                    <Route path={`${path}/account`}>
                        <Account />
                    </Route>
                    <Route path={`${path}/admin-control`} exact>
                        <AdminControl />
                    </Route>
                    <Route path={`${path}/admin-control/update-employee/:id`}>
                        <UpdateEmployee />
                    </Route>

                </Switch>
                <KJSIEIT />
            </main>
            <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} isAdmin = {isAdmin} />
        </div>
    );
}

export default Layout;
