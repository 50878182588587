import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
    control: {
      padding: theme.spacing(2),
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: "95%",
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
        borderRadius : theme.spacing(2)
    },
    formTitle : {
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(3),
        fontWeight: 'bold'
      },
      uploadButton: {
        display: 'none',
      },
      buttonIcon : {
        margin: theme.spacing(1),
      },
      uploadExcelButton : {
        margin : theme.spacing(1)
      }
  }));