import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    paper: {
        padding : theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
        padding : theme.spacing(3)
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    
}));