import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import {Grid, MenuItem} from '@material-ui/core';
import moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { updateStudentMember,deleteStudentMember } from '../../api';
import useStyles from './styles';


function FormDialog(props) {
    const [open, setOpen] = React.useState(false);

    
    const handleClickOpen = async () => {
      setOpen(true);
    };
    const handleDelete = async ()=>{
        await props.handleDelete();
        handleClose();
    }
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Delete
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Are you sure you want to delete the student member? </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Once deleted you won't be able to restore students data
            </DialogContentText>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  


export default function StudentMemberFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [student, setstudent] = useState(props.value);
  const handleChange = (e)=>{
      setstudent({...student, [e.target.name]:e.target.value});
  }
  const handleUpdate = async ()=>{
      try{
        await updateStudentMember(student);
      }catch(error){
        console.log(error);
      }
  }
  const handleDelete = async ()=>{
    try{
      await deleteStudentMember(student);
      setOpen(false);
    }catch(error){
      console.log(error);
    }
  }
  const classses = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <OpenInNewIcon />
      </Button>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth="md" 
        // scroll={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Chapter Code : {props.value.chapter_code}</DialogTitle>
        
        <DialogContent dividers="papers">
          <DialogContentText>
            Please click on cancel if you dont want to update any information 
          </DialogContentText>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Fullname"
                        name="fullname"
                        value={student.fullname}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Duration"
                        name="duration"
                        value={student.duration}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Mobile No"
                        name="mobile_no"
                        value={student.mobile_no}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={student.email}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Branch"
                        name="branch"
                        value={student.branch}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Membership Status"
                        name="membership_status"
                        value={student.membership_status}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        variant="outlined"
                        label="Institute Membership Id"
                        name="institute_membership_id"
                        value={student.institute_membership_id}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={12}>
                    <TextField
                        variant="outlined"
                        label="Institute Name"
                        name="institute_name"
                        value={student.institute_name}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                
                {/* <Grid item sm={12} lg={6}>
                    <TextField
                        
                        variant="outlined"
                        label="Institute Address"
                        name="institute_address"
                        value={student.institute_address}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute Address 2"
                        name="institute_address2"
                        value={student.institute_address2}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid> */}
               
                <Grid item sm={12} lg={4}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute State"
                        name="institute_state"
                        value={student.institute_state}
                        onChange={handleChange}
                        fullWidth
                    >
                    </TextField>
                </Grid>
                <Grid item sm={12} lg={4}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Institute Pin"
                        name="institute_pin"
                        value={student.institute_pin}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Chapter Code"
                        value={student.chapter_code}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Section Code"
                        value={student.section_code}
                        fullWidth
                    />
                </Grid>
                
                <Grid item sm={12} lg={3}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        label="Application Date"
                        type="application_date"
                        value={moment(student.membership_approval_date).format('DD-MM-YYYY')}
                        disabled
                        fullWidth
                    />
                    
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={classses.dialogFooter}>
          <Button onClick={handleClose}  color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Update
          </Button>
          {
            JSON.parse(localStorage.getItem('profile'))?.result?.role==='Admin' && 
          <FormDialog value={props.value} handleDelete={handleDelete} />
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
