import React from "react";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useStyles from "./styles";

const NewInputForm = ({
  handleSubmit,
  handleChange,
  natureOfPrincipalActivity,
  categoryOfInstitution,
  handleSignatureUpload,
  signature,
  stateData,
  designation,
  districtsData,
  formData,
  formError,
  setFormData,
  sections
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.formTitle}
        component="h5"
        variant="h5"
      >
        Add Institute Member
      </Typography>

      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography component="body1" variant="body1">
              Institute Details
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              required
              error={formError.nameOfInstitution}
              helperText={formError.nameOfInstitution && "Invalid Entry"}
              name="nameOfInstitution"
              label="Name of Institution/Industry/Organsation"
              value={formData.nameOfInstitution}
              fullWidth
              variant="outlined"
              autoFocus
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              error={formError.addressForCorrespondence}
              helperText={formError.addressForCorrespondence && "Invalid Entry"}
              name="addressForCorrespondence"
              label="Address for Correspondence"
              value={formData.addressForCorrespondence}
              rows="3"
              multiline
              fullWidth
              variant="outlined"
              required
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.pincodeForCorrespondence}
              helperText={formError.pincodeForCorrespondence && "Invalid Entry"}
              name="pincodeForCorrespondence"
              label="Pincode for Correspondence"
              value={formData.pincodeForCorrespondence}
              type="number"
              fullWidth
              required
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
            error={formError.phoneNumberForCorrespondence}
            helperText={
              formError.phoneNumberForCorrespondence && "Invalid Entry"
            }
              name="phoneNumberForCorrespondence"
              label="Phone No. for Correspondence"
              value={formData.phoneNumberForCorrespondence}
              fullWidth
              type="number"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl= {4}>
            <TextField
              error={formError.telephoneNumberForCorrespondence}
              helperText={
                formError.telephoneNumberForCorrespondence && "Invalid Entry"
              }
              name="telephoneNumberForCorrespondence"
              label="Alterate Contact Number"
              value={formData.telephoneNumberForCorrespondence}
              fullWidth
              type="number"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <TextField
                        required
                        name= "state"
                        error={formError.state}
                        helperText={formError.state && 'Invalid Entry'}
                        label= "Name of State"
                        value={formData.state || ''}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                        select
                    >
                      {stateData.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <TextField
                        required
                        name= "district"
                        error={formError.district}
                        helperText={formError.district && 'Invalid Entry'}
                        label= "Name of District"
                        value={formData.district || ''}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                        select
                    >
                      {districtsData
                      .filter((value)=>(!formData.state || formData.state===value?.state?._id))
                      .map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                    <TextField
                        required
                        name= "section"
                        error={formError.section}
                        helperText={formError.section && 'Invalid Entry'}
                        label= "Section"
                        value={formData.section || ''}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                        select
                    >
                      {sections.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.section_name}
                        </MenuItem>
                      ))}
                    </TextField>
              </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.city}
              helperText={formError.city && "Invalid Entry"}
              name="city"
              label="City"
              value={formData.city}
              required
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              required
              error={formError.instituteEmail}
              helperText={formError.instituteEmail && "Invalid Entry"}
              name="instituteEmail"
              label="Institute Email ID "
              value={formData.instituteEmail}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              
              error={formError.alternateEmail}
              helperText={formError.alternateEmail && "Invalid Entry"}
              name="alternateEmail"
              label="Alternate Email ID "
              value={formData.alternateEmail}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              required
              id="outlined-full-width"
              // helperText={formError.natureofPrincipalActivity && 'Invalid Entry'}
              name="natureOfPrincipalActivity"
              label="Nature of Principal Activity"
              value={formData.natureofPrincipalActivity}
              error={formError.natureofPrincipalActivity}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              autoFocus
              select
            >
              {natureOfPrincipalActivity.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              required
              id="outlined-full-width"
              // helperText={formError.categoryOfInstitution && 'Invalid Entry'}
              name="categoryOfInstitution"
              label="Category of Institution"
              value={formData.categoryOfInstitution}
              error={formError.categoryOfInstitution}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              autoFocus
              select
            >
              {categoryOfInstitution.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              required
              id="outlined-full-width"
              helperText={formError.typeOfInstitute && 'Invalid Entry'}
              name="typeOfInstitute"
              label="Type of Institution"
              value={formData.typeOfInstitute}
              error={formError.typeOfInstitute}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              select
            >
              {['Degree', 'Diploma', 'MBA-MCA','Others(Industry/Private/Public Sectors)'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography component="body1" variant="body1">
              Officer Incharge Details
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.firstNameOfOfficerIncharge}
              helperText={
                formError.firstNameOfOfficerIncharge && "Invalid Entry"
              }
              name="firstNameOfOfficerIncharge"
              label="First Name of Officer Incharge"
              value={formData.firstNameOfOfficerIncharge}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.lastNameOfOfficerIncharge}
              helperText={
                formError.lastNameOfOfficerIncharge && "Invalid Entry"
              }
              name="lastNameOfOfficerIncharge"
              label="Last Name of Officer Incharge"
              value={formData.lastNameOfOfficerIncharge}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              
              id="outlined-full-width"
              name="designation"
              label="Designation"
              value={formData.designation}
              error={formError.designation}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              
              select
            >
              {designation.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              
              error={formError.emailOfOfficerIncharge}
              helperText={formError.emailOfOfficerIncharge && "Invalid Entry"}
              name="emailOfOfficerIncharge"
              label="Email ID of Officer Incharge "
              value={formData.emailOfOfficerIncharge}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
            
            error={formError.phoneNumberOfOfficerIncharge}
            helperText={
              formError.phoneNumberOfOfficerIncharge && "Invalid Entry"
            }
              name="phoneNumberOfOfficerIncharge"
              label="Contact Number"
              value={formData.phoneNumberOfOfficerIncharge}
              fullWidth
              type="number"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              error={formError.addressNameOfTheOfficerIncharge}
              helperText={
                formError.addressNameOfTheOfficerIncharge && "Invalid Entry"
              }
              name="addressNameOfTheOfficerIncharge"
              label="Address of Officer Incharge"
              value={formData.addressNameOfTheOfficerIncharge}
              rows="3"
              multiline
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Application"
                  format="dd/MM/yyyy"
                  name="applicationDate"
                  value={formData.applicationDate}
                  error = {formError.applicationDate}
                  helperText={formError.applicationDate && 'Invalid Date'}
                  InputAdornmentProps={{ position: "start" }}
                  onChange = {(date)=>{
                    setFormData({...formData, applicationDate : date});
                  }}
                  fullWidth
                />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              Add Member
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default NewInputForm;
