import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button : {
        margin : theme.spacing(2),
        [theme.breakpoints.up('sm')] : {
           paddingLeft : theme.spacing(8),
           paddingRight : theme.spacing(8),
        }
    },
    textField : {
        [theme.breakpoints.up('sm')] : {
            width : '50%'
        },
        width : '90%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));