import React,{useState,useEffect} from "react";
import moment from "moment";
import axios from "axios";
import {
  Grid,
  Typography,
  Divider,
  Link,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router";
import {toast} from 'react-toastify';
import {
  updateInstitutionalMember,
  deleteInstitutionalMember,
  getAllSections,
  getAllStates,
  getAllDistricts
} from '../../api/index';
import BASE_URL from '../../api/constants';
import validation from "./validation";

const natureOfPrincipalActivity = [
  {
    value: "EDUCATIONAL",
    label: "EDUCATIONAL",
  },
  {
    value: "MANUFACTURING",
    label: "MANUFACTURING",
  },
  {
    value: "CONSULTANCY",
    label: "CONSULTANCY",
  },
  {
    value: "R&D/Corporate Sector",
    label: "R&D/Corporate Sector",
  },
  {
    value: "CONSTRUCTION",
    label: "CONSTRUCTION",
  },
  {
    value: "OTHERS",
    label: "OTHERS",
  },
];


const categoryOfInstitution = [
  {
    value: "UNIVERSITY",
    label: "UNIVERSITY",
  },
  {
    value: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
    label: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
  },
  {
    value: "ENGINEERING COLLEGE",
    label: "ENGINEERING COLLEGE",
  },
  {
    value: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
    label: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
  },
  {
    value: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
    label: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
  },
  {
    value: "POLYTECHNICS",
    label: "POLYTECHNICS",
  },
  {
    value: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
    label: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
  },
  {
    value: "CORPORATE INSTITUTIONAL MEMBER",
    label: "CORPORATE INSTITUTIONAL MEMBER",
  },
  {
    value: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
    label: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
  },
  {
    value: "STATUTORY BODIES",
    label: "STATUTORY BODIES",
  },
  {
    value: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
    label: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
  },
];


function DeleteAlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async ()=>{
    await props.handleDelete();
    history.push('/dashboard/institutional-members/approved/1');
  }
  
  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Delete Member Application
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Institution member application?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted you won't be able to restore it again.
            Please Click on cancel or anywhere outside the dialog box if you don't want to delete the application
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ViewInstitutionalMembershipComponent = ({ application,handleApplicationDataChange }) => {
    const classes = useStyles();
    const [stateData, setStatesData] = useState([]);
    const [districtsData, setDistrictsData] = useState([]);
    const [sections,setSections] = useState([]);
    const [formError, setFormError] = useState({});
    let fullName;
    if(application.first_name_of_the_officer_incharge){
      fullName = application.first_name_of_the_officer_incharge
    }
    if(application.last_name_of_the_officer_incharge){
      fullName += ' ';
      fullName += application.last_name_of_the_officer_incharge;
    }

    const downloadDetails = async (start,end)=>{
      try{
        axios({
          url: `${BASE_URL}/api/institute-membership/download-member-data`, //your url
          method: 'GET',
          headers : {
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
          },
          params : {id : application._id},
          responseType: 'blob', // important
          }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `IM-Details-${application.membership_no || ''}.pdf`); //or any other extension
              document.body.appendChild(link);
              link.click();
          });
  
      }catch(error){
        console.log(error);
      }
    } 

    const handleDelete = async ()=>{
      try{
        await deleteInstitutionalMember(application);
      }catch(error){
        console.log(error);
      }
    }
    const handleUpdate = async ()=>{
      let isFormValid = true;
      // validations 
      const newFormError = validation(application,formError);
      console.log(newFormError)
      for(const key in newFormError){
        if(newFormError[key]){
          isFormValid = false;
        }
      }
      setFormError(newFormError);
      console.log(isFormValid)
      if(!isFormValid) return;
      try{
        await updateInstitutionalMember(application);
        toast.success('Application Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }catch(error){
        console.log(error);
      }
    }
    const getStates = async ()=>{
      try{
          const data = await getAllStates();
          console.log(data);
          if(data?.status === 200){
            setStatesData(data?.data?.result);
          }
      }catch(error){
          console.log(error);
      }
  }
  const getDistricts = async ()=>{
      try{
          const data = await getAllDistricts();
          console.log(data);
          if(data?.status === 200){
            setDistrictsData(data?.data?.result);
          }
      }catch(error){
          console.log(error);
      }
  }
    const getSections = async ()=>{
      try{
          const data = await getAllSections();
          console.log(data);
          if(data?.status === 200){
            setSections(data?.data?.result);
          }
      }catch(error){
          console.log(error);
      }
    }
    useEffect(()=>{
      getSections();
      getStates();
      getDistricts();
    },[])
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography variant="body1">Institute Details</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.membership_no && (
            <Typography component="h5" variant="h5">
              <b>Membership No : IM {application?.membership_no}</b>
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            error={formError.name_of_institution}
            helperText={formError.name_of_institution && "Invalid Entry"}
            name="name_of_institution"
            label="Name of Institution/Industry/Organsation"
            fullWidth
            variant="outlined"
            value={application.name_of_institution}
            onChange={handleApplicationDataChange}
            color="black"
          >
            Dr.
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            required
            error={formError.address_for_correspondence}
            helperText={formError.address_for_correspondence && "Invalid Entry"}
            name="address_for_correspondence"
            label="Address for Correspondence"
            fullWidth
            variant="outlined"
            value={application.address_for_correspondence}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            error={formError.pincode_for_correspondence}
            helperText={formError.pincode_for_correspondence && "Invalid Entry"}
            name="pincode_for_correspondence"
            label="Pincode for Correspondence"
            fullWidth
            variant="outlined"
            value={application.pincode_for_correspondence}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            // required
            error={formError.telephone_number_for_correspondence}
            helperText={formError.telephone_number_for_correspondence && "Invalid Entry"}
            name="telephone_number_for_correspondence"
            label="Telephone No. for Correspondence"
            fullWidth
            variant="outlined"
            value={application.telephone_number_for_correspondence}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            // required
            error={formError.phone_number_for_correspondence}
            helperText={formError.phone_number_for_correspondence && "Invalid Entry"}
            name="phone_number_for_correspondence"
            label="Phone No. for Correspondence"
            fullWidth
            variant="outlined"
            value={application.phone_number_for_correspondence}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            // required
            error={formError.institute_email}
            helperText={formError.institute_email && "Invalid Entry"}
            name="institute_email"
            label="Institute Email"
            fullWidth
            variant="outlined"
            value={application.institute_email}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            // required
            error={formError.alternate_email}
            helperText={formError.alternate_email && "Invalid Entry"}
            name="alternate_email"
            label="Alternate Email"
            fullWidth
            variant="outlined"
            value={application.alternate_email}
            onChange={handleApplicationDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            error={formError.nature_of_principal_activity}
            helperText={formError.nature_of_principal_activity && "Invalid Entry"}
            name="nature_of_principal_activity"
            label="Nature of Principal Activity"
            fullWidth
            variant="outlined"
            value={application.nature_of_principal_activity}
            onChange={handleApplicationDataChange}
            select
          >
             {natureOfPrincipalActivity.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            error={formError.category_of_institution}
            helperText={formError.category_of_institution && "Invalid Entry"}
            name="category_of_institution"
            label="Category of Institution"
            fullWidth
            variant="outlined"
            value={application.category_of_institution}
            onChange={handleApplicationDataChange}
            select
          >
             {categoryOfInstitution.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <TextField
            required
            error={formError.type_of_institution}
            helperText={formError.type_of_institution && "Invalid Entry"}
            name="type_of_institution"
            label="Type of Institution"
            fullWidth
            variant="outlined"
            value={application.type_of_institution}
            onChange={handleApplicationDataChange}
            select
          >
            {['Degree', 'Diploma', 'MBA-MCA','Others(Industry/Private/Public Sectors)'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography variant="body1">Officer Incharge Details</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            error={formError.first_name_of_the_officer_incharge}
            helperText={formError.first_name_of_the_officer_incharge && "Invalid Entry"}
            name="first_name_of_the_officer_incharge"
            label="First Name of Officer Incharge"
            fullWidth
            variant="outlined"
            value={application.first_name_of_the_officer_incharge }
            onChange={handleApplicationDataChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            error={formError.last_name_of_the_officer_incharge}
            helperText={formError.last_name_of_the_officer_incharge && "Invalid Entry"}
            name="last_name_of_the_officer_incharge"
            label="Last Name of Officer Incharge"
            fullWidth
            variant="outlined"
            value={application.last_name_of_the_officer_incharge}
            onChange={handleApplicationDataChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            error={formError.email_of_officer_incharge}
            helperText={formError.email_of_officer_incharge && "Invalid Entry"}
            name="email_of_officer_incharge"
            label="Email ID of Officer Incharge"
            type="email"
            fullWidth
            value={application.email_of_officer_incharge}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <TextField
            required
            error={formError.phone_number_of_officer_incharge}
            helperText={formError.phone_number_of_officer_incharge && "Invalid Entry"}
            name="phone_number_of_officer_incharge"
            label="Phone Number of Officer Incharge"
            fullWidth
            value={application.phone_number_of_officer_incharge}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            error={formError.address_name_of_the_officer_incharge}
            helperText={formError.address_name_of_the_officer_incharge && "Invalid Entry"}
            name="address_name_of_the_officer_incharge"
            label="Address of Officer Incharge"
            fullWidth
            value={application.address_name_of_the_officer_incharge}
            onChange={handleApplicationDataChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="state"
            label="Name of State"
            fullWidth
            value={application?.state}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            {
              stateData.map((value,idx)=>(
                <MenuItem value={value.name}>
                  {value.name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="district"
            label="Name of District"
            fullWidth
            value={application?.district}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            
            {
              districtsData.map((value,idx)=>(
                <MenuItem value={value._id}>
                  {value.name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            error={formError.section_code}
            helperText={formError.section_code && "Invalid Entry"}
            name="section_code"
            label="Section"
            fullWidth
            value={application?.section_code}
            onChange={handleApplicationDataChange}
            select
            variant="outlined"
          >
            
            {
              sections.map((value,idx)=>(
                <MenuItem value={value.section_code}>
                  {value.section_name}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <TextField
            required
            name="createdAt"
            label="Date of Submission"
            fullWidth
            variant="outlined"
            value={moment(application.createdAt).format("DD-MM-YYYY")}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
          <Button variant="contained"  onClick={handleUpdate} color="primary">Update Application</Button>
        </Grid>
        {
          JSON.parse(localStorage.getItem('profile'))?.result?.role==='Admin' && 
          <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
            <DeleteAlertDialog handleDelete={handleDelete}/>
          </Grid>
        }
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Supporting Documents
          </Typography>
          <Divider />
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Button
              component={Link}
              target="_blank"
              variant="outlined"
              color="primary"
              fullWidth
              href={`${BASE_URL}/files/institutionalmembership/signature/${application.signature}`}
            >
              View Signature
            </Button>
          </Grid>
         
        </Grid>
        {
          application.membership_no && (
            <>
            <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
              <Button
                component={Link}
                target="_blank"
                variant="outlined"
                color="primary"
                
                href={`${BASE_URL}/api/institute-membership/download-certificate?id=${application._id}`}
              >
                Download Certificate
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
              <Button
                component={Link}
                target="_blank"
                variant="outlined"
                color="primary"
                
                href={`${BASE_URL}/api/institute-membership/download-qr-certificate?id=${application._id}`}
              >
                Download Online QR Certificate
              </Button>
            </Grid>
            </>
            
          )
        }
        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={downloadDetails}
              >
                Download Details
              </Button>
            </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography component="body1" variant="body1">
            Application History
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.last_updated_by && (
            <Typography component="body1" variant="body1">
              Last Updated By : {application?.last_updated_by?.email}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.processed_by && (
            <Typography>
              Last Updated/Reviewed By : {application?.processed_by?.email}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.createdAt && (
            <Typography>
              Created At : {moment(application?.createdAt).format('DD-MM-YYYY hh:mm:ss')}
            </Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          {
            application.updatedAt && (
            <Typography>
              Updated At : {moment(application?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}
            </Typography>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewInstitutionalMembershipComponent;
