import React from 'react';
import { Grid,Breadcrumbs,Typography,Paper,Button,Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LifeTimeApplicationsRejectedTable from '../../components/LifeTimeApplicationsRejectedTable/LifeTimeApplicationsRejectedTable';

import ViewLifeTimeApplicationComponent from '../../components/ViewLifeTimeApplicationComponent/ViewLifeTimeApplicationComponent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useStyles from './styles';

const LifeTimeRejctedApplications = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Life Time Membership Rejected Applications</Typography>
                </Breadcrumbs>
            </Grid>
            <Hidden smDown>
                <Grid item xs={12} sm={12} lg={6} align="right">
                    <Button className={classes.buttons}  variant="outlined" size="small" color="primary" to="/dashboard/life-time-members/1" component={Link}>
                        <OpenInNewIcon /> View Approved Applications
                    </Button>
                    <Button variant="contained" size="small" color="primary" to="/dashboard/life-time-membership-applications/" component={Link}>
                        <OpenInNewIcon /> View Applications
                    </Button>
                </Grid>
            </Hidden>
            <Grid item xs={12}>
                <Paper elevation={5}>
                    <LifeTimeApplicationsRejectedTable />
                </Paper>
            </Grid>
        </Grid>
        
    )
}

export default LifeTimeRejctedApplications
