import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useParams , useHistory } from "react-router-dom";

import useStyles from "./styles";
import { updateEmployee, getEmployeeById, removeEmployee } from "../../api/index";

const UpdateEmployee = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [employee, setEmployee] = useState({});
  const handleChange = (event) => {
    console.log(employee.role)
    setEmployee({ ...employee, [event.target.name]: event.target.value });
  };
  const getData = async () => {
    try {
      const data = await getEmployeeById({ id });
      console.log(data);
      if (data?.status === 200) {
        setEmployee(data?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleDelete = async ()=>{
    try {
      const payload = {
        id : employee._id
      }
      console.log(payload);
      const data = await removeEmployee(payload);
      console.log(data);
      if (data?.status === 200) {
        toast.success("Employee Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,

          progress: undefined,
        });
        history.push('/dashboard/admin-control/')
      } else {
        toast.success("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.success("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(employee);
      const data = await updateEmployee({
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        role: employee.role,
        employeeId: employee.employee_id,
        id: id,
      });
      if (data?.status === 200) {
        toast.success("Employee Data Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,

          progress: undefined,
        });
      } else {
        toast.success("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.success("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <Paper className={classes.paper} elevation={5}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="h5">Update Employee</Typography>
          </Grid>

          <Grid item xs={12} sm={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="First Name"
              InputLabelProps={{ shrink: true }}
              name="firstName"
              onChange={handleChange}
              value={employee.firstName}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Last Name"
              name="lastName"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={employee.lastName}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              label="Email"
              name="email"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={employee.email}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TextField
              required
              name="role"
              label="Role"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              select
              value={employee.role}
            >
              <MenuItem key={"Admin"} value={"Admin"}>
                Admin
              </MenuItem>
              <MenuItem key={"Employee"} value={"Employee"}>
                Employee
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Employee ID"
              InputLabelProps={{ shrink: true }}
              value={employee.employee_id}
              name="employee_id"
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={12} align="center">
            <Grid container spacing={3}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Update Employee
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Delete Employee
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default UpdateEmployee;
