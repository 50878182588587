import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import InputForm from "./InputForm";
import validation from "./validation";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as api from "../../api";

import { toast } from 'react-toastify';


const natureOfPrincipalActivity = [
  {
    value: "EDUCATIONAL",
    label: "EDUCATIONAL",
  },
  {
    value: "MANUFACTURING",
    label: "MANUFACTURING",
  },
  {
    value: "CONSULTANCY",
    label: "CONSULTANCY",
  },
  {
    value: "R&D/Corporate Sector",
    label: "R&D/Corporate Sector",
  },
  {
    value: "CONSTRUCTION",
    label: "CONSTRUCTION",
  },
  {
    value: "OTHERS",
    label: "OTHERS",
  },
];

const designation = [
  {
    value : 'Professor',
    label : 'Professor'
  },
  {
    value : 'Associate Professor',
    label : 'Associate Professor'
  },
  {
    value : 'Assistant Professor',
    label : 'Assistant Professor'
  },
  {
    value : 'Lecturer',
    label : 'Lecturer'
  },
  {
    value : 'Staff',
    label : 'Staff'
  },
]


const categoryOfInstitution = [
  {
    value: "UNIVERSITY",
    label: "UNIVERSITY",
  },
  {
    value: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
    label: "UNIVERSITY DEPARTMENT (DULY RECOGNISED)",
  },
  {
    value: "ENGINEERING COLLEGE",
    label: "ENGINEERING COLLEGE",
  },
  {
    value: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
    label: "SCIENCE AND TECHNICAL INSTITUTE IN INDIA AND ABROAD (DULY APPROVED BY AICTE / UGC)",
  },
  {
    value: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
    label: "STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)",
  },
  {
    value: "POLYTECHNICS",
    label: "POLYTECHNICS",
  },
  {
    value: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
    label: "INDUSTRIAL AND PROFESSIONAL ORGANISATIONS",
  },
  {
    value: "CORPORATE INSTITUTIONAL MEMBER",
    label: "CORPORATE INSTITUTIONAL MEMBER",
  },
  {
    value: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
    label: "DEPARTMENTS OF CENTRAL AND STATE GOVTS",
  },
  {
    value: "STATUTORY BODIES",
    label: "STATUTORY BODIES",
  },
  {
    value: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
    label: "AUTONOMOUS BODIES HAVING INTEREST IN TECHNICAL EDUCATION",
  },
];

const initialState = {
  nameOfInstitution: "",
  addressForCorrespondence: "",
  pincodeForCorrespondence: "",
  telephoneNumberForCorrespondence: "",
  phoneNumberForCorrespondence: "",
  instituteEmail: "",
  alternateEmail: "",
  natureOfPrincipalActivity: "",
  typeOfInstitute : '',
  categoryOfInstitution: "",
  firstNameOfOfficerIncharge: "",
  lastNameOfOfficerIncharge: "",
  emailOfOfficerIncharge: "",
  designation: "",
  addressNameOfTheOfficerIncharge: "",
  phoneNumberOfOfficerIncharge: "",
  state : "",
  district : null,
  // dateOfSubmission : "",
};

export default function AddInstitutionalMemberComponent() {
  const classes = useStyles();
  const [formData,setFormData] = useState(initialState);
  const [ formError, setFormError ] = useState({});
  const [loading, setLoading] = useState(false);
  const [backDrop,setBackDrop] = useState(false);
  const [ dialog,setDialog ] = useState(false);
  const [signature, setSignature] = useState(null);
  const [termsAndConditions, setTermsAndConditions]  = useState(false);
  const [stateData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [sections,setSections] = useState([]);

  const getStates = async ()=>{
    try{
        const data = await api.getAllStates();
        console.log(data);
        if(data?.status === 200){
          setStatesData(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
  }
  const getDistricts = async ()=>{
      try{
          const data = await api.getAllDistricts();
          console.log(data);
          if(data?.status === 200){
            setDistrictsData(data?.data?.result);
          }
      }catch(error){
          console.log(error);
      }
  }
  const getSections = async ()=>{
    try{
        const data = await api.getAllSections();
        console.log(data);
        if(data?.status === 200){
          setSections(data?.data?.result);
        }
    }catch(error){
        console.log(error);
    }
  }

  const submitForm = async ()=>{
    console.log(formData);
    setLoading(true);
    // console.log(file);
    try{
      const newFormData = new FormData();
      delete formData.state;
      for(const field in formData){
        newFormData.append(field, formData[field]);
      }

      const result = await api.adminAddInstitutionalMember(formData);
      if(result.status===200){
        toast.success('Member Added', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setFormData({});
      }
    }catch(error){
      console.log(error.response);
    }finally{
      setLoading(false);
    }
  }
  const handleSignatureUpload = e =>{
    setSignature(e.target.files[0]);
  }

  const handleChange = (e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
  }
  
  const handleSubmit = async (e)=>{
    e.preventDefault();
    let isFormValid = true;
    // validations 
    const newFormError = validation(formData,formError);
    // checks if any of the fields are not validated 
    // and changes isFormValid accordingly 
    for(const key in newFormError){
      if(newFormError[key]){
        isFormValid = false;
      }
    }
    console.log(newFormError)
    setFormError(newFormError);
    if(!isFormValid) return;
    await submitForm();
  }


  useEffect(()=>{
    getStates();
    getDistricts();
    getSections();
  }, []);

  return (
    <Grid className={classes.gridMargin}>
      <Grid item xs={12} sm={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/dashboard" >
                  Dashboard
              </Link>
              <Typography color="textPrimary">Add Institute Member</Typography>
          </Breadcrumbs>
      </Grid>
      <Grid item>
        {
          loading ? 
          (
            <Paper className={classes.loading} align="center">
              <CircularProgress  />
            </Paper>
          ):(
            <Paper className={classes.paper} elevation={5}>
            <InputForm 
                formData={formData} 
                formError = {formError}
                handleSubmit={handleSubmit} 
                handleChange={handleChange} 
                natureOfPrincipalActivity = {natureOfPrincipalActivity}
                categoryOfInstitution={categoryOfInstitution}        
                handleSignatureUpload={handleSignatureUpload}
                designation={designation}
                signature={signature}
                setFormData={setFormData}
                setTermsAndConditions={setTermsAndConditions}
                termsAndConditions={termsAndConditions}
                stateData={stateData}
                districtsData={districtsData}
                sections={sections}
              /> 
          </Paper>
          )
        }
       
      </Grid>
    </Grid>
  );
}