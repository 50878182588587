import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import istelogo2 from "../../assets/images/istelogo2.png";
import { Hidden } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
    padding: theme.spacing(4),
    color: "#010057",
  },
  alertWrapper: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  movingAlert: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    animation: `$scroll 10s linear infinite`,
  },
  alertMessage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '1rem', // Adjust the size here (e.g., 1rem or 0.75rem)
    lineHeight: '1', // Adjust the line height if necessary
  },
  '@keyframes scroll': {
    '0%': { transform: 'translateX(100%)' }, // Start from just off the right end
    '100%': { transform: 'translateX(-100%)' }, // Move off-screen to the left
  },
}));

export default function FormHeader(props) {
  const classes = useStyles();
  const { sections, title } = props;
  const currentDate = new Date().toLocaleDateString();

  return (
    <React.Fragment>
      <div className={classes.alertWrapper}>
        <Alert severity="warning" className={classes.movingAlert} variant="filled">
          <div className={classes.alertMessage}>
            <Typography variant="body1">
            Please note: Our website is under maintenance from 10 PM to 11:50 PM on {currentDate}. The services would be closed during this time.

            </Typography>
          </div>
        </Alert>
      </div>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h3"
          variant="h4"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          <div>
            <img width="100" alt="" src={istelogo2} />
          </div>
          <Hidden smDown>
            <b>INDIAN SOCIETY FOR TECHNICAL EDUCATION</b>
          </Hidden>
        </Typography>
      </Toolbar>
    </React.Fragment>
  );
}

FormHeader.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
