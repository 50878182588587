import React from 'react';
import AddStudent from '../../components/AddStudent/AddStudent';

const AddStudentMembers = () => {
    return (
        <div>
            <AddStudent />
        </div>
    )
}

export default AddStudentMembers
