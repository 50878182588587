const validation = (formData,formError)=>{
    // const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const newFormError = {...formError};
    // const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    newFormError.nameOfInstitution = (!formData.nameOfInstitution || formData.nameOfInstitution === "");
    newFormError.addressForCorrespondence = (!formData.addressForCorrespondence || formData.addressForCorrespondence === '');
    newFormError.pincodeForCorrespondence = (!formData.pincodeForCorrespondence || formData.qualifications === '');
    // newFormError.telephoneNumberForCorrespondence = !mobileNoRegex.test(formData.telephoneNumberForCorrespondence);
    // newFormError.phoneNumberForCorrespondence = (!mobileNoRegex.test(formData.phoneNumberForCorrespondence));
    newFormError.instituteEmail = (!formData.instituteEmail || !emailRegex.test(formData.instituteEmail));
    // newFormError.alternateEmail = (!formData.alternateEmail || !emailRegex.test(formData.alternateEmail));
    newFormError.natureOfPrincipalActivity = (!formData.natureOfPrincipalActivity || formData.natureOfPrincipalActivity==='');
    newFormError.categoryOfInstitution = (!formData.categoryOfInstitution)
    // newFormError.firstNameOfOfficerIncharge = (!formData.firstNameOfOfficerIncharge || formData.firstNameOfOfficerIncharge === "" ||  !nameRegex.test(formData.firstNameOfOfficerIncharge));
    // newFormError.lastNameOfOfficerIncharge = (!formData.lastNameOfOfficerIncharge || formData.lastNameOfOfficerIncharge === "" || !nameRegex.test(formData.lastNameOfOfficerIncharge) );
    // newFormError.emailOfOfficerIncharge = (!formData.emailOfOfficerIncharge || !emailRegex.test(formData.emailOfOfficerIncharge));
    // newFormError.addressNameOfTheOfficerIncharge = (!formData.addressNameOfTheOfficerIncharge || formData.addressNameOfTheOfficerIncharge === '');
    // newFormError.designation = (!formData.designation || formData.designation === '') ;
    newFormError.state = (!formData.state || formData.state === '');
    newFormError.district = (!formData.district || formData.district === '');
    newFormError.section = (!formData.section || formData.section === '');
    newFormError.typeOfInstitute = (!formData.typeOfInstitute || formData.typeOfInstitute === '');
    newFormError.city = (!formData.city || formData.city === '');
    // newFormError.phoneNumberOfOfficerIncharge = !mobileNoRegex.test(formData.phoneNumberOfOfficerIncharge);

    if(
        (formData.categoryOfInstitution === 'POLYTECHNICS' && formData.typeOfInstitute !== 'Diploma') || 
        (formData.categoryOfInstitution !== 'POLYTECHNICS' && formData.typeOfInstitute === 'Diploma') ||
        (formData.categoryOfInstitution !== 'STAND ALONE INSTITUTES (MBA / MCA / PHARMACY / ARCHITECT / HMCT ETC.)' && formData.typeOfInstitute==='MBA-MCA')
    ){
        newFormError.categoryOfInstitution = true;
        newFormError.typeOfInstitute = true;
    // newFormError.dateOfSubmission = (!formData.dateOfSubmission);
    }
    return newFormError;
}

export default validation;