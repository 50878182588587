import React, { useState, useEffect } from 'react'
import {
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
  MenuItem,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  Chip,
} from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import CircularProgress from '@material-ui/core/CircularProgress'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import CachedIcon from '@material-ui/icons/Cached'

import PaymentDialog from '../PaymentDialog/PaymentDialog'
import useStyles from './styles'
import { getPayments, updateOrderStatus, deletePayment } from '../../api'
import DownloadReceiptDialog from './DownloadReceipts'
import DownloadDetailsDialog from './DownloadDetails'

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email of Convener' },
  { id: 'amount', label: 'Amount' },
  { id: 'tracking_id', label: 'Tracking ID' },
  { id: 'transaction_date', label: 'Transaction Date' },
  { id: 'order_status_desc', label: 'Order Status Description' },
  { id: 'order_status', label: 'Order Status' },
  { id: 'view', label: 'View' },
  { id: 'openapplication', label: '' },
]

const AllPayments = () => {
  const [searchParameters, setSearchParameters] = useState({})
  const [payments, setPayments] = useState([])
  const history = useHistory()
  let { page } = useParams()
  const [next, setNextPage] = useState(null)
  const [prev, setPrevPage] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const handleLoading = (value) => {
    setLoading(value)
  }
  const classes = useStyles()
  const handleSearchParameterChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      [e.target.name]: e.target.value,
    })
  }
  const getData = async () => {
    try {
      setLoading(true)
      console.log(searchParameters)
      const data = await getPayments({ ...searchParameters, page: page })
      console.log(data)
      if (data?.status === 200) {
        setPayments(data?.data?.results.results)
        setNextPage(data?.data?.results.next?.page)
        setPrevPage(data?.data?.results.previous?.page)
        setCount(data?.data?.results.count)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateOrderStatusAndFetchData = async () => {
    try {
      await updateOrderStatus()
      await getData()
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [page])
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={6}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard">
              Dashboard
            </Link>
            <Typography color="textPrimary">All Payments</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Paper elevation={5} className={classes.root}>
            <Typography variant="h6">Count : {count}</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12} align="right">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={updateOrderStatusAndFetchData}
                >
                  <CachedIcon /> Update Order Status
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="student_chapter_code"
                  label="Chapter Code"
                  fullWidth
                  value={searchParameters.student_chapter_code}
                  onChange={handleSearchParameterChange}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="receipt_no"
                  label="Receipt No"
                  value={searchParameters.receipt_no}
                  onChange={handleSearchParameterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="email"
                  label="Email Address"
                  value={searchParameters.email}
                  onChange={handleSearchParameterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="membership_type"
                  label="Membership Type"
                  value={searchParameters.membership_type}
                  onChange={handleSearchParameterChange}
                  select
                  fullWidth
                >
                  {[
                    { name: 'Life Membership', value: 'lifetime membership' },
                    {
                      name: 'Institute Membership',
                      value: 'institute membership',
                    },
                    { name: 'Student Membership', value: 'student membership' },
                  ].map((type, idx) => (
                    <MenuItem value={type.value}>{type.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="order_status"
                  label="Order Status"
                  value={searchParameters.order_status}
                  onChange={handleSearchParameterChange}
                  fullWidth
                  select
                >
                  {['Completed', 'Not Completed'].map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="_id"
                  label="Order ID"
                  value={searchParameters._id}
                  onChange={handleSearchParameterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="bank_ref_no"
                  label="Bank Ref No"
                  value={searchParameters.bank_ref_no}
                  onChange={handleSearchParameterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="tracking_id"
                  label="Tracking ID"
                  value={searchParameters.tracking_id}
                  onChange={handleSearchParameterChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format="dd/MM/yyyy"
                  name="startDate"
                  fullWidth
                  value={searchParameters.startDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => {
                    setSearchParameters({
                      ...searchParameters,
                      startDate: date,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="To"
                  name="endDate"
                  format="dd/MM/yyyy"
                  fullWidth
                  value={searchParameters.endDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => {
                    setSearchParameters({ ...searchParameters, endDate: date })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="startReceiptNo"
                  label="Start Receipt No"
                  value={searchParameters.startReceiptNo}
                  onChange={handleSearchParameterChange}
                  helperText="Enter Only The Number. Example if your receipt no is LM-O-0001 enter only 0001"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <TextField
                  variant="outlined"
                  name="endReceiptNo"
                  label="End Receipt No"
                  value={searchParameters.endReceiptNo}
                  onChange={handleSearchParameterChange}
                  helperText="Enter Only The Number. Example if your receipt no is LM-O-0001 enter only 0001"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} align="center">
                <Button variant="contained" color="primary" onClick={getData}>
                  <SearchIcon /> Search
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <DownloadReceiptDialog
                  count={payments?.length || 0}
                  searchParameters={searchParameters}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <DownloadDetailsDialog
                  count={payments?.length || 0}
                  searchParameters={searchParameters}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={5} className={classes.root}>
            {loading ? (
              <div align="center" className={classes.loading}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.map((row) => {
                        return (
                          <TableRow
                            key={row._id}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell>
                              {`${row.first_name} ${row.last_name}`}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.tracking_id}</TableCell>
                            <TableCell>{row.transaction_date}</TableCell>
                            <TableCell>{row.order_status_desc}</TableCell>
                            <TableCell>
                              {row.order_status ? (
                                <Chip label="Completed" color="primary" />
                              ) : (
                                <Chip label="Not Completed" color="secondary" />
                              )}
                            </TableCell>
                            <TableCell>
                              <PaymentDialog value={row} />
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  if (
                                    row.membership_type ===
                                    'institute membership'
                                  ) {
                                    window.open(`/dashboard/institute-membership-applications/${row.membership_id}`, '_blank');
                                  } else if (
                                    row.membership_type === 'student membership'
                                  ) {
                                    window.open(`/dashboard/student-membership-applications/students/${row.membership_id}`, '_blank');
                                  } else if (
                                    row.membership_type ===
                                    'lifetime membership'
                                  ) {
                                    window.open(`/dashboard/life-time-membership-applications/${row.membership_id}`, '_blank');
                                  }
                                }}
                              >
                                Open
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box align="right">
                  <Button to={`${prev}`} component={Link} disabled={!prev}>
                    <NavigateBeforeIcon />
                  </Button>
                  <Button to={`${next}`} component={Link} disabled={!next}>
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default AllPayments
