import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';

import {
    Grid,
    Paper,
    TextField,
    CssBaseline,
    Typography,
    Divider,
    Button,
    Link,
    Box
} from '@material-ui/core';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import {getPaymentDetails,downloadReceipt} from '../../api/payment';
import {BASE_URL} from '../../constants/actionTypes'
import useStyles from './styles';



const PaymentSuccess = () => {
    const { order_id } = useParams();
    const [paymentData, setPaymentData] = useState({
        first_name : '',
        last_name : '',
        email : '',
        _id : '',
        membership_type : '',
        amount : 0
    });
    const classes = useStyles();
    const getData = async ()=>{
        try{
            const data = await getPaymentDetails({order_id});
            console.log(data?.data?.data);
            setPaymentData(data?.data?.data);
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        getData();
    },[])
    return (
        <>
            <FormHeader />
            <CssBaseline />
            <main className={classes.layout}>    
               		<Paper  className={classes.paper}>
                        <Box border={1} style={{padding : '10px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="center" variant="h5" component="h5">
                                        <b>Payment Successfully Completed</b> 
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        First Name : {paymentData.first_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Last Name : {paymentData.last_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Email : {paymentData.email}
                                    </Typography>
                                </Grid>
                               
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Amount : {paymentData.amount} INR
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Membership Type : {paymentData.membership_type}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Transaction Date : {paymentData.transaction_date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Payment Mode : {paymentData.payment_mode}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Order Id : {paymentData._id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Bank Reference Number : {paymentData.bank_ref_no}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Tracking ID : {paymentData.tracking_id}
                                    </Typography>
                                </Grid>
                                
                                <TextField
                                    value={paymentData._id}
                                    name='order_id'
                                    style={{display : 'none'}}
                                >
                                </TextField>
                                {/* <Grid item xs={12} sm={12} lg={12} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        href = {`${BASE_URL}/payment/download-payment-receipt`}
                                    >Download Payment Receipt</Button>
                                </Grid> */}
                            </Grid>
                        </Box>
					</Paper>
            </main>
            <KJSIEIT />
        </>
    )
}

export default PaymentSuccess;
