import React, { useState, useEffect } from "react";
import InstituteAndConvenerForm from "./InstituteAndConvenerForm";
import DataGridDemo from "./TablePreview";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import BackupIcon from "@material-ui/icons/Backup";
import AddIcon from "@material-ui/icons/Add";

// import { XGrid } from '@material-ui/x-grid';
import {
  Grid,
  Paper,
  CssBaseline,
  Typography,
  Divider,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Box,
  Chip,
} from "@material-ui/core";
import useStyles from "./styles";
import { initialStateOfInstituteAndConvener } from "./initialStates";
import {
  studentRegistration,
  getAllStudentSections,
} from "../../api/registrationForm";
import {
  studentValidation,
  instituteValidation,
  studentListValidation,
  checkForDuplicates
} from "./validation";
import StudentForm from "./StudentForm";
import FormAlertDialog from "../FormAlertDialog/FormAlertDialog";
// import sheet from "../../assets/excelsheet/Template.xlsm";
// import Template from "../../assets/excelsheet/"

const StudentMembershipForm = () => {
  const classes = useStyles();

  const [studentData, setStudentData] = useState({});
  const [signature, setSignature] = useState(null);
  const [institute, setInstitute] = useState(
    initialStateOfInstituteAndConvener
  );
  const [institutionError, setInstitutionError] = useState({});
  const [studentDataError, setStudentDataError] = useState({});
  const [backDrop, setBackDrop] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [sections, setSections] = useState([]);
  // const [states, setStatesData] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [studentTable, setStudentTable] = useState([]);
  const [preview, setPreview] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
    window.location.reload();
  };

  const closeBackDrop = () => {
    setBackDrop(false);
  };
  const toggleBackDrop = () => {
    setBackDrop(!backDrop);
  };

  const handleStudentCSVData = (
    fullname,
    duration,
    branch,
    mobile_no,
    email,
    gender
  ) => {
    const data = {
      fullname: fullname?.substring(0, 22),
      duration,
      branch,
      email: email?.trim(),
      mobile_no: mobile_no?.toString().substring(0, 10),
      gender,
    };
    console.log(typeof data.mobile_no);
    return data;
  };
  const handleSignatureUpload = (e) => {
    setSignature(e.target.files[0]);
  };
  const handleSheetUpload = (e) => {
    try {
        const files = e.target.files;
        if (!files.length) return; // Check if any file is selected
        const f = files[0];
        const reader = new FileReader();

        const emailSet = new Set();
        const phoneSet = new Set();
        const students = [];
        let hasError = false;
        let firstErrorMessage = ''; // Store only the first error message

        reader.onload = function (event) {
            const data = event.target.result;
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

            // Check if there is at least one entry in the data (after headers)
            if (dataParse.length <= 1) {
                toast.error('No data found after headers.', {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }

            for (let i = 1; i < dataParse.length; i++) { // Start from 1 to skip header
                const student = dataParse[i];

                try {
                    const fullName = student[0]; // Full Name in column A
                    const durationMembership = student[1]; // Duration of Membership in column B
                    const branch = student[2]; // Branch in column C
                    const phone = student[3]; // Phone Number in column D
                    const email = student[4]; // Email Address in column E
                    const gender = student[5]; // Gender in column F
                    const lineNumber = i + 1; // Line number for error messages

                    // Check for missing email
                    if (!email || typeof email !== 'string' || email.trim() === '') {
                        hasError = true;
                        firstErrorMessage = `Email is missing on line ${lineNumber}`;
                        break;
                    }
                    
                    // Check for missing phone
                    if (!phone || typeof phone !== 'number') {
                        hasError = true;
                        firstErrorMessage = `Phone number is missing on line ${lineNumber}`;
                        break;
                    }

                    // Check for duplicate emails
                    if (emailSet.has(email)) {
                        hasError = true;
                        firstErrorMessage = `Duplicate email id on line ${lineNumber}: ${email}`;
                        break;
                    } else {
                        emailSet.add(email);
                    }

                    // Check for duplicate phone numbers
                    if (phoneSet.has(phone)) {
                        hasError = true;
                        firstErrorMessage = `Duplicate phone number on line ${lineNumber}: ${phone}`;
                        break;
                    } else {
                        phoneSet.add(phone);
                    }

                    // Add validated student data to the students array
                    students.push(handleStudentCSVData(fullName, durationMembership, branch, phone, email, gender));

                } catch (error) {
                    console.error(error.message);
                }
            }

            // Show first error or success message
            if (hasError) {
                toast.error(firstErrorMessage, {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                console.log(students)
                setStudentTable(students);
                toast.success('File uploaded successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            // Reset the input field to allow re-uploading the same file
            e.target.value = ''; // Reset the file input
        };

        reader.readAsBinaryString(f);

    } catch (error) {
        console.error(error);
        toast.info('Please upload a valid .xlsx file', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};

  const handleStudentDataChange = (event) => {
    setStudentData({ ...studentData, [event.target.name]: event.target.value });
  };
  const handleInstituteDataChange = (event) => {
    console.log(institute);
    setInstitute({ ...institute, [event.target.name]: event.target.value });
  };

  const handleStudentDataUpdate = (idx, value) => {
    console.log("here");
    const newStudentDataTable = [...studentTable];
    console.log(idx, value);
    if (idx < newStudentDataTable.length) {
      newStudentDataTable[idx] = value;
    }
    setStudentTable(newStudentDataTable);
  };

  

  
  
  const handleStudentSubmit = () => {
    const { newFormError, isValid } = studentValidation(studentData);
    setStudentDataError(newFormError);
    if (!isValid) return;

    // Check for duplicates before updating the table
    const isDuplicate = checkForDuplicates(studentData, studentTable);

    if (isDuplicate) {
        // alert("Duplicate entry found! Please correct the data.");
        toast.error("Duplicate e-mail id or mobile number found");
        return; // Exit if duplicate is found
    }

    // If no duplicate, add the student to the table
    setStudentTable(prevTable => [...prevTable, studentData]);
};

  const deleteStudent = (indexOfElement) => {
    console.log(indexOfElement);
    const newStudentTable = studentTable.filter(
      (value, index) => index !== indexOfElement
    );
    setStudentTable(newStudentTable);
  };
  const handleNextClick = async () => {
    const { newFormError, isValid } = instituteValidation(institute);
    const isStudentsInValid = studentListValidation(studentTable);

    setInstitutionError(newFormError);
    if (!isValid || !signature || isStudentsInValid) {
      toast.info(
        "Please Enter Correct Details and Upload Signature\nAdd all the details of students",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    setPreview(true);
  };
  const submitApplication = async () => {
    try {
      const formData = new FormData();
      for (const field in institute) {
        if (institute[field] !== "") {
          formData.append(field, institute[field]);
        }
      }
      formData.append("student_applications", JSON.stringify(studentTable));
      formData.append("signature", signature);
      const data = await studentRegistration(formData);
      console.log(data);
      if (data?.status === 201) {
        openDialog();
      }
    } catch (error) {
      console.log(error);
      toast.info(
        "Something went wrong!\nMake Sure you have added the correct student chapter code and institutional membership id",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const getSections = async () => {
    try {
      const data = await getAllStudentSections();
      if (data?.status === 200) {
        setSections(data?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  


 const downloadXlsxFile = (fileName) => {
  const link = document.createElement("a");
  link.download = `Template.xlsx`;
  link.href = "../../assets/excelsheet/sheet.xlsx";
  link.click();
};


  const downloadMembershipManual = () => {
    const link = document.createElement("a");
    link.download = `ISTE-STUDENT-MEMBERSHIP-MANUAL.pdf`;
    link.href = `../../assets/ISTE-STUDENT-MEMBERSHIP-MANUAL.pdf`;
    link.click();
  };
  useEffect(() => {
    getSections();
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        {!preview ? (
          <Paper className={classes.paper} elevation={5}>
            <Grid container className={classes.root} spacing={5}>
              <Grid item xs={12}>
                <Typography
                  className={classes.formTitle}
                  variant="h5"
                  align="center"
                >
                  APPLICATION FOR STUDENT MEMBERSHIP
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                <Button variant="outlined" onClick={downloadMembershipManual}>
                  Download Student Membership Application Manual
                </Button>
              </Grid>
              <Grid item xs={12}>
                <InstituteAndConvenerForm
                  institute={institute}
                  handleChange={handleInstituteDataChange}
                  institutionError={institutionError}
                  signature={signature}
                  handleSignatureUpload={handleSignatureUpload}
                  sections={sections}
                  // states={states}
                  setInstitute={setInstitute}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <input
                    className={classes.uploadButton}
                    id="contained-button-file6"
                    name="sheetUpload"
                    type="file"
                    onChange={handleSheetUpload}
                    accept=".xlsx"
                  />
                  <label htmlFor="contained-button-file6">
                    <Box display="flex">
                     
                      <Button size="small" variant="outlined" component="span">
                        <BackupIcon className={classes.buttonIcon} /> Upload
                        Students List (.xlsx)
                      </Button>
                      <Typography>&nbsp;&nbsp;</Typography>
                      <Button
                       onClick={() => downloadXlsxFile('Template.xlsx')}
                        size="small"
                        variant="outlined"
                      >
                        {/* <Button size="small" variant="outlined" component="span"> */}
                        Download Excel Format
                      </Button>
                      <Typography>&nbsp;&nbsp;</Typography>
                    </Box>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                <StudentForm
                  student={studentData}
                  handleStudentDataChange={handleStudentDataChange}
                  setStudentData={setStudentData}
                  handleSheetUpload={handleSheetUpload}
                  studentDataError={studentDataError}
                />

                <div align="center">
                  <Box m={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleStudentSubmit}
                    >
                      <AddIcon /> Add Student
                    </Button>
                  </Box>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={8} xl={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DataGridDemo
                      studentTable={studentTable}
                      deleteStudent={deleteStudent}
                      handleStudentDataUpdate={handleStudentDataUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography component="h6" variant="h6">
                  Institute and Convener Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b>First Name of Convener :</b> {institute.first_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b>Last Name of Convener : </b>
                  {institute.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Email of Convener : </b>
                  {institute.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Membership Id of Convener :</b> {institute.membership_id}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Institute Membership ID : </b>
                  {institute.institute_membership_id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Name of ISTE Chapter: </b>
                  {institute.name_of_iste_chapter}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Section : </b>
                  {institute.section_code}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle1">
                                    <b> Department: </b>{institute.department   }
                                    </Typography>
                                </Grid> */}
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Address of Institution:</b>{" "}
                  {institute.address_of_institution}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Pincode: </b>
                  {institute.pincode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Mobile No: </b>
                  {institute.mobile_no}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Email of Institution: </b>
                  {institute.email_of_institution}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Typography component="subtitle1">
                  <b> Student Chapter Code: </b>
                  {institute.student_chapter_code}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle1">
                                    <b>  State: </b>{institute.state}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Typography component="subtitle1">
                                    <b>Section Code:</b> {institute.section_code}
                                    </Typography>
                                </Grid> */}
              <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                  Student Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <DataGridDemo
                  studentTable={studentTable}
                  deleteStudent={deleteStudent}
                  handleStudentDataUpdate={handleStudentDataUpdate}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setPreview(false);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} align="center">
                <FormAlertDialog
                  title="Submit Student Membership Form"
                  handleSubmit={submitApplication}
                />
                {/* <Button variant="contained" color="primary" onClick={submitApplication}>Submit Application</Button> */}
              </Grid>
            </Grid>
          </Paper>
        )}
      </main>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={closeBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={dialog}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Student Registration Form Successfully Submiited"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You form have been submitted. Check your email for confirmation
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default StudentMembershipForm;
