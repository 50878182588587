import React,{useState,useEffect} from 'react';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Paper,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    MenuItem,
    InputLabel,
    Button,
    Select
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { 
    getAllStates, 
    getAllDistricts,
    addState ,
    updateState,
    deleteState,
    addDistrict,
    updateDistrict,
    deleteDistrict
} from '../../api';
import States from '../../components/States/States';
import Districts from '../../components/Districts/Districts';
import useStyles from './styles';

const StatesAndDistricts = () => {
    const classes = useStyles();
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtQuery, setDistrictQuery] = useState();
    const [stateData, setStateData] = useState(null);
    const [districtData, setDistrictData] = useState({});

    const handleStateDataChange = (e)=>{
        setStateData(e.target.value);
    }
    const handleDistrictDataChange = (e)=>{
        setDistrictData({...districtData, [e.target.name]:e.target.value});
        console.log(districtData);
    }
    const handleDistrictQueryChange = async (value)=>{
        setDistrictQuery({state:value});
        
    }
    const getStates = async ()=>{
        try{
            const data = await getAllStates();
            console.log(data);
            if(data?.status === 200){
                setStates(data?.data?.result);
            }
        }catch(error){
            console.log(error);
        }
    }
    const getDistricts = async ()=>{
        try{
            const data = await getAllDistricts(districtQuery);
            if(data?.status === 200){
                setDistricts(data?.data?.result);
            }
        }catch(error){
            console.log(error);
        }
    }
    const addNewState = async ()=>{
        try{
            const data = await addState({name:stateData});
            console.log(data);
            if(data?.status === 201){
                console.log(data?.data);
                toast('Stated Added!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getStates();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const addNewDistrict = async ()=>{
        try{
            const data = await addDistrict(districtData);
            console.log(data);
            if(data?.status === 201){
                console.log(data?.data);
                toast('District Added!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getDistricts();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleUpdateState = async (value)=>{
        console.log(value);
        try{
            const data = await updateState(value);
            if(data?.status === 200){
                console.log(data?.data);
                toast('State Updated!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getStates();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleDeletState = async (value)=>{
        try{
            const data = await deleteState(value);
            if(data?.status === 200){
                console.log(data?.data);
                toast('State Deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getStates();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleUpdateDistrict = async (value)=>{
        try{
            const data = await updateDistrict(value);
            if(data?.status === 200){
                console.log(data?.data);
                toast('District Updated!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getDistricts();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleDeleteDistrict = async (value)=>{
        try{
            const data = await deleteDistrict(value);
            if(data?.status === 200){
                console.log(data?.data);
                toast('District Updated!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getDistricts();
            }
        }catch(error){
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(()=>{
        getStates();
        getDistricts();
    }, []);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">States and Districts</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
                <Paper className={classes.paper} elevation={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <TextField
                                label="Name of State"
                                variant="outlined"
                                fullWidth
                                name="state"
                                value={stateData}
                                onChange={handleStateDataChange}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addNewState}
                            >
                                Add State
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={8} xl={8}>
                <Paper className={classes.paper} elevation={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                label="Name of District"
                                variant="outlined"
                                fullWidth
                                name="name"a
                                value={districtData?.name}
                                onChange={handleDistrictDataChange}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Age"
                                name="state"
                                value={districtData?.state}
                                onChange={handleDistrictDataChange}
                                >
                                    {
                                        states.map((value,idx)=>(
                                        <MenuItem value={value._id}>
                                            {value.name}
                                        </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addNewDistrict}
                            >
                                Add District
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={5} xl={5}>
                <States 
                    states={states} 
                    handleDistrictQueryChange={handleDistrictQueryChange}
                    handleUpdateState={handleUpdateState}
                    handleDeletState={handleDeletState}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={7} xl={7}>
                <Districts 
                    districts={districts} 
                    states={states} 
                    districtQuery={districtQuery}
                    handleDeleteDistrict={handleDeleteDistrict}
                    handleUpdateDistrict={handleUpdateDistrict}
                    handleDistrictQueryChange={handleDistrictQueryChange}
                />
            </Grid>
        </Grid>
    )
}

export default StatesAndDistricts;
