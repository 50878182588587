import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    root: {
      width: '100%',
      padding : theme.spacing(3),
      borderRadius : theme.spacing(4)
    },
    container: {
      minHeight: 500,
    },
    loading : {
      padding : theme.spacing(10)
    },
    box : {
      padding : theme.spacing(2)
    },
    searchButton : {
      padding : theme.spacing(2)
    },
    buttonPadding : {
      margin : theme.spacing(1)
    }
  }));