import React,{useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Collapse,Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Link , useRouteMatch, useParams} from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useStyles from './styles';
import { getLifeTimeRejectedApplications } from '../../api'; 


const columns = [
  { id: 'name', label: 'Full Name'},
  { id: 'email', label: 'Email' },
  { id: 'membershipCategory',label: 'Membership Category'},
  { id: 'recommendersApproval', label: 'Recommender\'s Approval'},
  { id: 'recommendersMembershipNo', label: 'Recommender\'s Membership Number'},
  { id: 'view', label: 'View'},
];

export default function LifeTimeApplicationsRejectedTable()  {
  const classes = useStyles();
  let { page } = useParams();
  const [loading,setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const [lifeTimeMembers ,setLifeTimeMembers] = useState([]);
  const [next,setNextPage] = useState(null);
  const [prev,setPrevPage] = useState(null);

  const handleLoading = (value)=>{
    setLoading(value);
  }

  const prevPage = (val)=>{
    setPrevPage(val);
  }
  const nextPage = (val)=>{
    setNextPage(val);
  }
  const getData = async ()=>{
    setLoading(true);
    const data = await getLifeTimeRejectedApplications({page : page, limit : 10, rejected : true});
    if(data?.data?.result){
      console.log(data?.data?.result);
      setLifeTimeMembers(data?.data?.result?.results);
      nextPage(data?.data?.result?.next?.page)
      prevPage(data?.data?.result?.previous?.page)
      handleLoading(false);
    }
  }
  // getData();
  useEffect(()=>{
    getData();

  },[page]);
  // getData();

  return (
    <Paper className={classes.root}>
      { loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) :  (
          <>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lifeTimeMembers.map((row) => {
                return (
                  <TableRow key={row._id} hover role="checkbox" tabIndex={-1} key={row.code}>
                      <TableCell >
                            {`${row.first_name} ${row.last_name}`}
                      </TableCell>
                      <TableCell>
                            {row.email}
                      </TableCell>
                      <TableCell>
                            {row.membership_category}
                      </TableCell>
                      <TableCell>
                            { row.recommenders_approval ? 
                              <Chip color="primary" label="Approved"></Chip> : 
                                row.recommenders_rejected ? 
                                <Chip color="secondary" label="Rejected"></Chip> :
                                 <Chip color="secondary"  label="Pending"></Chip>
                            }
                      </TableCell>
                      <TableCell>
                            {`${row.recommenders_membership_no}`}
                      </TableCell>
                      <TableCell>
                          <Button 
                          size="small"
                          variant="contained" 
                          color="primary" 
                          component={Link} 
                          to={`/dashboard/life-time-membership-applications/${row._id}`}>
                            {'Open'}
                          </Button>
                      </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box align="right">
          <Button  to={`${prev}`} component={Link} disabled={!prev}><NavigateBeforeIcon /></Button>
          <Button  to={`${next}`} component={Link} disabled={!next}><NavigateNextIcon /></Button>
        </Box>
        </>
        )}
    </Paper>
  );
}
