import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, TextField, MenuItem } from "@material-ui/core";
import { studentValidation } from "./validation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 1000,
  },
  textField: {
    marginTop: theme.spacing(1),
  },
}));

const Row = ({ row, index, deleteStudent, handleStudentDataUpdate, page }) => {
  const [edit, setEdit] = useState(false);
  const [student, setStudent] = useState(row);
  const [studentError, setStudentError] = useState({});
  const handleChange = (e) => {
    setStudent({ ...student, [e.target.name]: e.target.value });
  };

  const handleUpdate = () => {
    const { isValid, newFormError } = studentValidation(student);
    setStudentError(newFormError);
    if (!isValid) return;
    handleStudentDataUpdate(page * 20 + index, student);
  };

  useEffect(() => {
    const { isValid, newFormError } = studentValidation(row);

    setStudentError(newFormError);
    if (!isValid) {
      setEdit(true);
    }
  }, []);

  return (
    <>
      {edit ? (
        <TableRow hover key={index} role="checkbox" tabIndex={-1}>
          <TableCell>{page * 20 + index + 1}</TableCell>
          <TableCell>
            <TextField
              size="small"
              name="fullname"
              variant="outlined"
              error={studentError.fullname}
              label="Full Name"
              value={student.fullname}
              onChange={handleChange}
            ></TextField>
          </TableCell>
          <TableCell>
            <TextField
              size="small"
              name="email"
              variant="outlined"
              error={studentError.email}
              label="Email"
              value={student.email}
              onChange={handleChange}
            ></TextField>
          </TableCell>
          <TableCell>
            <TextField
              size="small"
              name="duration"
              variant="outlined"
              error={studentError.duration}
              label="Duration"
              value={student.duration}
              onChange={handleChange}
            ></TextField>
          </TableCell>
          <TableCell>
            <TextField
              size="small"
              name="branch"
              variant="outlined"
              error={studentError.branch}
              label="Branch"
              value={student.branch}
              onChange={handleChange}
            ></TextField>
          </TableCell>
          <TableCell>
            <TextField
              required
              name="mobile_no"
              label="Mobile No. "
              error={studentError.mobile_no}
              helperText={studentError.mobile_no && "Invalid Entry"}
              value={student.mobile_no}
              onChange={handleChange}
              fullWidth
              size="small"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <TextField
              required
              name="gender"
              label="Gender"
              error={studentError.gender}
              helperText={studentError.gender && "Please Select Gender"}
              value={student.gender}
              onChange={handleChange}
              fullWidth
              size="small"
              variant="outlined"
              select
            >
              {[
                { value: "M", name: "Male" },
                { value: "F", name: "Female" },
                { value: "O", name: "Others" },
              ].map((option) => (
                <MenuItem key={option._id} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>

          <TableCell>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleUpdate}
            >
              <SaveIcon />
            </Button>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => deleteStudent(page * 20 + index)}
            >
              <DeleteIcon />
            </Button>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow key={index} role="checkbox">
          <TableCell>{page * 20 + index + 1}</TableCell>
          <TableCell>{row.fullname}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.duration}</TableCell>
          <TableCell>{row.branch}</TableCell>
          <TableCell>{row.mobile_no}</TableCell>
          <TableCell>{row.gender}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setEdit(true);
              }}
            >
              <EditIcon />
            </Button>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => deleteStudent(page * 20 + index)}
            >
              <DeleteIcon />
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default function StickyHeadTable({
  studentTable,
  deleteStudent,
  handleStudentDataUpdate,
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Mobile No</TableCell>
              <TableCell>Gender</TableCell>

              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentTable.slice(page * 20, page * 20 + 20).map((row, index) => {
              return (
                <Row
                  key={index + Math.random()}
                  row={row}
                  index={index}
                  deleteStudent={deleteStudent}
                  handleStudentDataUpdate={handleStudentDataUpdate}
                  page={page}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        page={page}
        rowsPerPage={20}
        count={studentTable.length}
        onChangePage={handleChangePage}
      />
    </Paper>
  );
}
