import React,{useState,useEffect} from 'react';
import {
    Paper,
    CircularProgress,
    Box,
    Grid,
    TextField,
    Button,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { Link , useRouteMatch, useParams} from 'react-router-dom';
import useStyles from './styles';
import {
  getAllChapters
} from '../../api/index';
import axios from 'axios';
import moment from 'moment';
import BASE_URL from '../../api/constants';
import FacultyChapterFormDialog from '../FacultyChapterFormDialog/FacultyChapterFormDialog';

const columns = [
    { id: 'chapterCode', label: 'Chapter Code'},
    { id: 'codeNumber', label:'Code Number'},
    { id: 'sectionCode',label: 'Section Code'},
    {id : 'instituteName', label : 'Name'},
    { id: 'instituteState', label: 'State' },
    { id: 'instituteType',label: 'Type'},
    {id : 'address', label : 'Address'},
    {id : 'view', label : 'view'},
];



const FacultyChaptersTable = () => {
    const [searchParameters, setSearchParameters] = useState({});
    const [loading,setLoading] = useState(false);
    let { page } = useParams();
    const [chapters, setChapters] = useState([]);
    const [count,setCount] = useState(0);
    const [next,setNextPage] = useState(null);
    const [prev,setPrevPage] = useState(null);
    let { path, url } = useRouteMatch();
    const handleChange = (e)=>{
        setSearchParameters({...searchParameters, [e.target.name]:e.target.value});
    }
    const searchMembers = ()=>{
      getData();
      
    }
    const generateReport = ()=>{
      try{

        axios({
          url: `${BASE_URL}/api/faculty-chapters/reports`, //your url
          method: 'GET',
          headers : {
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
          },
          params : {...searchParameters},
          responseType: 'blob', // important
          }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Faculty Chapters Report - exported at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.xlsx`); //or any other extension
              document.body.appendChild(link);
              link.click();
          });
  
      }catch(error){
        console.log(error);
      }
    }
    const classes = useStyles();
    const getData = async ()=>{
      setLoading(true);
      try{
        const data = await getAllChapters({...searchParameters,page : page, limit : 100});
        console.log(data?.data?.result);
        setChapters(data?.data?.result?.results);
        setNextPage(data?.data?.result?.next?.page);
        setCount(data?.data?.result?.count);
        setPrevPage(data?.data?.result?.previous?.page);
        setLoading(false);
      }catch(error){
        console.log(error);
      }
    }
    useEffect(()=>{
      getData();
    },[page]);

    return (
        <Paper className={classes.root}>
      { loading ? (
        <div align="center" className={classes.loading}>
          <CircularProgress />
        </div>
      ) :  (
          <>
        <Box elevation={2} className={classes.box}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="h6">Total Count : {count}</Typography>
              </Grid>
              <Grid item  sm={12} lg={8}>
                <TextField
                label = "Name of Institute"
                variant="outlined"
                name="name_of_institution"
                value={searchParameters.name_of_institution}
                onChange={handleChange}
                fullWidth
                >
                </TextField>
              </Grid>
                <Grid item  sm={12} lg={2}>
                  <TextField
                  label = "Section Code"
                  variant="outlined"
                  name="section_code"
                  onChange={handleChange}
                  value={searchParameters.section_code}
                  fullWidth
                  >
                  </TextField>
                </Grid>
              <Grid item  sm={12} lg={2}>
                <TextField
                label = "Chapter Code"
                variant="outlined"
                name="chapter_code"
                onChange={handleChange}
                value={searchParameters.chapter_code}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item  sm={12} lg={3}>
                <TextField
                label = "Institute Type"
                variant="outlined"
                name="institute_type"
                onChange={handleChange}
                value={searchParameters.institute_type}
                fullWidth
                >
                </TextField>
              </Grid>
              <Grid item  sm={12} lg={3}>
                <TextField
                label = "State"
                variant="outlined"
                name="state"
                onChange={handleChange}
                value={searchParameters.state}
                fullWidth
                >
                </TextField>
              </Grid>
              
              
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format="dd/MM/yyyy"
                  name="startDate"
                  value={searchParameters.startDate || new Date()}
                  InputAdornmentProps={{ position: "start" }}
                  onChange = {(date)=>{
                    setSearchParameters({...searchParameters, startDate : date});
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <KeyboardDateTimePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="To"
                    name="endDate"
                    format="dd/MM/yyyy"
                    value={searchParameters.endDate || new Date()}
                    InputAdornmentProps={{ position: "start" }}
                    onChange = {(date)=>{
                      setSearchParameters({...searchParameters, endDate : date});
                    }}
                  />
              </Grid>
              <Grid item sm={12} lg={12} align="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={searchMembers}
                  className={classes.buttonPadding}
                  
                >
                  <SearchIcon/> Search
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={generateReport}
                  className={classes.buttonPadding}
                  mt={2}
                >
                  <AssessmentIcon/> Generate Report
                </Button>
                
              </Grid>
          </Grid>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                chapters.map((value,idx)=>{
                  return (
                    <TableRow key={value._id}>
                      <TableCell>
                        {value.chapter_code}
                      </TableCell>
                      <TableCell>
                        {value.code_number}
                      </TableCell>
                      <TableCell>
                        {value.section_code}
                      </TableCell>
                      <TableCell>
                        {value.institute_name}
                      </TableCell>
                      <TableCell>
                        {value.state}
                      </TableCell>
                      <TableCell>
                        {value.institute_type}
                      </TableCell>
                      <TableCell>
                        {value.institute_address + " " + value.institute_address2}
                      </TableCell>
                      <TableCell>
                        <FacultyChapterFormDialog value={value}/>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Box align="right">
          <Button  to={`${prev}`} component={Link} disabled={!prev}><NavigateBeforeIcon /></Button>
          <Button  to={`${next}`} component={Link} disabled={!next}><NavigateNextIcon /></Button>
        </Box>
        </>
        )}
    </Paper>
    )
}

export default FacultyChaptersTable;
