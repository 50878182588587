import { makeStyles } from "@material-ui/core";
import { green } from '@material-ui/core/colors';


export default makeStyles((theme)=>({
    paper: {
        padding : theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
          padding : theme.spacing(3)
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    
}));