import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    paper : {
        padding: theme.spacing(2),
        width : theme.spacing(80),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        }, 
    },
    button : {
        padding : theme.spacing(1),
        margin : theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(1),
            margin : theme.spacing(2),
            width : theme.spacing(40)
        }, 
    }
}));