import React,{useState, useEffect} from 'react';
import { BrowserRouter, Switch, Route,useRouteMatch,useParams,Link,useHistory } from 'react-router-dom';
import { Grid,Breadcrumbs,Paper,Typography,CircularProgress,Button } from '@material-ui/core';
import {toast} from 'react-toastify';

import ViewLifeTimeMembershipComponent from '../../components/ViewLifeTimeApplicationComponent/ViewLifeTimeApplicationComponent';
import LifeTimeMembershipAdminForm from '../../components/LifeTimeMembershipAdminForm/LifeTimeMembershipAdminForm';
import { getLifeTimeApplicant,approveLifeTimeMembership,rejectLifeTimeMembership} from '../../api/index';
import useStyles from './styles';

const ViewLifeTimeApplication = () => {
    const classes = useStyles();
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [application,setApplication] = useState({applicationId : applicationId});
    const [adminFormData, setAdminFormData] = useState({note : null});
    let { path, url } = useRouteMatch(); 
    const handleAdminFormDataChange = (event)=>{
        setAdminFormData({...adminFormData, [event.target.name] : event.target.value});
    }
    const handleApplicationDataChange = (e)=>{
        setApplication({...application,[e.target.name]:e.target.value})
    }
    
    const handleApplicationRejection = async ()=>{
        try{
            const data = await rejectLifeTimeMembership({...adminFormData,lifeTimeMemberId : applicationId });
            if(data?.status === 200){
                toast.info('Application Rejected', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/dashboard/life-time-membership-applications');
            }else{
                toast.error(data?.data?.message || 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            toast.error('Something went wrong', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleApplicationApproval = async ()=>{
        try{
            const payload = {
                lifeTimeMemberId : applicationId
            }
            if(adminFormData?.note){
                payload.note = adminFormData?.note;
            }
            const data = await approveLifeTimeMembership(payload);
            if(data?.status === 200){
                toast.success('Application Approved', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/dashboard/life-time-membership-applications');
            }else{
                toast.error(data?.data?.message || 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            toast.error(
                'Something went wrong.', 
            {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getData = async ()=>{
        console.log(application);
        const data = await getLifeTimeApplicant(application?.applicationId);
        console.log(data);
        if(data?.data?.result){
            setApplication(data?.data?.result);
            setLoading(false);
        }
    }

    useEffect(()=>{
        getData(applicationId); 
    },[])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Link color="inherit" to="/dashboard/life-time-membership-applications" >
                        Life Time Membership Applications
                    </Link>
                    <Typography color="textPrimary">{loading ? "Application" : `${application.first_name} ${application.middle_name} ${application.last_name}` }</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={5} className={classes.paper}>
                    {loading ? 
                        <div align="center"><CircularProgress /></div> : 
                        <ViewLifeTimeMembershipComponent 
                            application={application} 
                            handleApplicationDataChange={handleApplicationDataChange}
                            setApplication={setApplication}
                        /> 
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {!loading && application.status==='In Progress'  && 
                    <LifeTimeMembershipAdminForm 
                    application={application} 
                    handleApplicationRejection={handleApplicationRejection}  
                    handleApplicationApproval = {handleApplicationApproval}
                    handleAdminFormDataChange = {handleAdminFormDataChange}
                /> }
            </Grid>
        </Grid>
        
    )
}

export default ViewLifeTimeApplication
