export const studentValidation = (student)=>{
    const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const newFormError = {
        fullname : (!nameRegex.test(student.fullname) || !student.fullname ||student.fullname.length > 22),
        duration : !student.duration || isNaN(student.duration) || student.duration > 5 || student.duration < 1,
        branch : (!student.branch || student.branch === ''),
        mobile_no : !mobileNoRegex.test(student.mobile_no),
        email : !emailRegex.test(student.email),
        gender : !student.gender || student.gender === ''
    }
    var isValid = true;
    for(const field in newFormError){
        if(newFormError[field]){
            isValid = false;
            break;
        }
    }
    return {newFormError, isValid};
}