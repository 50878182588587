import React from 'react';
import {
    Grid,
    Breadcrumbs,
    Link,
    Typography,
    
} from '@material-ui/core';

import FacultyChaptersTable from '../../components/FacultyChaptersTable/FacultyChaptersTable';

const FacultyChapters = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Faculty Chapters</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <FacultyChaptersTable />
            </Grid>
        </Grid>
    )
}

export default FacultyChapters;
