import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 500,
    },
    loading : {
      padding : theme.spacing(10)
    }
  }));