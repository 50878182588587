import React,{useState,useEffect} from 'react'
import {
    Grid,
    Breadcrumbs,
    Typography,
    Paper,
    TextField,
    Button,
    
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { getPaymentData,updatePaymentData } from '../../api';
import { toast } from 'react-toastify';

const initalState = {
    type : '',
    amount : 0,
    gst_percentage : 0,
    total_amount : 0,
    admission_fee : 0
}


const PaymentDetails = () => {
    const classes = useStyles();
    const [student, setStudent] = useState(initalState);
    const [lifetime, setLifetime] = useState(initalState);
    const [institute1, setInstitute1] = useState(initalState);
    const [institute2, setInstitute2] = useState(initalState);
    const [institute3, setInstitute3] = useState(initalState);
    const [institute4, setInstitute4] = useState(initalState);


    const getData = async ()=>{
        try{
            const data = await getPaymentData();
            console.log(data);
            for(const value of data?.data?.payment){
                if(value?.type === 'student'){
                    setStudent(value);
                }else if(value?.type==='lifetime'){
                    setLifetime(value);
                }else if(value?.type === 'institute-type-1'){
                    setInstitute1(value)
                }else if(value?.type === 'institute-type-2'){
                    setInstitute2(value)
                }else if(value?.type === 'institute-type-3'){
                    setInstitute3(value)
                }
                else if(value?.type === 'institute-type-4'){
                    setInstitute4(value)
                }
            }
        }catch(error){
            console.log(error);
        }
    }
    const handleStudentDatChange = (e)=>{
        setStudent({...student, [e.target.name]:e.target.value})
    }
    const handleLifetimeDatChange = (e)=>{
        setLifetime({...lifetime, [e.target.name]:e.target.value})
    }
    const handleInstitute1Change = (e)=>{
        setInstitute1({...institute1,[e.target.name]:e.target.value})
    }
    const handleInstitute2Change = (e)=>{
        setInstitute2({...institute2,[e.target.name]:e.target.value})
    }
    const handleInstitute3Change = (e)=>{
        setInstitute3({...institute3,[e.target.name]:e.target.value})
    }
    const handleInstitute4Change = (e)=>{
        setInstitute4({...institute4,[e.target.name]:e.target.value})
    }
    const handleUpdateData = async (data)=>{
        try{
            let newData = {
                amount : data.amount,
                gst_percentage : data.gst_percentage,
                admission_fee : data.admission_fee,
                type : data.type
            }
            const updatedData = await updatePaymentData(newData);
            if(updatedData.status===200){
                toast.success('Payment data updated', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        getData();
    },[])
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/dashboard" >
                            Dashboard
                        </Link>
                        <Typography color="textPrimary">Payment Details</Typography>
                    </Breadcrumbs>
                </Grid >
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Lifetime Member's Payment Details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    value={lifetime.amount}
                                    onChange={handleLifetimeDatChange}
                                    label="Amount"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    value={lifetime.admission_fee}
                                    onChange={handleLifetimeDatChange}
                                    name="admission_fee"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    value={lifetime.gst_percentage}
                                    onChange={handleLifetimeDatChange}
                                    name="gst_percentage"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(lifetime.admission_fee) + parseInt(lifetime.amount) + 
                                        ((parseInt(lifetime.admission_fee) + parseInt(lifetime.amount))*parseInt(lifetime.gst_percentage))/100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(lifetime);
                                    }}

                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Student's Payment Details (Per Student)</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    value={student.amount}
                                    onChange={handleStudentDatChange}
                                    label="Amount for 1 year"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    value={student.admission_fee}
                                    onChange={handleStudentDatChange}
                                    name="admission_fee"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    value={student.gst_percentage}
                                    onChange={handleStudentDatChange}
                                    name="gst_percentage"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(student.admission_fee) + parseInt(student.amount) + 
                                        ((parseInt(student.admission_fee) + parseInt(student.amount))*parseInt(student.gst_percentage))/100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Chapter Share (50% of basic fee)"
                                    name="chapter_share"
                                    value={student.amount/2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="ISTE HQ Fees"
                                    name="iste_hq_fees"
                                    value={parseInt(student.admission_fee) + parseInt(student.amount) + 
                                        ((parseInt(student.admission_fee) + parseInt(student.amount))*parseInt(student.gst_percentage))/100 - student.amount/2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(student);
                                    }}
                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item  xs={12} sm={12} lg={6}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Institute Members Payment Details (Type 1)</Typography>
                                <Typography>
                                    University / University Department / Engg. Colleges / Science
                                    and technology Institutions / Industrial and professional organisations /
                                    Deptts. of central and state Govts / Statutory bodies / Autonomous bodies
                                    having interest in Technical Education
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    label="Amount"
                                    value={institute1.amount}
                                    onChange={handleInstitute1Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    name="admission_fee"
                                    value={institute1.admission_fee}
                                    onChange={handleInstitute1Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    name="gst_percentage"
                                    value={institute1.gst_percentage}
                                    onChange={handleInstitute1Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(institute1.admission_fee) + parseInt(institute1.amount) + 
                                        ((parseInt(institute1.admission_fee) + parseInt(institute1.amount))*parseInt(institute1.gst_percentage))/100
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(institute1);
                                    }}
                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Institute Members Payment Details (Type 2)</Typography>
                                <Typography>
                                Stand alone institutes (MBA / MCA / Pharmacy / Architect / HMCT etc.)
                                
                                </Typography>
                                <br/>
                                <br/>
                                <br/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    label="Amount"
                                    value={institute2.amount}
                                    onChange={handleInstitute2Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    name="admission_fee"
                                    value={institute2.admission_fee}
                                    onChange={handleInstitute2Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    name="gst_percentage"
                                    value={institute2.gst_percentage}
                                    onChange={handleInstitute2Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(institute2.admission_fee) + parseInt(institute2.amount) + 
                                        ((parseInt(institute2.admission_fee) + parseInt(institute2.amount))*parseInt(institute2.gst_percentage))/100
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(institute2);
                                    }}
                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Institute Members Payment Details (Type 3)</Typography>
                                <Typography>
                                Polytechnics
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    label="Amount"
                                    value={institute3.amount}
                                    onChange={handleInstitute3Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    name="admission_fee"
                                    value={institute3.admission_fee}
                                    onChange={handleInstitute3Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    name="gst_percentage"
                                    value={institute3.gst_percentage}
                                    onChange={handleInstitute3Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(institute3.admission_fee) + parseInt(institute3.amount) + 
                                        ((parseInt(institute3.admission_fee) + parseInt(institute3.amount))*parseInt(institute3.gst_percentage))/100
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(institute3);
                                    }}
                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Paper elevation={5} className={classes.root}>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">Institute Members Payment Details (Type 4)</Typography>
                                <Typography>
                                Corporate Institutional Membership
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    label="Amount"
                                    value={institute4.amount}
                                    onChange={handleInstitute4Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Admission Fee"
                                    name="admission_fee"
                                    value={institute4.admission_fee}
                                    onChange={handleInstitute4Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="GST %"
                                    name="gst_percentage"
                                    value={institute4.gst_percentage}
                                    onChange={handleInstitute4Change}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Total Amount"
                                    name="total_amount"
                                    value={
                                        parseInt(institute4.admission_fee) + parseInt(institute4.amount) + 
                                        ((parseInt(institute4.admission_fee) + parseInt(institute4.amount))*parseInt(institute4.gst_percentage))/100
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{
                                        handleUpdateData(institute4);
                                    }}
                                >
                                     Save  
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                
                
                
                
            </Grid>
        </div>
    )
}

export default PaymentDetails;
