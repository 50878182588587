export const studentValidation = (student)=>{
    const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const newFormError = {
        fullname : (!nameRegex.test(student.fullname) || !student.fullname ||student.fullname.length > 22),
        duration : !student.duration || student.duration > 6 || student.duration <= 1,
        branch : (!student.branch || student.branch === ''),
        mobile_no : !mobileNoRegex.test(student.mobile_no),
        email : !emailRegex.test(student.email),
        gender : !student.gender && (student.gender!=='M' || student.gender!=='F' || student.gender!=='O')
    }
    var isValid = true;
    for(const field in newFormError){
        isValid = !newFormError[field] && isValid;
    }
    return {newFormError, isValid};
}

export const checkForDuplicates = (student, table) => {
    // Check if there is any student in the table with the same email or mobile number
    const duplicate = table.some(existingStudent => 
        existingStudent.email === student.email || existingStudent.mobile_no === student.mobile_no
    );
    return duplicate;
};



export const studentListValidation = (students)=>{
    console.log(students);
    if(students?.length===0) return true;
    for(var student of students){
        const {isValid} = studentValidation(student);
        
        if(!isValid){
            return true;
        }
    }
    return false;
}

export const instituteValidation = (institute)=>{
    const nameRegex = /^[a-z ,.'-]+$/i;
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const mobileNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    let student_chapter_code = false;
    if(institute.new_chapter){
        student_chapter_code = !!institute.student_chapter_code
    }else{
        student_chapter_code =  !institute.student_chapter_code || institute.student_chapter_code===''
    }
    const newFormError = {
        first_name : !nameRegex.test(institute.first_name),
        last_name : !nameRegex.test(institute.last_name),
        email : !emailRegex.test(institute.email), 
        email_of_institution : !emailRegex.test(institute.email_of_institution), 
        institute_membership_id : (!institute.institute_membership_id || institute.institute_membership_id===''),
        address_of_institution : (!institute.address_of_institution || institute.address_of_institution===''),
        name_of_iste_chapter : (!institute.name_of_iste_chapter || institute.name_of_iste_chapter===''),
        pincode : !pincodeRegex.test(institute.pincode),
        mobile_no : !mobileNoRegex.test(institute.mobile_no),
        student_chapter_code : student_chapter_code,
        //state : (!institute.state || institute.state===''),
        section_code : (!institute.section_code || institute.section_code===''),
    }
    console.log(newFormError)
    let isValid = true;
    for(const field in newFormError){
        if(newFormError[field]===true){
            isValid = false; 
            break;
        }
    }
    
    return {newFormError, isValid}
}