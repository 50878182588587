import React from 'react';
import { 
    Grid,
    Divider,
    Typography,
    TextField ,
    Box,
    Button,
    MenuItem,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import useStyles from './styles';
import BackupIcon from '@material-ui/icons/Backup';

const InstituteAndConvenerForm = ({ 
    institute,
    handleChange,
    institutionError ,
    handleSignatureUpload,
    signature,
    sections,
    states,
    setInstitute
}) => {
    const classes = useStyles();
    return (
        <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Typography  variant="h5">
                            Institution and Convener Details
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                            <TextField
                                required
                                name= "name_of_iste_chapter"
                                error={institutionError.name_of_iste_chapter}
                                helperText={institutionError.name_of_iste_chapter && 'Invalid Entry'}
                                label= "Name of Institution"
                                value={institute.name_of_iste_chapter}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "institute_membership_id"
                                error={institutionError.institute_membership_id}
                                helperText={`Enter Correct Institutional Member Id. Example :- If your Number is "IM-1324" Enter Only "1324"`}
                                label= "Institution Membership Id"
                                value={institute.institute_membership_id}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "address_of_institution"
                                error={institutionError.address_of_institution}
                                helperText={institutionError.address_of_institution && 'Invalid Entry'}
                                label= "Address of Institution"
                                value={institute.address_of_institution}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "pincode"
                                error={institutionError.pincode}
                                helperText={institutionError.pincode && 'Invalid Entry'}
                                label= "Pincode of Institution"
                                value={institute.pincode}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "email_of_institution"
                                error={institutionError.email_of_institution}
                                helperText={institutionError.email_of_institution && 'Invalid Entry'}
                                label= "Email of Institution"
                                value={institute.email_of_institution}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                   

                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                        <TextField
                            required
                            name= "section_code"
                            error={institutionError.section_code}
                            helperText={institutionError.section_code && 'Invalid Entry'}
                            label= "Section"
                            value={institute.section_code || ''}
                            fullWidth
                            onChange={handleChange}
                            variant="outlined"
                            select
                        >
                        {sections.map((option) => (
                            <MenuItem key={option._id} value={option.section_code}>
                            {option.section_name}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                name= "student_chapter_code"
                                error={institutionError.student_chapter_code}
                                helperText={ 
                                    institute.new_chapter ? 
                                    "Should be left blank for new chapter" 
                                    : `Enter Correct Chapter Code. Example :- If your Chapter Code is "MH117 and MH-33",enter in the format MH117 and MH033 resp.`}
                                label= "Student Chapter Code"
                                value={institute.student_chapter_code}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "first_name"
                                error={institutionError.first_name}
                                helperText={institutionError.first_name && 'Invalid Entry'}
                                label= "First Name of Convener"
                                value={institute.first_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "last_name"
                                error={institutionError.last_name}
                                helperText={institutionError.last_name && 'Invalid Entry'}
                                label= "Last Name of Convener"
                                value={institute.last_name}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                name= "membership_id"
                                error={institutionError.membership_id}
                                helperText={institutionError.membership_id && 'Invalid Entry'}
                                label= "Membership ID of Convener"
                                value={institute.membership_id}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "email"
                                error={institutionError.email}
                                helperText={institutionError.email && 'Invalid Entry'}
                                label= "Email of Convener"
                                value={institute.email}
                                onChange={handleChange}
                                type="email"
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3} xl={3}>
                            <TextField
                                required
                                name= "mobile_no"
                                error={institutionError.mobile_no}
                                helperText={institutionError.mobile_no && 'Invalid Entry'}
                                label= "Phone No of Convener"
                                value={institute.mobile_no}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                            />
                    </Grid>
                    
                    
                    
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                
                                checked={institute.new_chapter}
                                onChange={()=>{setInstitute({...institute, new_chapter : !institute.new_chapter})}}
                                name="new_chapter"
                                color="primary"
                                
                            />
                            }
                            label="If New Student Chapter Please Tick (For establishment of new student chapter minimum 100 students or 50% of Annual Intake required at initial stage,
                                any branch)"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <input
                        className={classes.uploadButton}
                        id="contained-button-file1"
                        name="imageUpload"
                        type="file"
                        onChange={handleSignatureUpload}
                        />
                        <label htmlFor="contained-button-file1">
                        <Box display="flex">
                            <Button variant="outlined" component="span">
                            <BackupIcon className={classes.buttonIcon} /> Signature of Principal / Faculty Advisor  / ISTE Coordinator / Chapter Secy.
                            </Button>
                            <Typography>&nbsp;&nbsp;{signature?.name} </Typography>
                        </Box>
                        </label>
                    </Grid>
                    
                </Grid>
        </div>
    )
}

export default InstituteAndConvenerForm
