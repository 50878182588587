import React,{useState} from 'react';
import { Paper,Grid,TextField,Typography,MenuItem,Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import useStyles from './styles';
import {addEmployee} from '../../api/index';

const AddEmployee = () => {
    const classes = useStyles();
    const [employee, setEmployee] = useState({});
    const handleChange = (event) => {
        setEmployee({...employee, [event.target.name] : event.target.value});
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        try{
            const data = await addEmployee(employee);
            if(data?.status === 200){
                toast.success('Employee Added', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                toast.success('Somethin went wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            console.log(error);
            toast.success('Somethin went wrong', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <Paper className={classes.paper} elevation={5}>
             <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography variant="h6">
                        Add Employee
                    </Typography>
                </Grid>
               
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label = "First Name"
                        name = "firstName"
                        onChange = {handleChange}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label = "Middle Name"
                        name = "middleName"
                        onChange = {handleChange}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label = "Last Name"
                        name = "lastName"
                        onChange = {handleChange}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="email"
                        label = "Email"
                        name = "email"
                        onChange = {handleChange}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        required
                        id="outlined-full-width"
                        name="role"
                        label="Role"
                        fullWidth
                        variant="outlined"
                        onChange = {handleChange}
                        select
                        >
                        <MenuItem key={"Admin"} value={"Admin"}>
                            Admin
                        </MenuItem>
                        <MenuItem key={"Employee"} value={"Employee"}>
                            Employee
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label = "Employee ID"
                        name = "employeeId"
                        onChange = {handleChange}
                    >
                    </TextField>
                </Grid>
                
                <Grid item xs={12} sm={12} lg={12} align="center">
                    <Button variant="contained" color="primary" type="submit">Add Employee</Button>
                </Grid>
            </Grid>
            </form>
        </Paper>
    )
}

export default AddEmployee;;
