import React from 'react';
import { Grid,Breadcrumbs,Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';


import StudentMembershipTable from '../../components/StudentMembersTable/StudentMembersTable';

const StudentMembers = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Student Members</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                    <StudentMembershipTable />
            </Grid>
        </Grid>
        
    )
}

export default StudentMembers;
