import React from 'react';
import { Grid,Breadcrumbs,Typography} from '@material-ui/core';
import { Link } from 'react-router-dom';

import LifeTimeMembershipTable from '../../components/LifeTimeMembershipTable/LifeTimeMembershipTable';


const LifeTimeApplications = () => {
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Life Time Membership Applications</Typography>
                </Breadcrumbs>
            </Grid>
            {/* <Hidden smDown>
                <Grid item xs={12} sm={12} lg={6} align="right">
                    <Button className={classes.buttons}  variant="outlined" size="small" color="primary" to="/dashboard/life-time-members/1" component={Link}>
                        <OpenInNewIcon /> View Approved Applications
                    </Button>
                    <Button variant="contained" size="small" color="primary" to="/dashboard/life-time-membership-rejected-applications/1" component={Link}>
                        <OpenInNewIcon /> View Rejected Applications
                    </Button>
                </Grid>
            </Hidden> */}
            <Grid item xs={12}>
                
                    <LifeTimeMembershipTable />
                
            </Grid>
        </Grid>
        
    )
}

export default LifeTimeApplications
