import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import { BrowserRouter, Switch, Route,useRouteMatch } from 'react-router-dom';
import { Button, Hidden } from '@material-ui/core';

import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import LifeTimeApplications from '../LifeTimeApplications/LifeTimeApplications';
import LifeTimeMembers from '../LifeTimeMembers/LifeTimeMembers';
import ViewLifeTimeApplication from '../ViewLifeTimeApplication/ViewLifeTimeApplication';
import LifeTimeRejctedApplications from '../LifeTimeApplicationsRejected/LifeTimeApplicationsRejected';
import StudentApplications from '../StudentApplications/StudentApplications';
import Dashboard from '../Dashboard/Dashboard';
import ViewStudentApplications from '../ViewStudentApplications/ViewStudentApplications';
import InstitutionalApplications from '../InstitutionalApplications/InstitutionalApplications';
import InstitutionalMembers from '../InstitutionalMembers/InstitutionalMembers';
import ViewInstitutionalApplication from '../ViewInstitutionalApplication/ViewInstitutionalApplication';
import AdminControl from '../AdminControl/AdminControl';
import UpdateEmployee from '../UpdateEmployee/UpdateEmployee';
import Account from '../Account/Account';
import StudentMembers from '../StudentMembers/StudentMembers';
import StatesAndDistricts from '../StatesAndDistricts/StatesAndDistricts';
import AddStudentMembers from '../AddStudentMembers/AddStudentMembers';
import AddLifeTimeMember from '../AddLifeTimeMember/AddLifeTimeMember';
import AddInstitutionalMember from '../AddInstitutionalMember/AddInstitutionalMember';
import AddNewChapter from '../AddNewChapter/AddNewChapter';
import FacultyChapters from '../FacultyChapters/FacultyChapters';
import StudentChapters from '../StudentChapters/StudentChapters';
import AddNewStudentChapter from '../AddNewStudentChapter/AddNewStudentChapter';


import useStyles from './styles';



export default function Layout2() {
  const classes = useStyles();
  const theme = useTheme();
  let { path, url } = useRouteMatch();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
              <Hidden smDown>
                Indian Society for Technical Education
              </Hidden>
              <Hidden smUp>
                ISTE
              </Hidden>
          </Typography>
          <Button variant="contained" color="secondary" style={{marginLeft : 'auto'}}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
         
        </List>
        <Divider />
        
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
                    <Route exact path={path}>
                        <Dashboard />
                    </Route>

                    {/*Student Members*/}
                    <Route path={`${path}/student-membership-applications`} exact>
                        <StudentApplications />
                    </Route>
                    <Route path={`${path}/student-membership-applications/admin/add-student`} exact>
                        <AddStudentMembers />
                    </Route>
                    <Route path={`${path}/student-membership-applications/students/:applicationId`}>
                        <ViewStudentApplications />
                    </Route>
                    <Route path={`${path}/student-members/:page`}>
                        <StudentMembers />
                    </Route>

                    {/*Life Time Members*/}
                    <Route path={`${path}/life-time-membership-applications`} exact>
                        <LifeTimeApplications />
                    </Route>
                    <Route path={`${path}/life-time-membership/admin/add`} exact>
                        <AddLifeTimeMember />
                    </Route>
                    <Route path={`${path}/life-time-membership-rejected-applications/:page`} exact>
                        <LifeTimeRejctedApplications />
                    </Route>
                    <Route path={`${path}/life-time-membership-applications/:applicationId`}>
                        <ViewLifeTimeApplication />
                    </Route>
                    <Route path={`${path}/life-time-members/:page`} >
                        <LifeTimeMembers />
                    </Route>
                    
                    {/*Institutional Members*/}
                    <Route path={`${path}/institute-membership/admin/add`} exact>
                        <AddInstitutionalMember />
                    </Route>
                    <Route path={`${path}/institute-membership-applications`} exact>
                        <InstitutionalApplications />
                    </Route>
                    <Route path={`${path}/institute-membership-applications/:applicationId`}>
                        <ViewInstitutionalApplication />
                    </Route>
                    <Route path={`${path}/institutional-members/approved/:page`} >
                        <InstitutionalMembers  approved={true} rejected={false} />
                    </Route>
                    <Route path={`${path}/institutional-members/rejected/:page`} >
                        <InstitutionalMembers  approved={false} rejected={true} />
                    </Route>

                    {/*faculty chapters*/}
                    <Route path={`${path}/faculty-chapters/admin/add`} exact>
                        <AddNewChapter />
                    </Route>
                    <Route path={`${path}/faculty-chapters/admin/view/:page`} exact>
                        <FacultyChapters />
                    </Route>

                    {/* Student Chapters */}
                    <Route path={`${path}/student-chapters/admin/add`} exact>
                        <AddNewStudentChapter />
                    </Route>
                    <Route path={`${path}/student-chapters/admin/view/:page`} exact>
                        <StudentChapters />
                    </Route>
                   
                    {/*States and Districts*/}
                    <Route path={`${path}/states-and-districts`}>
                        <StatesAndDistricts />
                    </Route>
                    
                    <Route path={`${path}/account`}>
                        <Account />
                    </Route>
                    <Route path={`${path}/admin-control`} exact>
                        <AdminControl />
                    </Route>
                    <Route path={`${path}/admin-control/update-employee/:id`}>
                        <UpdateEmployee />
                    </Route>

                </Switch>
                <KJSIEIT />
      </main>
    </div>
  );
}
