import {
  Box,
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Paper
} from '@material-ui/core';
import ProfileInformation from '../../components/ProfileInformation/ProfileInformation';
import useStyles from './styles';
const Account = () => {
  const classes = useStyles();
  return (<>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
              <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                     </Link>
                     <Typography color="textPrimary">Account</Typography>
                 </Breadcrumbs>
            <ProfileInformation />
          </Grid>
        </Grid>
      </Container>
  </>)
};

export default Account;