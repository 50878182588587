import React from "react";


const SocialMedia = () => {
  return (
    <div className="container-social">
      <a
        className="container-social-handles"
        href="https://kjsieit.somaiya.edu.in/en"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fas fa-globe" aria-hidden="true"></i>
      </a>
      <a
        className="container-social-handles"
        href="https://www.facebook.com/kjsieitofficial"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-facebook" aria-hidden="true"></i>
      </a>
      <a
        className="container-social-handles"
        href="https://www.instagram.com/kjsieit_22/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-instagram" aria-hidden="true"></i>
      </a>

      <a
        className="container-social-handles"
        href="https://twitter.com/kjsieit1"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-twitter" aria-hidden="true"></i>
      </a>
      <a
        className="container-social-handles"
        href="https://www.youtube.com/kjsieitofficial"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-youtube" aria-hidden="true"></i>
      </a>
    </div>
  );
};

export default SocialMedia;
