import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
    Tab,
    TableFooter,
    TextField,
    Typography,
    Box
} from '@material-ui/core';

const columns = [
  { id: 'duration', label: 'Duration of Membership'},
  { id: 'count', label: 'Number of Students'},
  { id: 'amount', label: 'Amount for Each Student'},
  { id: 'admission_fee', label: 'Admission Fee for Each Student'},
  { id: 'gst_percentage', label: 'GST %'},
  { id: 'total', label: 'Total Fees to be Paid (INR)'},
  { id: 'total', label: 'Chapter Share\n(50% of basic fee)'},
  { id: 'total', label: 'ISTE HQ Fees'},
];





const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
    padding : theme.spacing(3),
    borderRadius : theme.spacing(2)
  },
  container: {
    maxHeight: 440,
  },
  tableFooter : {
      margin : theme.spacing(3)
  }
}));


export default function StickyHeadTable(props) {
  const classes = useStyles();
  console.log(props)
  let total = 0;
  let totalWithDeduction = 0;
  for(const payment of props.paymentDetails){
      total += (payment.count*payment.amount_for_each + payment.count*parseInt(payment.admission_fee))*(1 + (payment.gst_percentage/100))
      totalWithDeduction += (payment.count*payment.amount_for_each + payment.count*parseInt(payment.admission_fee))*(1 + (payment.gst_percentage/100)) - (payment.count*payment.amount_for_each)/2
      
    }
  
  return (
    <Paper className={classes.root} elevation={5}>
      <TableContainer className={classes.container}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
                {
                    props.paymentDetails.map((value,idx)=>{
                        
                        return(
                            <TableRow key={idx}>
                                <TableCell>
                                    {value.duration}
                                </TableCell>
                                <TableCell>
                                    {value.count}
                                </TableCell>
                                <TableCell>
                                    
                                    <TextField
                                        value={value.amount_for_each}
                                        name="amount_for_each"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e)=>{
                                            props.handlePaymentDetailsChange({...value, [e.target.name]:e.target.value},idx);
                                        }}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={value.admission_fee}
                                        name="admission_fee"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e)=>{
                                            props.handlePaymentDetailsChange({...value, [e.target.name]:e.target.value},idx);
                                        }}
                                    >

                                    </TextField>
                                    
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={value.gst_percentage}
                                        name="gst_percentage"
                                        size="small"
                                        variant="outlined"
                                        // onChange={(e)=>{
                                        //     props.handlePaymentDetailsChange({...value, [e.target.name]:e.target.value},idx);
                                        // }}
                                    >

                                    </TextField>
                                    
                                </TableCell>
                                <TableCell>
                                    <TextField
                                      label = "Line Total"
                                      size="small"
                                      variant="outlined"
                                      value={
                                        (value.count*value.amount_for_each + value.count*parseInt(value.admission_fee))*(1 + (value.gst_percentage/100))
                                      }
                                    >

                                    </TextField>
                                    
                                </TableCell>
                                <TableCell>
                                    <TextField
                                      label = "Chapters Share"
                                      size="small"
                                      variant="outlined"
                                      value={
                                        (value.count*value.amount_for_each)/2
                                      }
                                    >
                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                      label = "ISTE HQ Fees"
                                      size="small"
                                      variant="outlined"
                                      value={
                                        (value.count*value.amount_for_each + value.count*parseInt(value.admission_fee))*(1 + (value.gst_percentage/100)) - (value.count*value.amount_for_each)/2
                                      }
                                    >
                                    </TextField>
                                </TableCell>
                            </TableRow>
                        );
                    })
                }
                
          </TableBody>
                
        </Table>
      </TableContainer>
      <Box align="right">
            <Typography variant="h6" component="h6" className={classes.tableFooter}>
                Total Amount (including GST) = {total.toFixed(2)} INR <br/>
                Total Amount with deductions (including GST) = {totalWithDeduction.toFixed(2)} INR
            </Typography>
      </Box>
    </Paper>
  );
}
