import React, { useEffect,useState } from 'react';
import { Grid,Paper,Button,Hidden } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { toast } from 'react-toastify';

import DashboardCard from '../../components/DashboardCard/DashboardCard';
import StudentStats from '../../components/StudentStats/StudentStats'; 
import InstitutionalMembersStats from '../../components/InstitutionalMembersStats/InstitutionalMembersStats';
import useStyles from './styles';
import { getDashboardData } from '../../api';



const Dashboard = () => {
    const classes = useStyles();
    const [counts, setCounts] = useState({});
    const getData = async ()=>{
        try{
            const data = await getDashboardData();
            if(data?.status === 200){
                setCounts(data?.data?.counts);
            }
        }catch(error){
            console.log(error);

        }
    }
    useEffect(()=>{
        getData();
    },[])
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={6} xl={3} >
                    <StudentStats title="Life Members" count={counts.countOfLifeTimeMembers} icon={PersonIcon}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} xl={3} >
                    <StudentStats title="Student Members" count={counts.countOfStudentMembers} icon={LocalLibraryIcon}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={3} >
                    <StudentStats title="Institutional Members" count={counts.countOfInstitutionalMembers} icon={BusinessIcon}/>
                </Grid>
                
                <Grid item xs={12} sm={12} lg={4} xl={3} >
                    <StudentStats title="Faculty Chapters" count={counts.countOfFacultyChapters} icon={LocalLibraryIcon}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={3} >
                    <StudentStats title="Student Chapters" count={counts.counrOfStudentChapters } icon={LocalLibraryIcon}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View LifeTime Membership Applications" 
                    body="View all the lifetime membership registration applications" 
                    to="/dashboard/life-time-membership-applications/"
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View Institution Membership Applications" 
                    body="View all the institutional membership registration applications" 
                    to="/dashboard/institute-membership-applications/"
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View Student Membership Applications" 
                    body="View all the student membership registration applications" 
                    to="/dashboard/student-membership-applications/1"
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View Student Members" 
                    body="View the list of all student members" 
                    to="/dashboard/student-members/1"
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View Lifetime Members" 
                    body="View the list of all lifetime members" 
                    to="/dashboard/life-time-members/1"
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={4} xl={4} >
                   <DashboardCard 
                    title="View Institution Members" 
                    body="View the list of all institution members" 
                    to="/dashboard/institutional-members/approved/1"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard;
