import React from 'react';
import { Grid,Breadcrumbs,Typography,Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

import InstitutionalMembersTable from '../../components/InstitutionalMembersTable/InstitutionalMembersTable';


const InstitutionalMembers = ({approved,rejected}) => {
    console.log(approved);
    console.log(rejected);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Institute Members</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                    <InstitutionalMembersTable approved={approved} rejected={rejected} />
            </Grid>
        </Grid>
        
    )
}

export default InstitutionalMembers;
