import React from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Breadcrumbs,
    Typography,

} from '@material-ui/core';

import AddNewStudentChapterForm from '../../components/AddStudentChapterForm/AddStudentChapterForm';

const AddNewStudentChapter = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Add Student Chapter</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <AddNewStudentChapterForm />
            </Grid>
        </Grid>
    )
}

export default AddNewStudentChapter;
