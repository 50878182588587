import React from "react";
import SocialMedia from "../SocialMedia/SocialMedia";
import icon1 from "../../assets/images/somaiya1.png";
// import icon2 from "../../assets/images/somaiya2.jpeg";
import { Paper, Box, makeStyles, Grid,Typography,Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  footer : {
    marginTop : 'auto'
  },
  devAndMaintained : {
    margin : theme.spacing(2)
  }
}));

const KJSIEIT = () => {
  const classes = useStyles();
  return (
    <Box borderTop={1} borderColor="rgba(0, 0, 0, 0.1)" className={classes.footer}>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ overflowY: "hidden" }}
      >
        <Grid container justify="space-between">
          

          <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center"}}>
            <Hidden smDown>
              <Typography className={classes.devAndMaintained} variant="h6">Developed and Maintained By</Typography>
            </Hidden>
            <Hidden smUp>
            <Typography className={classes.devAndMaintained} ><b>Developed and Maintained By</b></Typography>
            </Hidden>
            {/* <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"

            
            >
              <Grid item xs={3} md={3} lg={3} align="center">
              <div className="container-kjsieit-logo1">
                <img alt="" src={icon1} />
              </div>
              </Grid>
            </Grid> */}
            {/* <h2 style={{fontSize:'2rem' }}>
              K. J. Somaiya Institute of Engineering and Information
              Technology, Sion, Mumbai 
            </h2> */}
            <Hidden smDown>
              <Typography style={{color : '#010057'}} variant="h4" component="h2">
                <b>K. J. Somaiya Institute of 
                Technology, Sion, Mumbai</b> 
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography style={{color : '#010057'}}   variant="h5" component="h2">
                <b>K. J. Somaiya Institute of 
                Technology, Sion, Mumbai</b> 
              </Typography>
            </Hidden>
            <Typography style={{color : '#010057'}} variant="h6">
              <b>(An Autonomous Institute Permanently Affiliated to the University of Mumbai)</b>
            </Typography>
            {/* <h4 style= {{ margin: 0 }}> 
            
            </h4> */}
            {/* <h4 style={{ margin: 0 }}>
            Accredited by NAAC with ‘A’ Grade (3.21 CGPA), Approved by AICTE, New Delhi
            </h4> */}
            <SocialMedia />
            <p>
              <Link
                to="/developerscontact"
                style={{ textDecoration: "none", color: "#3F51B5"}}
              >
                <Typography style={{fontSize:'1.3rem' }}><b>Know the Team</b></Typography>
              </Link>
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default KJSIEIT;
