import React from 'react';
import {
    Grid,
    Breadcrumbs,
    Link,
    Typography,
    
} from '@material-ui/core';

import StudentChaptersTable from '../../components/StudentChaptersTable/StudentChaptersTable';

const StudentChapters = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Typography color="textPrimary">Student Chapters</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <StudentChaptersTable />
            </Grid>
        </Grid>
    )
}

export default StudentChapters;
