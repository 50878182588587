import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
    paper: {
        borderRadius : theme.spacing(4),
        padding : theme.spacing(5)
    },
    gridMargin : {
        marginBottom : theme.spacing(10)
    },
    submitDiv : {
        marginTop : theme.spacing(3)
    }
  }));