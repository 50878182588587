import * as actionType from '../constants/actionTypes';

const authReducer = (state = {
  authData: localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null,
}, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      return { ...state, authData: action.data, loading: false, errors: null };
      case actionType.UPDATE:
        let user = JSON.parse(localStorage.getItem("profile"));
        user.result.ph_no = action.data;
        localStorage.setItem("profile", JSON.stringify(user));
        return { ...state, authData: user, loading: false, errors: null };
    case actionType.LOGOUT:
      localStorage.clear();
      return { ...state, authData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default authReducer;