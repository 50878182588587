import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';

import {
    Grid,
    Paper,
    TextField,
    CssBaseline,
    Typography,
    Divider,
    Button,
    Link,
    Box
} from '@material-ui/core';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import {getPaymentData} from '../../api/payment';
import useStyles from './styles';
import { BASE_URL } from '../../constants/actionTypes';


const CompletePayment = () => {
    const { token } = useParams();
    const [paymentData, setPaymentData] = useState({
        first_name : '',
        last_name : '',
        email : '',
        _id : '',
        membership_type : '',
        amount : 0
    });
    const classes = useStyles();
    const getData = async ()=>{
        try{
            const data = await getPaymentData({token});
            console.log(data?.data?.data);
            setPaymentData(data?.data?.data);
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        getData();
    },[])
    return (
        <>
            <FormHeader />
            <CssBaseline />
            <main className={classes.layout}>    
               
                <form method="POST" name="customerData" action={`${BASE_URL}/api/payment/ccavRequestHandler`}>
					<Paper className={classes.paper}>
                        <Box border={2} style={{padding : '10px'}}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="center" variant="h4" component="h4">
                                        Payment Details 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        First Name : {paymentData.first_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Last Name : {paymentData.last_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Email : {paymentData.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Amount : {paymentData.amount}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Membership Type : {paymentData.membership_type}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography component="subtitle2" variant="subtitle2">
                                        Order Id : {paymentData._id}
                                    </Typography>
                                </Grid>
                                <TextField
                                    value={paymentData._id}
                                    name='order_id'
                                    style={{display : 'none'}}
                                >
                                </TextField>
                                <Grid item xs={12} sm={12} lg={12} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >Complete Payment</Button>
                                </Grid>
                            </Grid>
                        </Box>
					</Paper>
				</form>
            </main>
            <KJSIEIT />
        </>
    )
}

export default CompletePayment;
