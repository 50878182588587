import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LifeTimeMembership from './pages/LifeTimeMembership/LifeTimeMembership';
import InstitutionalMembership from './pages/InstitutionalMembership/InstitutionalMembership';
import StudentMembership from './pages/StudentMembership/StudentMembership';
import Recommender from './pages/Recommender/Recommender';
import Layout from './pages/Layout/Layout';
import Login from './pages/Login/Login';
import ForgotPass from './pages/ForgotPass/ForgotPass';
import DevelopersContact from './pages/DevelopersContact/DevelopersContact';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Layout2 from './pages/Layout2/Layout2';
import CompletePayment from './pages/CompletePayment/CompletePayment';
import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
import TransactionFailed from './pages/TransactionFailed/TransactionFailed';
import PaymentAlreadyCompleted from './pages/PaymentAlreadyCompleted/PaymentAlreadyCompleted';
import LandingPage from './pages/LandingPage/LandingPage';
import MembershipPolicy from './pages/MembershipPolicy/MembershipPolicy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path = "/" exact>
            <LandingPage />
          </Route>
          <Route path="/iste-login" exact component={Login} />
          <Route exact path="/forgotpass">
            <ForgotPass />
          </Route>
          <Route exact path="/developerscontact">
            <DevelopersContact />
          </Route>
          <Route exact path="/resetpassword/:token">
            <ResetPassword />
          </Route>
          <Route path="/life-time-membership/registration" exact component={LifeTimeMembership} />
          <Route path="/institutional-membership/registration" exact component={InstitutionalMembership} />
          <Route path="/student-membership/registration" exact component={StudentMembership} />
          <Route path="/privacy-policy" exact component={MembershipPolicy} />
          <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
          <Route path="/dashboard" >
              <Layout />
          </Route>
          <Route path="/layout2" >
              <Layout2 />
          </Route>
          <Route path="/recommender/:token" >
              <Recommender />
          </Route>
          <Route path="/complete-payment/:token" exact>
              <CompletePayment />
          </Route>
          <Route path="/payment-details/:order_id" exact>
              <PaymentSuccess />
          </Route>
          <Route path="/payment-done" exact>
              <PaymentSuccess />
          </Route>
          <Route path="/payment-already-done/:order_id" exact>
              <PaymentAlreadyCompleted />
          </Route>
          <Route path="/payment-failed/:order_id" exact>
              <TransactionFailed />
          </Route>
        </Switch>
        <ToastContainer />
    </BrowserRouter>
    
  );
}

export default App;
