import React, { useState } from 'react';
import { 
    Paper,
    Grid,
    CircularProgress,
    Typography,
    Divider,
    TextField,
    Button,
    
} from '@material-ui/core';
import useStyles from './styles';
import { toast } from 'react-toastify';
import { addFacultyChapter } from '../../api';

const AddFacultyChapter = () => {
    const [loading,setLoading] = useState(false);
    const [chapter, setChapter] = useState({
        membership_no : '',
        chairman : '',
        chairman_department : '',
        secretary : '',
        secretary_department : ''
    });
    const [chapterError, setChapterError] = useState({
        membership_no : '',
        chairman : '',
        chairman_department : '',
        secretary : '',
        secretary_department : ''
    });
    const handleChange = (e)=>{
        setChapter({...chapter, [e.target.name]:e.target.value})
    }
    const validateData = ()=>{
        const newChapterError = {
            membership_no : !chapter.membership_no || isNaN(chapter.membership_no),
            chairman : !chapter.chairman,
            chairman_department : !chapter.chairman_department,
            secretary : !chapter.secretary,
            secretary_department : !chapter.secretary_department
        }
        setChapterError(newChapterError);
        let isValid = true;
        for(var key in newChapterError){
            if(newChapterError[key]) isValid = false;
        }
        return isValid;
    }
    const submitForm = async ()=>{
        setLoading(true);
        try{
            const isValid = validateData(chapter);
            if(!isValid) return;
            const data = await addFacultyChapter(chapter);
            console.log(data);
            if(true){
                toast.success('Chapter Added', {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
              
          }
        }catch(error){
          console.log(error.response);
          toast.error('Something went wrong! Make sure that the Institute Number is Valid and havent added before', {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }finally{
          setLoading(false);
        }
      }
    
    const classes = useStyles();
    return (
        <Grid>
            <Grid item className={classes.gridMargin}>
                {
                    loading ? 
                    <Paper className={classes.loading} align="center">
                        <CircularProgress />
                    </Paper> :
                    (
                        <>
                        <Paper className={classes.paper}>
                            <Typography className={classes.formTitle} component="h5" variant="h5" >
                                Add Faculty Chapter
                            </Typography>
                            <Divider/>
                            <Grid container spacing={3} className={classes.searchMargin}>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.membership_no}
                                        helperText={chapterError.membership_no && "Enter Valid Membership No"}
                                        label="Institute ID"
                                        name="membership_no"
                                        value={chapter.membership_no}
                                        onChange={handleChange}
                                        type="Number"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error = {chapterError.chairman}
                                        helperText={chapterError.chairman && "Invalid Entry"}
                                        label="Chapter Chairman"
                                        name="chairman"
                                        value={chapter.chairman}
                                        onChange={handleChange}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.chairman_department}
                                        helperText={chapterError.chairman_department && "Invalid Entry"}
                                        label="Chapter Chairman Department"
                                        name="chairman_department"
                                        value={chapter.chairman_department}
                                        onChange={handleChange}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.secretary}
                                        helperText={chapterError.secretary && "Invalid Entry"}
                                        label="Chapter Secretary"
                                        name="secretary"
                                        value={chapter.secretary}
                                        onChange={handleChange}
                                    >
                                        
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        error={chapterError.secretary_department}
                                        helperText={chapterError.secretary_department && "Invalid Entry"}
                                        label="Chapter Secretary Department"
                                        name="secretary_department"
                                        value={chapter.secretary_department}
                                        onChange={handleChange}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                    >
                                        Add Chapter
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        </>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default AddFacultyChapter;
