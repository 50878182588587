import { makeStyles } from "@material-ui/core";

export default makeStyles((theme)=>({
    buttons : {
        marginRight : theme.spacing(2)
    },
    root: {
        width: '100%',
        padding : theme.spacing(5),
        borderRadius : theme.spacing(4)
    },
    loading : {
        padding : theme.spacing(10)
    },
    box : {
        padding : theme.spacing(2)
    },
    searchButton : {
        padding : theme.spacing(2)
    },
    buttonPadding : {
        margin : theme.spacing(1)
    },
    dateRangePicker : {
        height : '50px',
        borderRadius : '2px',
        fontSize : '15px',
        width : '100%'
    },
    tableBoxMargin : {
        marginTop : theme.spacing(10)
    }
}));
