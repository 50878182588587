import React,{useState, useEffect} from 'react';
import { BrowserRouter, Switch, Route,useRouteMatch,useParams,Link,useHistory } from 'react-router-dom';
import { Grid,Breadcrumbs,Paper,Typography,CircularProgress } from '@material-ui/core'
import {toast} from 'react-toastify';

import ViewInstitutionalApplicationComponent from '../../components/ViewInstitutionalApplicationComponent/ViewInstitutionalApplicationComponent';
import InstitutionalMembershipAdminForm from '../../components/InstitutionalMembershipAdminForm/InstitutionalMembershipAdminForm';
import { getInstitutionalApplicant, approveInstitutionalMembership,rejectInstitutionalMembership , getAllDistricts} from '../../api/index';
import useStyles from './styles';

const ViewInstitutionalApplication = () => {
    const classes = useStyles();
    const history = useHistory();
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [application,setApplication] = useState({applicationId : applicationId});
    const [applicationData, setApplicationData] = useState({remark : ""});
    const [districtsData, setDistrictsData] = useState([]);
    
    let { path, url } = useRouteMatch(); 
    const handleDataChange = (event)=>{
        setApplicationData({...applicationData, [event.target.name] : event.target.value});
    }
    
    
    const handleApplicationRejection = async ()=>{
        try{
            const data = await rejectInstitutionalMembership({...applicationData, institutionalMemberId : applicationId });
            // console.log(data);
            if(data?.status === 200){
                toast.info('Application Rejected', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/dashboard/institute-membership-applications/');
            }else{
                
                toast.error(data?.data?.message || 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            toast.error('Something went wrong', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleApplicationApproval = async ()=>{
        try{
            const data = await approveInstitutionalMembership({...applicationData, institutionalMemberId : applicationId });
            if(data?.status === 200){
                toast.success('Application Approved', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/dashboard/institute-membership-applications/');
            }else{
                toast.error(data?.data?.message || 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error){
            toast.error('Make sure you have added a remark.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getData = async ()=>{
        const data = await getInstitutionalApplicant({applicationId});
        console.log(application);
        console.log(data);
        if(data?.data?.result){
            setApplication(data?.data?.result);
            setLoading(false);
        }
    }
    const handleApplicationDataChange = (e)=>{
        setApplication({...application,[e.target.name]:e.target.value});
    }
    const getDistricts = async ()=>{
        try{
            const data = await getAllDistricts();
            if(data?.status === 200){
              setDistrictsData(data?.data?.result);
            }
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        getData(applicationId); 
    },[])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/dashboard" >
                        Dashboard
                    </Link>
                    <Link color="inherit" to="/dashboard/institute-membership-applications" >
                        Institution Membership Applications
                    </Link>
                    <Typography color="textPrimary">{loading ? "Application" : `${application.name_of_institution} ` }</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={5} className={classes.paper}>
                    {loading ? 
                        <div align="center"><CircularProgress /></div> : 
                        <ViewInstitutionalApplicationComponent 
                            application={application} 
                            districtsData={districtsData}
                            handleApplicationDataChange={handleApplicationDataChange}
                        /> 
                    }
                </Paper>
            </Grid>

            <Grid item xs={12}>
                {!loading && application.status === 'In Progress'   && 
                <InstitutionalMembershipAdminForm 
                application={application} 
                handleApplicationRejection={handleApplicationRejection}  
                handleApplicationApproval = {handleApplicationApproval}
                handleDataChange={handleDataChange}
                /> }
            </Grid>
        </Grid>
        
    )
}

export default ViewInstitutionalApplication
