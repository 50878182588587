import React, { useState, useEffect } from 'react';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Paper,
    Box,
    Button,
    Link as MaterialUILink
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link, useParams, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import AssessmentIcon from '@material-ui/icons/Assessment'
import BASE_URL from '../../api/constants';


import StudentApplicationTable from '../../components/StudentApplicationTable/StudentApplicationTable';
import InstituteAndConvener from '../../components/InstituteAndConvener/InstituteAndConvener';
import StudentApplicationAdminForm from '../../components/StudentApplicationAdminForm/StudentApplicationAdminForm';
import {
    getStudentApplicationData,
    approveStudentMembershipApplication,
    rejectStudentMembershipApplication,
    getPaymentData
} from '../../api';
import {
    getAllStudentSections
} from '../../api/registrationForm'
import useStyles from './styles';
import StudentMembershipPayment from '../StudentMembershipPayment/StudentMembershipPayment';

function GenerateReportDialog(props) {
    const [open, setOpen] = React.useState(false)


    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                <AssessmentIcon /> Generate Student Report
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Generate Student Report
                    <br />
                    Total Count : {props.count}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Download Data in the .xlsx format. Since we have more than{' '}
                        {props.count} entries you can download them in batches
                    </DialogContentText>
                    {Array(Math.ceil(props.count / 1000))
                        .fill(null)
                        .map((value, idx) => (
                            <Button
                                style={{ margin: '2px' }}
                                variant="outlined"
                                onClick={() => {
                                    props.generateStudentReport(idx * 1000, (idx + 1) * 1000)
                                }}
                            >
                                <GetAppIcon /> Download Entries between ({idx * 1000 + 1} and{' '}
                                {(idx + 1) * 1000})
                            </Button>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const StudentApplications = () => {
    const { applicationId } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setloading] = useState(true);
    const [studentData, setStudentData] = useState([]);
    const [instituteAndConvener, setInstituteAndConvener] = useState({});
    const [adminFeedback, setAdminFeedback] = useState({});
    const [remarks, setRemarks] = useState({});
    const [remarkErrors, setRemarksError] = useState({});
    const [paymentType, setPaymentType] = useState('student-with-deduction');
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [sections, setSections] = useState([]);
    const [count, setCount] = useState(0)

    const fetchAllSections = async () => {
        try {
            const data = await getAllStudentSections();
            if (data?.status === 200) {
                setSections(data?.data?.result);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const [paymentData, setPaymentData] = useState({});
    const handleCheckboxChange = (event) => {
        setAdminFeedback({ ...adminFeedback, [event.target.name]: 'reject' });
        console.log(adminFeedback);
    }
    const handleRemarkChange = (e) => {
        setRemarks({ ...remarks, [e.target.name]: e.target.value });
    }

    const calculatePaymentDetails = async (students) => {

        // console.log(students);
        const data = await getPaymentData();
        let paymentData;
        for (const val of data?.data?.payment) {
            if (val.type === 'student') {
                paymentData = val;
                console.log('here')
                console.log(val);
                break
            }
        }
        let paymentDetailsCalculation = []
        for (var i = 1; i <= 5; i++) {
            let count = 0;
            for (var value of students) {
                // console.log(value);
                if (value?.duration === i) count++;
            }
            if (count > 0) {
                paymentDetailsCalculation.push({
                    duration: i,
                    count: count,
                    amount_for_each: paymentData.amount * i,
                    admission_fee: paymentData.admission_fee,
                    gst_percentage: paymentData.gst_percentage
                })
            }
        }
        setPaymentDetails(paymentDetailsCalculation);
    }
    const handlePaymentDetailsChange = (value, idx) => {
        const newPaymentDetails = [...paymentDetails];
        newPaymentDetails[idx] = value;
        setPaymentDetails(newPaymentDetails);
    }
    const getData = async () => {
        try {
            const data = await getStudentApplicationData({ instituteConvenerId: applicationId });
            if (data?.status === 200) {
                if (data?.data?.result?.studentMembers) {
                    setStudentData(data?.data?.result?.studentMembers);
                    setCount(data?.data?.result?.studentMembers.length);
                }
                if (data?.data?.result?.instituteAndConvener) {
                    setInstituteAndConvener(data?.data?.result?.instituteAndConvener);
                }
                calculatePaymentDetails(data?.data?.result?.studentMembers);

                setloading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const generateStudentReport = async (start, end) => {
        try {
            await axios({
                url: `${BASE_URL}/api/student-membership/applications/students-report`, //your url
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token
                        }`,
                    'Content-Type': 'application/json',
                },
                data: { 
                    studentData, 
                    start, 
                    end 
                },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `Student Report - exported at ${moment().format(
                        'YYYY-MM-DD-HH-MM-SS'
                    )}.xlsx`
                ) //or any other extension
                document.body.appendChild(link)
                link.click()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleAccept = async () => {
        try {
            const data = await approveStudentMembershipApplication({
                instituteConvenerId: applicationId,
                adminFeedback: adminFeedback,
                paymentType: paymentType
            });
            if (data?.status === 200) {
                toast.success('Application Approved & Payment Link have been emailed', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location.reload();
            } else {
                toast.error('Something went wrong!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const handleReject = async () => {
        const newRemarksError = {
            note: !remarks.note,
            note2: !remarks.note2
        }
        setRemarksError(newRemarksError);
        console.log(remarks);
        if (!remarks.note && !remarks.note2) {
            toast.info('Please enter a remark', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        try {
            let remark = '';
            if (remarks.note !== undefined) remark += remarks.note;
            if (remarks.note2 !== undefined) remark += '\n' + remarks.note2;
            const data = await rejectStudentMembershipApplication({
                instituteConvenerId: applicationId,
                remark: remark,
            });
            if (data?.status === 200) {
                toast.success('Application Rejected', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/dashboard/student-membership-applications');
            } else {
                toast.error('Something went wrong!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const downloadCard = async (start, end) => {
        try {
            axios({
                url: `${BASE_URL}/api/student-membership/members/download-cards`, //your url
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
                },
                params: { instituteConvenerId: applicationId },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Student Cards - downloaded at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        } catch (error) {
            console.log(error);
        }
    }

    const downloadCertificates = async (start, end) => {
        try {
            axios({
                url: `${BASE_URL}/api/student-membership/members/download-certificates`, //your url
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
                },
                params: { instituteConvenerId: applicationId },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Student Cards - downloaded at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        } catch (error) {
            console.log(error);
        }
    }

    const downloadInvoice = async (start, end) => {
        try {
            axios({
                url: `${BASE_URL}/api/student-membership/members/download-invoice`, //your url
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
                },
                params: { instituteAndConvenerId: applicationId, paymentType: paymentType },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Student Invoice - downloaded at ${moment().format('YYYY-MM-DD-HH-MM-SS')}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        } catch (error) {
            console.log(error);
        }
    }

    const downloadInstituteDetails = async (start, end) => {
        try {
            axios({
                url: `${BASE_URL}/api/student-membership/members/download-institute-details`, //your url
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
                },
                params: { id: applicationId },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Institute Details - downloaded at ${moment().format('YYYY-MM-DD-HH-MM')}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        } catch (error) {
            console.log(error);
        }
    }
    const downloadFilingeDetails = async (start, end) => {
        try {
            axios({
                url: `${BASE_URL}/api/student-membership/members/download-student-filing-details`, //your url
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`
                },
                params: { instituteAndConvenerId: applicationId },
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `STUDENT APPLICATION AND PAYMENT DETAILS - downloaded at ${moment().format('YYYY-MM-DD-HH-MM')}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
        fetchAllSections();
    }, [])


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/dashboard" >
                            Dashboard
                        </Link>
                        <Link color="inherit" to="/dashboard/student-membership-applications/" >
                            Student Membership Applications
                        </Link>
                        <Typography color="textPrimary">Student Membership Applications</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.paper}>
                        {loading ?
                            <Box align="center">
                                <CircularProgress />
                            </Box> :
                            <InstituteAndConvener institute={instituteAndConvener} sections={sections} />}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.paper}>
                        {loading ?
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                            :
                            <StudentApplicationTable
                                studentData={studentData}
                                handleCheckboxChange={handleCheckboxChange}

                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {(!loading && instituteAndConvener.status !== 'InProgress') && (
                        <Paper elevation={5} className={classes.paper} align="center">
                            <Button color="primary" variant="outlined" style={{ marginRight: "10px" }} onClick={downloadCard}><PrintIcon /> Download Student Cards</Button>
                            <Button color="primary" variant="outlined" style={{ marginRight: "10px" }} onClick={downloadCertificates}><DescriptionIcon /> Download Student Certificates</Button>
                            {
                                instituteAndConvener.student_chapter_code && (
                                    <Button
                                        component={MaterialUILink}
                                        target="_blank"
                                        href={`${BASE_URL}/api/student-chapters/download-certificate?chapter_code=${instituteAndConvener.student_chapter_code}`}
                                        variant="outlined"
                                        style={{ marginRight: "10px" }}>
                                        <GetAppIcon /> Download Chapter Certificate
                                    </Button>
                                )
                            }
                            {
                                instituteAndConvener.status === 'Approved' && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{ marginRight: "10px" }}
                                        onClick={handleAccept}
                                        className={classes.button}
                                    >
                                        Send Payment Link
                                    </Button>
                                )
                            }
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                                style={{ marginRight: "10px" }}
                                onClick={downloadInvoice}
                                className={classes.button}
                            >
                                <GetAppIcon /> Download Invoice
                            </Button>
                        </Paper>
                    )

                    }

                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.paper} align="center">
                        <Grid container spacing={1}>
                            <Grid item sm={12} lg={4}>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={downloadInstituteDetails}
                                >
                                    <GetAppIcon /> Download Application Details
                                </Button>
                            </Grid>
                            {
                                instituteAndConvener.status === 'Completed' && (
                                    <Grid item sm={12} lg={3}>
                                        <Button
                                            variant="outlined"
                                            type="submit"
                                            onClick={downloadFilingeDetails}
                                        >
                                            <GetAppIcon /> Download Filing Details
                                        </Button>
                                    </Grid>
                                )
                            }
                            <Grid item sm={12} lg={4}>
                                <GenerateReportDialog
                                    count={count}
                                    generateStudentReport={generateStudentReport}
                                />
                            </Grid>


                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} >
                    <StudentMembershipPayment
                        paymentDetails={paymentDetails}
                        handlePaymentDetailsChange={handlePaymentDetailsChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        (!loading && instituteAndConvener.status === 'InProgress') &&
                        <StudentApplicationAdminForm
                            handleRemarkChange={handleRemarkChange}
                            handleAccept={handleAccept}
                            handleReject={handleReject}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                        />
                    }
                </Grid>
            </Grid>
        </>

    )
}

export default StudentApplications
